import { Currency } from "./rate";

export interface Asset{
    currency: Currency; 
    balance: number;
    accountId?: string;
    iban?: string;
}
export class Asset implements Asset{
    constructor(init?: Asset) {
        Object.assign(this, init);
      }
//     constructor(asset: Asset) {
//         this.currency = asset.currency; 
//         this.balance = asset.balance; 
//         this.accountId = asset.accountId; 
//     }
}
