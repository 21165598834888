import { CryptoAccount } from "./cryptoAccount";
import { FIATAccount } from "./fiatAccount";
import { User } from "./user";
import { Document } from "./document";
import { Currency, isFiat } from "./rate";

export interface Profile {
    username: string;
    displayName: string;
    email: string;
    phoneNumber?: string;
    dob?: Date;
    image?: string;
    bio?: string;
    kycValidated: boolean;
    documents?: Document[];
    fiatAccounts?: FIATAccount[];
    cryptoAccounts?: CryptoAccount[];
    tfaGoogleEnabled: boolean;
    homeAddress?: Address;
    identificationId?: string;
    identificationStatus: IdentificationStatus;
    // getAccount(currency: Currency): FIATAccount | CryptoAccount | undefined;
}

export class Profile implements Profile {
    constructor(user: User) {
        this.username = user.username;
        this.displayName = user.displayName;
        this.image = user.image;
    }
}

export interface Photo {
    id: string;
    url: string;
    isMain: boolean;
    data: Blob;
}

export interface Address {
    id?: string;
    street: string;
    zipCode?: string;
    town: string;
    county: string;
    country: string;
}

export class ProfileFilterParams {
    userName?: string;
    kycValidated?: boolean;
}

export interface LoginInfo {
    date: Date;
    ipAddress: string;
}

export class LoginInfoFilterParams {
    userName?: string;
    startDate?: Date;
    endDate?: Date;
}

export enum IdentificationStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2,
}

export function getIdentificationStatusLabel(identificationStatus: IdentificationStatus) {
    switch (identificationStatus) {
        case IdentificationStatus.Pending:
            return "Pending";
        case IdentificationStatus.Approved:
            return "Approved";
        case IdentificationStatus.Rejected:
            return "Rejected";
        default:
            return "";
    }
}