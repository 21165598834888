import React, { Suspense } from "react";
import ReactDOM from "react-dom";
// import "semantic-ui-css/semantic.min.css";
import "react-calendar/dist/Calendar.css";
// import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";
// import "./app/layout/styles.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import { store, StoreContext } from "./app/stores/store";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./i18n";
import LoadingComponent from "./app/layout/LoadingComponent";

export const history = createBrowserHistory();

ReactDOM.render(
    <StoreContext.Provider value={store}>
        <Router history={history}>
            <Suspense fallback={<LoadingComponent content='Loading app...' />}>
                <App />
            </Suspense>
        </Router>
    </StoreContext.Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
