import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const CryptoTrade = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M5.417 13.868a.833.833 0 0 0-.839.111l-.605.474a.209.209 0 0 1-.299-.045A7.713 7.713 0 0 1 11.63 2.465a1.042 1.042 0 1 0 .436-2.038A9.79 9.79 0 0 0 2.048 15.71a.208.208 0 0 1-.04.285l-.02.016a.833.833 0 0 0 .297 1.46l2.893.775a.834.834 0 0 0 1.044-.893l-.3-2.805a.834.834 0 0 0-.505-.679zM18.043 4.424a.208.208 0 0 1 .031-.274l.364-.327a.834.834 0 0 0-.395-1.438l-3.266-.642a.833.833 0 0 0-.964 1.043l.802 2.856a.833.833 0 0 0 1.36.394l.218-.195a.209.209 0 0 1 .315.044 7.707 7.707 0 0 1-8.152 11.647 1.042 1.042 0 1 0-.444 2.036 9.787 9.787 0 0 0 10.13-15.144z"
      fill="currentColor"
    />
    <path
      d="M7.293 14.074a1.041 1.041 0 0 0 1.04 1.041h.278a.209.209 0 0 1 .208.209v.093a1.042 1.042 0 0 0 2.084 0v-.104a.208.208 0 0 1 .194-.207 3.084 3.084 0 0 0 2.861-3.07 3.041 3.041 0 0 0-.678-1.906.208.208 0 0 1 0-.26 3.041 3.041 0 0 0-.15-4.003 3.083 3.083 0 0 0-2.033-.973.208.208 0 0 1-.194-.207v-.103a1.042 1.042 0 0 0-2.084 0v.091a.208.208 0 0 1-.208.209h-.278a1.042 1.042 0 0 0-1.041 1.041v8.149zm4.583-6.111a1 1 0 0 1-.995.995H9.583a.208.208 0 0 1-.208-.209V7.175a.208.208 0 0 1 .208-.208h1.297a1 1 0 0 1 .996.996zm-.995 5.069H9.583a.209.209 0 0 1-.208-.208V11.25a.208.208 0 0 1 .208-.208h1.297a.996.996 0 0 1 0 1.99z"
      fill="currentColor"
    />
  </Icon>
);

export const Scan = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="m3.2 18.333-1.32.031a.208.208 0 0 1-.213-.208V16.8A.833.833 0 0 0 0 16.8v1.57A1.63 1.63 0 0 0 1.63 20H3.2a.833.833 0 1 0 0-1.667zM.833 4.033a.833.833 0 0 0 .834-.833l-.032-1.32a.21.21 0 0 1 .208-.213H3.2A.833.833 0 0 0 3.2 0H1.63A1.63 1.63 0 0 0 0 1.63V3.2a.833.833 0 0 0 .833.833zM18.37 0H16.8a.833.833 0 0 0 0 1.667l1.32-.031a.21.21 0 0 1 .213.208V3.2A.833.833 0 1 0 20 3.2V1.63A1.63 1.63 0 0 0 18.37 0zM19.167 15.967a.833.833 0 0 0-.834.833l.032 1.32a.21.21 0 0 1-.208.213H16.8a.833.833 0 0 0 0 1.667h1.57A1.63 1.63 0 0 0 20 18.37V16.8a.833.833 0 0 0-.833-.833zM6.667 3.75H4.583a.417.417 0 0 0-.416.417V6.25c0 .23.186.417.416.417h2.084c.23 0 .416-.187.416-.417V4.167a.417.417 0 0 0-.416-.417zM15.417 3.75h-2.084a.417.417 0 0 0-.416.417V6.25c0 .23.186.417.416.417h2.084c.23 0 .416-.187.416-.417V4.167a.417.417 0 0 0-.416-.417zM15.417 13.333h-2.084a.417.417 0 0 0-.416.417v2.083c0 .23.186.417.416.417h2.084c.23 0 .416-.186.416-.417V13.75a.417.417 0 0 0-.416-.417zM6.667 13.333H4.583a.417.417 0 0 0-.416.417v2.083c0 .23.186.417.416.417h2.084c.23 0 .416-.186.416-.417V13.75a.417.417 0 0 0-.416-.417zM8.75 6.884a.625.625 0 0 0 .625-.625V5.21A.208.208 0 0 1 9.583 5h1.61a.625.625 0 1 0 0-1.25H8.75a.625.625 0 0 0-.625.625v1.884a.625.625 0 0 0 .625.625zM11.25 5.943a.625.625 0 0 0-.625.625v2.79a.625.625 0 1 0 1.25 0v-2.79a.625.625 0 0 0-.625-.625zM8.75 12.083a.625.625 0 0 0 .625-.625v-2.01a.625.625 0 0 0-1.25 0v1.177a.208.208 0 0 1-.208.208H4.583a.625.625 0 1 0 0 1.25H8.75zM11.193 15h-1.61a.208.208 0 0 1-.208-.209v-1.177a.625.625 0 0 0-1.25 0v2.01a.625.625 0 0 0 .625.626h2.443a.625.625 0 0 0 0-1.25zM4.583 9.167H6.25a.625.625 0 0 0 0-1.25H4.583a.625.625 0 0 0 0 1.25zM13.333 9.167h2.084a.625.625 0 0 0 0-1.25h-2.084a.625.625 0 0 0 0 1.25zM15.417 10.833h-4.584a.625.625 0 0 0 0 1.25h4.584a.625.625 0 1 0 0-1.25z"
      fill="currentColor"
    />
  </Icon>
);

export const Star = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M24 12L14.1213 14.1213L12 24L9.87868 14.1213L0 12L9.87868 9.87868L12 0L14.1213 9.87868L24 12Z"
      fill="currentColor"
    />
  </Icon>
);

export const EmptyMinus = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g>
      <path
        d="M1.25 11.25h17.5a1.25 1.25 0 0 0 0-2.5H1.25a1.25 1.25 0 0 0 0 2.5z"
        fill="#E5E7EA"
      />
    </g>
  </Icon>
);

export const EmptyPlus = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g>
      <path
        d="M0 10a1.25 1.25 0 0 0 1.25 1.25h7.292a.208.208 0 0 1 .208.208v7.292a1.25 1.25 0 0 0 2.5 0v-7.292a.208.208 0 0 1 .208-.208h7.292a1.25 1.25 0 0 0 0-2.5h-7.292a.208.208 0 0 1-.208-.208V1.25a1.25 1.25 0 0 0-2.5 0v7.292a.208.208 0 0 1-.208.208H1.25A1.25 1.25 0 0 0 0 10z"
        fill="#E5E7EA"
      />
    </g>
  </Icon>
);
