import * as React from "react";
import { Flex, Text, FlexProps } from "@chakra-ui/react";

interface Props extends FlexProps {
  icon: React.ReactElement;
  children: string;
  isActive: boolean;
}

export default function NavigationItem({
  icon,
  children,
  isActive,
  ...rest
}: Props) {
  return (
    <Flex
      as="li"
      py="6"
      color={isActive ? "white" : "gray.300"}
      borderBottom={isActive ? "2px" : "none"}
      borderColor="yellow.800"
      align="center"
      cursor="pointer"
      pos="relative"
      zIndex="1"
      _hover={{ color: "white" }}
      {...rest}
    >
      {icon}
      <Text size="paragraph_semibold" ml="4">
        {children}
      </Text>
    </Flex>
  );
}
