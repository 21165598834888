import { FlexProps, Flex, Tabs, Tab, TabList, TabPanels, TabPanel } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import DepositDetails from "./deposit/DepositDetails";
import WithdrawDetails from "./withdraw/WithdrawDetails";
import DepositSummary from "./deposit/DepositSummary";
import { useStore } from "app/stores/store";
import { FundingType } from "app/models/fundingRequest";
import WithdrawSummary from "./withdraw/WithdrawSummary";
import { useTranslation } from "react-i18next";


export default observer(function FundingCard(props: FlexProps) {
    
    // const [showDepositSummary, setShowDepositSummary] = React.useState(false);
    const {
        fundingRequestStore: { showSummary, fundingType,  setFundingType },
    } = useStore();
    const { t } = useTranslation();

    function onTabChanged(index: number) {
        //TODO: resetcontrols
        // setAmount(0);
        
        setFundingType(index == 0 ? FundingType.Deposit : FundingType.Withdraw);
    }

    return (
        <Flex
            {...props}
            bg="gray.800"
            borderRadius="0.75rem"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            p={{ base: "4", md: "10" }}
            direction="column"
            color="white"
        >
            {!showSummary ? (
                <Tabs onChange={(index) => onTabChanged(index)} defaultIndex={fundingType == FundingType.Deposit ? 0: 1}variant="prohash">
                    <TabList>
                        <Tab>{t("deposit")}</Tab>
                        <Tab>{t("widthdraw")}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {fundingType == FundingType.Deposit &&
                            <DepositDetails />}
                        </TabPanel>
                        <TabPanel>
                        {fundingType == FundingType.Withdraw &&
                            <WithdrawDetails />}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            ) : (
                <>{fundingType === FundingType.Deposit ? <DepositSummary /> : <WithdrawSummary/>}</>
            )}
        </Flex>
    );
});
