import * as React from "react";
import {
  FlexProps,
  Flex,
  Text,
  Heading,
  Button,
  Image,
} from "@chakra-ui/react";
import CheckImage from "assets/images/onboarding/check.webp";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
  handleClick: () => void;
}
export default function FirstDeposit({ handleClick, ...rest }: Props) {
  const { t } = useTranslation();
  return (
    <Flex
      justify="space-between"
      p="10"
      bg="gray.800"
      borderRadius="0.75rem"
      height="min-content"
      sx={{ gap: "12px" }}
      {...rest}
    >
      <Flex direction="column">
        <Heading color="white" size="h4_bold" mb="2">
          {t("first.deposit.p1")}
        </Heading>
        <Text size="smallText_regular" color="gray.500" mb="3">
          {t("first.deposit.p2")}
        </Text>
        <Button variant="primary" w="min-content" onClick={handleClick}>
          {t("first.deposit.p3")}
        </Button>
      </Flex>
      <Image
        minW="125px"
        minH="125px"
        w="0"
        h="0"
        src={CheckImage}
        alt=""
        objectFit="contain"
      />
    </Flex>
  );
}
