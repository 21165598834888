import * as React from "react";
import { Flex, FlexProps, Box } from "@chakra-ui/react";

interface Props extends FlexProps {
  steps: number;
  currentStep: number;
}
export default function OnboardingSteps({
  steps,
  currentStep,
  ...rest
}: Props) {
  return (
    <Flex sx={{ gap: "2px" }} mt="1" {...rest}>
      {[...new Array(steps)].map((_, i) => {
        let position = i + 1;
        let bg = "gray.700";
        if (position < currentStep) bg = "yellow.800";
        if (position === currentStep) bg = "gray.600";
        return <Box key={i} w="100%" bg={bg} height="8px" />;
      })}
    </Flex>
  );
}
