import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Text } from "@chakra-ui/react";
import { history } from "../..";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ProfileAddressForm from "features/profiles/ProfileAddressForm";

export default observer(function Three() {
    const {
        profileStore,
        commonStore,
        userStore: { user },
    } = useStore();


    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);

    return (
        <>
            <OnboardingSteps steps={5} currentStep={3} />
            <AuthenticationTemplate
                headingText="Address"
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                        <Text size="paragraph_normal" color="white" mb="6">
                            Personal Information verification can take up to 3 days to be complete. We'll let you know when the verification is
                            complete.
                        </Text>
                        <Text size="paragraph_normal" color="white">
                            Don’t worry, you’ll still be able to access our platform in the meantime.
                        </Text>
                    </>
                }
            >
                {/* Auto complete can be used for all for fields: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */}
                <ProfileAddressForm saveCallback={() => history.push("/onboarding/four")} editMode={true} submitButtonText="Continue"/>
            </AuthenticationTemplate>
        </>
    );
});
