export interface User {
    username: string;
    displayName: string;
    token: string;
    image?: string;
    roles: string[];
    tfaGoogleEnabled: boolean;
    onboardingDone: boolean;
    //TODO: add roles
}

export interface UserFormValues {
    email: string;
    password: string;
    displayName?: string;
    username?: string;
    TFAGoogleCode?: string;
}

export interface TFAFormValues {
    Key: string;
    Code: string;
}

export interface UserPwdFormValues {
    email?: string;
    newPassword: string;
    token?: string;
    password?: string;
}

export const Role = {
    Admin: "admin",
    Customer: "customer",
    Operator: "operator",
};
