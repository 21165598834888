import {
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import { Currency, Rate } from "../models/rate";
import { store } from "./store";

export default class KrakenStore {
    rates: Rate[] = [];
    hubConnection: HubConnection | null = null;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    createHubConnection = () => {
        this.loadingInitial = true;
        try {
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_RATES_URL!, {
                    accessTokenFactory: () => store.userStore.user?.token!,
                })
                .withAutomaticReconnect()
                // .configureLogging(LogLevel.Information)
                .build();

            this.hubConnection
                .start()
                .catch((error) =>
                    console.log("Error establishing the connection: ", error)
                );

            this.hubConnection.on("GetAllRates", (result: Rate[]) => {
                runInAction(() => {
                    //console.log(result);
                    this.rates = result;
                    this.loadingInitial = false;
                });
            });
        } catch (error) {
            //console.log(error);
            this.loadingInitial = false;
        }
        // this.hubConnection.on('ReceiveComment', (comment: string) => {
        //     runInAction(() => {
        //         // comment.createdAt = new Date(comment.createdAt);
        //         this.comments.unshift(comment)
        //     });
        // })
        // }
    };

    stopHubConnection = () => {
        this.hubConnection
            ?.stop()
            .catch((error) =>
                console.log("Error stopping connection: ", error)
            );
    };

    clearRates = () => {
        this.rates = [];
        this.stopHubConnection();
    };

    getRate = (fromCurrency: Currency, toCurrency: Currency) => {
        var zeroRate: Rate  = {
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            value: 0,
        };
        if (this.rates != null) {
            var rate = this.rates.find((r) => r.fromCurrency == fromCurrency && r.toCurrency == toCurrency);
            if (rate != undefined) {
                return rate;
            }
        }
        return zeroRate;
    }
}
