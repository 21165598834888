import { Box, BoxProps, Flex, Text, Button, Spinner, Image } from "@chakra-ui/react";
import TransactionSmallCard from "./TransactionSmallCard";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import { LedgerFilterParams, TransactionType } from "app/models/transaction";
import { FundingType } from "app/models/fundingRequest";
import CreatedImage from "assets/images/account/created.webp";
import { CurrencyLabel } from "app/models/rate";
import { history } from "../../..";
import { PagingParams } from "app/models/pagination";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
    applyFilter?: boolean;
}

export default observer(function RecentActivity({ applyFilter = false, ...rest }: Props) {
    const {
        fundingRequestStore: { fundingType, asset },
        ledgerStore: {
            loading,
            transactionRegistry,
            setPagingParams,
            loadTransactions,
            filterParams,
            setFilterParams,
        },
    } = useStore();

    // useEffect(() => {
    //     if (asset.accountId) {
    //         loadFundingRequests(asset.accountId, fundingType == FundingType.Withdraw, 3);
    //     } else {
    //         setFundingRequests(null);
    //     }
    // }, [asset.accountId, fundingType]);

    useEffect(() => {
        setPagingParams(new PagingParams(1, 2));
        if (applyFilter) {
            var newFP = filterParams;
            if (fundingType)
                newFP.type = [fundingType == FundingType.Deposit ? TransactionType.Deposit.toString() : TransactionType.Withdrawal.toString()];
            newFP.currency = asset.currency ? [asset.currency.toString()] : undefined;
            
            setFilterParams(newFP);
        }
        if (transactionRegistry.size < 1) {
            loadTransactions();
        }
        return () => {
            setFilterParams(new LedgerFilterParams(), false);
            setPagingParams(new PagingParams());
        };
    }, [loadTransactions, asset.currency, fundingType, setFilterParams]);
    const { t } = useTranslation();

    return (
        <Box bg="gray.800" borderRadius="0.75rem" p={{ base: "6", sm: "10" }} {...rest}>
            <Flex justify="space-between" mb={{ base: "4", sm: "6" }}>
                <Text size="overline" color="gray.500">
                    {t("recent.activity.p1")} {applyFilter && CurrencyLabel[asset.currency]} {t("recent.activity.p2")}
                </Text>
                <Button
                    variant="link"
                    fontSize="sm"
                    lineHeight="sm"
                    fontWeight="semibold"
                    letterSpacing="0.125rem"
                    textTransform="uppercase"
                    color="gray.300"
                    onClick={() => history.push("/activity")}
                >
                    {t("view.all")}
                </Button>
            </Flex>
            <>
                <Flex direction="column">
                    {loading ? (
                        // <Skeleton isLoaded={!loading} height='20px'/>
                        <Spinner alignContent={"center"} color="yellow.500" size="xl" />
                    ) : transactionRegistry && transactionRegistry.size > 0 ? (
                        <>
                            {Array.from(transactionRegistry.values()).map((transaction, i) => (
                                <TransactionSmallCard key={transaction.id} transaction={transaction} hasBreak={i < transactionRegistry.size - 1} />
                            ))}
                        </>
                    ) : (
                        <>
                            <Image mx="auto" w="172px" h="173px" mb="10" src={CreatedImage} />
                            <Text mb="10" size="paragraph_semibold" color="gray.400" textAlign="center">
                                {t("recent.activity.p3")} {CurrencyLabel[asset.currency]} {fundingType.toLowerCase()} {t("recent.activity.p4")}
                            </Text>
                        </>
                    )}
                    {/* <TransactionCompactCard hasBreak />
        <TransactionCompactCard isPending /> */}
                </Flex>
            </>
        </Box>
    );
});
