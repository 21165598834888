import * as React from "react";
import { pdf } from "@react-pdf/renderer";
import Grid from "app/components/grid";
import {
    Flex,
    Heading,
    Text,
    Button,
    Modal,
    ModalBody,
    useDisclosure,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    useBreakpointValue,
    ModalHeader,
    ModalFooter,
    Spinner,
    Tooltip,
} from "@chakra-ui/react";
import { Download } from "assets/icons/activity";
import TransactionCard from "./TransactionCard";
import TransactionOverview from "./TransactionOverview";
import FilterPopover from "app/components/filterPopover";
import { useStore } from "app/stores/store";
import { SyntheticEvent, useEffect, useState } from "react";
import { getTransactionStatusOptions, getTransactionTypeOptions, LedgerFilterParams } from "app/models/transaction";
import PdfStatement from "./statement/PdfStatement";
import { observer } from "mobx-react-lite";
import { getCurrencyItems } from "app/models/rate";
import DateFilterPopover from "app/components/filterPopover/DateFilterPopover";
import { getRecordNo, PagingParams } from "app/models/pagination";
import Notification from "app/components/notification";
import { MessageType } from "app/models/message";
import LoadingComponent from "app/layout/LoadingComponent";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';

export default observer(function Activity() {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isOpenCancel, onClose: onCloseCancel, onOpen: onOpenCancel } = useDisclosure();
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);
    const {
        ledgerStore: {
            loading,
            transactionRegistry,
            setPagingParams,
            loadTransactions,
            pagination,
            filterParams,
            setFilterParams,
            getFilterLabel,
            groupedTransations,
            selectedTx,
            setSelectedTx,
            cancelSelectedTransaction,
        },
    } = useStore();
    const openTransactionModal = useBreakpointValue({
        base: onOpen,
        lg: () => {},
    });

    const openCancelModal = React.useMemo(() => {
        return () => {
            onClose();
            onOpenCancel();
        };
    }, [onClose, onOpenCancel]);

    useEffect(() => {
        if (transactionRegistry.size < 1) {
            loadTransactions();
        }
        return () => {
            setFilterParams(new LedgerFilterParams(), false);
        };
    }, [loadTransactions, setFilterParams]);

    function handleGetNext() {
        setLoadingNext(true);
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadTransactions().then(() => setLoadingNext(false));
    }

    const handleDownloadDocument = async (e: SyntheticEvent<HTMLButtonElement>) => {
        setLoadingPdf(true);
        const doc = <PdfStatement data={transactionRegistry} title={"Ledger"} filter={getFilterLabel()} recordNo={getRecordNo(pagination)} />;
        const asPdf = pdf([] as any); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, "PdfStatement.pdf");
        setLoadingPdf(false);
    };

    function handleApplyCurrency(values: string[]) {
        var newFP = filterParams;
        newFP.currency = values;
        setFilterParams(newFP);
    }

    function handleApplyDateInterval(newStartDate?: Date, newEndDate?: Date) {
        var newFP = filterParams;
        newFP.startDate = newStartDate;
        newFP.endDate = newEndDate;
        //console.log(JSON.stringify(newFP));
        setFilterParams(newFP);
    }

    function handleApplyType(values: string[]) {
        var newFP = filterParams;
        newFP.type = values;
        setFilterParams(newFP);
    }

    function handleApplyStatus(values: string[]) {
        var newFP = filterParams;
        newFP.status = values;
        setFilterParams(newFP);
    }

    function handleCancelTx() {
        //TODO: 2b changed to cancel tx.id - refact 2
        cancelSelectedTransaction();
        onCloseCancel();
    }
    const { t } = useTranslation();

    // function getRecordNo() {
    //     return Math.min(pagination!.currentPage * pagination!.itemsPerPage, pagination!.totalItems) + " of " + pagination!.totalItems;
    // }
    if (loading && !loadingNext) return <LoadingComponent />;

    return (
        <>
            <Grid mt={{ base: "6", lg: "10" }}>
                <Flex direction="column" gridColumn={{ base: "1/-1", lg: "1 / span 6", xl: "1 / span 7" }}>
                    <Flex align="center" justify="space-between" mb={{ base: "4", sm: "6" }} direction={{ base: "column", sm: "row" }}>
                        <Flex align="center" direction={{ base: "row", md: "column", lg: "row" }}>
                            <Heading size="h2_bold" color="white" mr={{ base: "3", md: "0", lg: "3" }} mb={{ base: "0", md: "2", lg: "0" }}>
                                {t("navigation.activity")}
                            </Heading>
                            <Text size="smallText_semibold" color="gray.300">
                                {pagination ? pagination.totalItems : "0"} {t("transactions")}
                            </Text>
                        </Flex>
                        <Tooltip hasArrow label="Download displayed transactions as PDF" bg="gray.800" color="white">
                            <Button
                                color="gray.300"
                                variant="overlineLink"
                                isLoading={loadingPdf}
                                isDisabled={!(transactionRegistry && transactionRegistry.size > 0)}
                                onClick={handleDownloadDocument}
                                rightIcon={<Download w="20px" h="20px" />}
                            >
                                {t("download.pdf")}
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Flex
                        mb="6"
                        sx={{ gap: "12px" }}
                        // d={{ base: "none", sm: "flex" }}
                        wrap="wrap"
                    >
                        <FilterPopover
                            key="type"
                            buttonText={t("type")}
                            items={getTransactionTypeOptions()}
                            initialValues={filterParams.type ? filterParams.type : []}
                            applyHandler={handleApplyType}
                        >
                            <></>
                        </FilterPopover>
                        <DateFilterPopover
                            key="date"
                            buttonText={t("date")}
                            initialStartDate={filterParams.startDate}
                            initialEndDate={filterParams.endDate}
                            applyHandler={handleApplyDateInterval}
                        >
                            <></>
                        </DateFilterPopover>
                        <FilterPopover
                            key="asset"
                            buttonText={t("asset")}
                            items={getCurrencyItems()}
                            initialValues={filterParams.currency ? filterParams.currency : []}
                            applyHandler={handleApplyCurrency}
                        >
                            <></>
                        </FilterPopover>
                        <FilterPopover
                            key="Status"
                            buttonText={t("status")}
                            items={getTransactionStatusOptions()}
                            initialValues={filterParams.status ? filterParams.status : []}
                            applyHandler={handleApplyStatus}
                        >
                            <></>
                        </FilterPopover>
                    </Flex>

                    {/* <FiltersMenu mb="6" d={{ base: "flex", sm: "none" }} /> */}
                    <Flex direction="column" height={{ base: "100%", sm: "600px" }} overflow="auto">
                        {loading && !loadingNext ? (
                            <Spinner key="spinner" alignContent={"center"} color="yellow.500" size="xl" />
                        ) : groupedTransations && groupedTransations.length > 0 ? (
                            <>
                                {groupedTransations.map(([group, transactions]) => (
                                    <Flex direction="column" key={group}>
                                        <Text mb="6" mt="4" color="gray.300" variant="paragraph_normal" key={group}>
                                            {group}
                                        </Text>
                                        {transactions.map((transaction) => (
                                            // Array.from(transactionRegistry.values()).map((transaction) => (
                                            <TransactionCard
                                                key={transaction.id}
                                                isSelected={selectedTx && transaction.id === selectedTx.id}
                                                transaction={transaction}
                                                cursor="pointer"
                                                mb="4"
                                                onClick={(e) => {
                                                    setSelectedTx(transaction);
                                                    openTransactionModal && openTransactionModal();
                                                }}
                                            />
                                        ))}
                                    </Flex>
                                ))}
                                {pagination && pagination!.totalPages > 1 && (
                                    <Flex align="center" direction="row" justify="end" mb="4">
                                        <Text size="smallText_semibold" color="gray.300" mr="3">
                                            {t("pagination.p1")} {getRecordNo(pagination)} {t("pagination.p2")}
                                        </Text>
                                        <Button
                                            variant="primary"
                                            isLoading={loadingNext}
                                            isDisabled={!(!loadingNext && !!pagination && pagination.currentPage < pagination.totalPages)}
                                            onClick={handleGetNext}
                                        >
                                            {t("display.more.bttn")}
                                        </Button>
                                    </Flex>
                                )}
                            </>
                        ) : (
                            <Notification message="No data." bg="gray.700" mb="6" messageStyle={{ fontWeight: "normal" }} type={MessageType.Info} />
                        )}
                    </Flex>
                </Flex>
                {selectedTx && (
                    <TransactionOverview
                        gridColumn={{ base: "1/-1", lg: "7 / span 6", xl: "8 / span 5" }}
                        d={{ base: "none", lg: "flex" }}
                        handleCancel={openCancelModal}
                        transaction={selectedTx}
                    />
                )}
            </Grid>
            <Modal isOpen={isOpen} onClose={onClose} variant="transparent">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody bg="transparent">
                        {selectedTx && <TransactionOverview w="100%" transaction={selectedTx} handleCancel={openCancelModal} />}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenCancel} onClose={onCloseCancel}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader> {t("cancel.tx.btn")} </ModalHeader>
                    <ModalBody>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
                        ipsa quae ab illo.
                    </ModalBody>
                    <ModalFooter>
                        <Flex
                            justify="space-between"
                            w="100%"
                            sx={{ gap: "12px" }}
                            align={{ base: "flex-stat", sm: "center" }}
                            direction={{ base: "column", sm: "row" }}
                        >
                            <Button variant="secondary" onClick={onCloseCancel}>
                                No, Don’t Cancel Transaction
                            </Button>
                            <Button variant="primary" isLoading={loading} isDisabled={loading} onClick={handleCancelTx}>
                                Yes, Cancel Transaction
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
});
