import { Alert, Info, Check } from "assets/icons/unauthenticated";

export  enum MessageType {
    Info,
    Error,
    Success,
};

export function getIcon(messageType: MessageType) {
    switch (messageType) {
        case MessageType.Info:
            return <Info/>;
        case MessageType.Error:
            return <Alert/>;
        case MessageType.Success:
                return <Check/>;
        default:
            return <Info/>;
    }
}
