import { Flex, FlexProps, Heading, Menu, MenuButton, Text, MenuList, MenuItem, useBreakpointValue, Grid, Image } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useState } from "react";
import { CryptoCurrencies, Currency, geDisplayValue, getCurrencyLabel, getFiatCurrencyOptions } from "app/models/rate";
import { useTranslation } from "react-i18next";

export default observer(function ExchangeRates(props: FlexProps) {
    const { t } = useTranslation();
    const {
        krakenStore: { rates, loadingInitial, getRate },
    } = useStore();
    const [currency, setCurrency] = useState(Currency.EUR);

    const sortFontSize = useBreakpointValue({
        base: "smallText_normal",
        sm: "smallText_semibold",
    });
   

    return (
        <Flex
            bg="gray.800"
            borderRadius="0.75rem"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            p={{ base: "4", md: "10" }}
            direction="column"
            color="white"
            {...props}
        >
            <Flex align="center" justify="space-between" mb="6">
                <Heading size="h4_bold">{t("exchange.rate")}</Heading>
                <Menu>
                    <MenuButton
                        ml={{ base: "2", sm: "4" }}
                        sx={{
                            "> span": {
                                display: "flex",
                                alignItems: "center",
                            },
                        }}
                    >
                        <Text
                            as="span"
                            size="smallText_semibold"
                            color={{ base: "white", sm: "gray.500" }}
                            whiteSpace="nowrap"
                            display={{ base: "none", sm: "flex" }}
                        >
                            {t("currency")}:
                        </Text>
                        <Text as="span" size={sortFontSize} color="white" ml="1" whiteSpace="nowrap">
                            {getCurrencyLabel(currency)}
                        </Text>
                        <ArrowDown ml="1" color="gray.500" />
                    </MenuButton>
                    <MenuList>
                        {getFiatCurrencyOptions().map((currencyOption) => (
                            <MenuItem key={currencyOption.value} onClick={() => setCurrency(currencyOption.value)}>
                                {currencyOption.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Flex>
            <Grid templateColumns="1fr 1fr 1fr" mb="4" d={{ base: "none", sm: "grid" }}>
                <Text size="smallText_normal" color="gray.300">
                    {t("landing.rates.p2")}
                </Text>
                <Text size="smallText_normal" color="gray.300" textAlign="center">
                    {t("landing.rates.p3")}
                </Text>
                <Text size="smallText_normal" color="gray.300" textAlign="right" mr="3">
                {t("landing.rates.p4")}
                </Text>
            </Grid>
            <Flex direction={{ base: "row", sm: "column" }} overflowY="auto" pb="2" mb="-2" pr="4" mr="-4">
                {CryptoCurrencies.map((cryptoCurrency, i) => (
                    <Grid
                        key={cryptoCurrency}
                        flex="0 0 auto"
                        templateColumns={{ base: "1fr", sm: "1fr 1fr 1fr" }}
                        mb={{ base: "0", sm: 3 }}
                        mr={{ base: 2, sm: 0 }}
                        p="3"
                        bg="black"
                        borderRadius="0.5rem"
                        color="white"
                        alignItems="center"
                    >
                        <Flex>
                            <Flex ml={{ base: "0", sm: "3" }} mb={{ base: "4", sm: "0" }} align="center">
                                <Image w="40px" h="40px" src={"/coins/" + cryptoCurrency.toLowerCase() + ".svg"} />
                                <Flex direction="column" ml="4">
                                    <Text size="paragaph_semibold">{getCurrencyLabel(cryptoCurrency)}</Text>
                                    <Text size="smallText_normal" color="gray.500">
                                        {cryptoCurrency}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Text textAlign="center" size="paragaph_normal" d="flex" justifyContent="center" mb={{ base: "3", sm: "0" }}>
                            <Text as="span" color="gray.300" size="smallText_normal" d={{ base: "flex", sm: "none" }} mr="2">
                                {t("landing.rates.p3")}
                            </Text>
                            {rates && geDisplayValue(getRate(currency, cryptoCurrency)!)}
                        </Text>
                        <Text textAlign="center" size="paragaph_normal" d="flex" justifyContent="center">
                            <Text as="span" color="gray.300" size="smallText_normal" d={{ base: "flex", sm: "none" }} mr="2">
                            {t("landing.rates.p4")}
                            </Text>
                            {rates && geDisplayValue(getRate(cryptoCurrency, currency)!)}
                        </Text>
                    </Grid>
                ))}
            </Flex>
        </Flex>
    );
});
