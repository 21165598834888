import * as React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {
  variant: "primary" | "secondary";
  children: string;
}

export default function PercentageButton({
  variant,
  children,
  ...rest
}: Props) {
  return (
    <Button
      variant={variant}
      padding="2"
      fontSize="xs"
      lineHeight="xs"
      {...rest}
    >
      {children}
    </Button>
  );
}
