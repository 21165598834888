import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Text, Flex } from "@chakra-ui/react";
import { Upload } from "assets/icons/onboarding";

interface Props {
    setFiles: (files: any) => void;
}

const maxSizeMB = 2;
const maxSize = maxSizeMB * 1048576; //bytes

export default function PhotoWidgetDropzone({ setFiles }: Props) {
    const [uploaded, setUploaded] = React.useState(false);
    const dzStyles = {
        border: "dashed 3px #eee",
        borderColor: "#eee",
        borderRadius: "5px",
        paddingTop: "30px",
        textAlign: "center" as "center",
        height: 200,
    };

    const dzActive = {
        borderColor: "green",
    };

    function sizeValidator(file: any) {
        if (file.size > maxSize) {
            return {
                code: "size-too-large",
                message: `Size is larger than ${maxSizeMB} MB`,
            };
        }

        return null;
    }

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file: any) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            setUploaded((v) => !v);
        },
        [setFiles]
    );

    const { fileRejections, getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        accept: "image/jpeg, image/png",
        maxFiles: 1,
        validator: sizeValidator,
        onDrop,
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <>
            {file.name} - {file.size} bytes
            <ul>
                {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </>
    ));

    const acceptedFilesItems = acceptedFiles.map((file) => file.name + " (" + file.size + " bytes)");

    return (
        <div {...getRootProps()}>
            <Flex
                p="10"
                w="100%"
                bg="black"
                borderWidth="1px"
                borderStyle="dashed"
                borderColor={isDragActive ? "yellow.300" : "yellow.800"}
                borderRadius="0.75rem"
                direction="column"
                align="center"
                justify="center"
                cursor="pointer"
                mb="6"
                // onClick={() => setUploaded((v) => !v)}
            >
                <input {...getInputProps()} />
                <Flex minW="48px" minH="48px" borderRadius="50%" bg="yellow.800" justify="center" align="center" mb="6">
                    <Upload w="20px" h="20px" />
                </Flex>
                <Text
                    size="paragraph_semibold"
                    mb="2"
                    color="white"
                    textAlign="center"
                    d="flex"
                    alignItems="center"
                    // cursor={uploaded ? "pointer" : "default"}
                >
                    {acceptedFiles.length > 0 ? "Uploaded " +  acceptedFilesItems.reduce((acc, item) => acc + item) : "Drop or Upload document"}
                </Text>
                <Text size="smallText_normal" color="gray.500" textAlign="center">
                    JPEG or PNG up to 2MB
                </Text>
                {fileRejections.length > 0 && (
                    <Text
                    size="paragraph_semibold"
                        color="red"
                    >
                        Invalid file!
                    </Text>
                )}
            </Flex>
            {/* <div
                {...getRootProps()}
                style={isDragActive ? { ...dzStyles, ...dzActive } : dzStyles}
            >
                <input {...getInputProps()} />
                <Icon name="upload" size="huge" />
                <Text size="paragraph_bold" color="white" mb="10">Drop image here</Text>
                <Text size="smallText_semibold" color="white">(Only *.jpeg and *.png images, smaller than {maxSizeMB} MB will be accepted)</Text>
            </div> */}
            {/* <aside>
                {fileRejections.length > 0 && (
                    <Text
                        style={{
                            marginBottom: 10,
                        }}
                        basic
                        color="red"
                    >
                        {fileRejectionItems}
                    </Text>
                )}
            </aside> */}
        </div>
    );
}
