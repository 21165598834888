import * as React from "react";
import { Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";
import { Close } from "assets/icons/home";
import { Alert, Info } from "assets/icons/unauthenticated";
import { getIcon, MessageType } from "app/models/message";
import { useTranslation } from "react-i18next";

export interface Props extends FlexProps {
  message: string;
  type: MessageType;
  children?: React.ReactNode;
  handleClose?: () => void;
  messageStyle?: TextProps;
}

export default function Notification({
  message,
  type = MessageType.Info,
  children,
  handleClose,
  messageStyle,
  ...rest
}: Props) {
  const { t } = useTranslation();
  return (
    <Flex
      bg="gray.800"
      p="4"
      borderRadius="0.75rem"
      align="center"
      color="white"
      {...rest}
    >
      <Flex
        minW="44px"
        minH="44px"
        w="0"
        h="0"
        borderRadius="full"
        bg="black"
        align="center"
        justify="center"
      >
        {getIcon(type)}
      </Flex>
      <Text size="paragraph_semibold" ml="3" {...messageStyle}>
        {t(message)}
      </Text>
      {children ? (
        <Flex ml="3" mr="4">
          {children}
        </Flex>
      ) : null}
      {handleClose ? (
        <Close ml="auto" cursor="pointer" onClick={handleClose} />
      ) : null}
    </Flex>
  );
}
