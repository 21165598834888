import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useState } from "react";
import { Flex, Heading, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useBreakpointValue, IconButton, Button } from "@chakra-ui/react";
import { Bin } from "assets/icons/activity";
import { Copy } from "assets/icons/home";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { CurrencyLabel, Rate } from "../../../app/models/rate";
import { useStore } from "../../../app/stores/store";
import RateFeeForm from "./RateFeeForm";

export default observer(function RateFees() {
    const {
        adminStore: { loading, initialLoading, rateFees, loadRateFees, deleteRateFee },
        userStore: { isAuthorized },
    } = useStore();
    const headingSize = useBreakpointValue({ base: "h4_bold", sm: "h4_bold" });
    const [target, setTarget] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [rateFee, setRateFee] = useState<Rate>();

    useEffect(() => {
        if (isAuthorized) {
            loadRateFees();
        }
    }, [isAuthorized, loadRateFees]);

    function handleDeleteFee(fee: Rate, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        deleteRateFee(fee);
    }

    function handleEditFee(fee: Rate, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        setRateFee(fee);
        setEditMode(true);
    }

    if (initialLoading) return <LoadingComponent />;

    return (
        <Flex gridColumn={{ base: "1/-1" }} mb={{ base: "0", lg: "0" }} direction="column">
            <Flex justify="space-between" mb={{ base: "2", sm: "4" }}>
                <Heading my="2" color="white" gridColumn="1/-1" size={headingSize}>
                    Exchange Rate Fees
                </Heading>

                <Button
                    variant="overlineLink"
                    onClick={() => {
                        setEditMode(!editMode);
                        setRateFee(undefined);
                    }}
                >
                    {editMode ? "Cancel" : "Add Fee"}
                </Button>
            </Flex>

            <Flex
                gridColumn={{ base: "1/-1" }}
                mb={{ base: "6", lg: "0" }}
                direction="column"
                justify="center"
                py="3"
                bg="gray.800"
                borderRadius="0.75rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.700"
                overflowX="auto"
            >
                {editMode ? (
                    <Flex direction="column" justify="center" px="6" py="6">
                        <RateFeeForm setAddRateFeeMode={setEditMode} selectedFee={rateFee} />
                    </Flex>
                ) : (
                    rateFees &&
                    rateFees.length > 0 && (
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    <Th color="gray.500">Actions</Th>
                                    <Th color="gray.500">From Currency</Th>
                                    <Th color="gray.500">To Currency</Th>
                                    <Th color="gray.500" isNumeric>
                                        Percentage Value
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody color="white" bg="gray.900">
                                {rateFees.map((fee) => (
                                    <Tr key={fee.fromCurrency + fee.toCurrency}>
                                        <Td>
                                            <Flex direction="row">
                                                <Tooltip label="Edit">
                                                    <IconButton
                                                        aria-label="Edit"
                                                        mr="2"
                                                        colorScheme="gray.800"
                                                        icon={<Copy w="20px" h="20px" />}
                                                        name={"Edit" + fee.fromCurrency + fee.toCurrency}
                                                        isLoading={target === "Edit" + fee.fromCurrency + fee.toCurrency && loading}
                                                        onClick={(e) => handleEditFee(fee, e)}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Delete">
                                                    <IconButton
                                                        aria-label="Delete"
                                                        colorScheme="gray.500"
                                                        icon={<Bin w="20px" h="20px" />}
                                                        name={"Delete" + fee.fromCurrency + fee.toCurrency}
                                                        isLoading={target === "Delete" + fee.fromCurrency + fee.toCurrency && loading}
                                                        onClick={(e) => handleDeleteFee(fee, e)}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                        <Td>{CurrencyLabel[fee.fromCurrency]}</Td>
                                        <Td>{CurrencyLabel[fee.toCurrency]}</Td>
                                        <Td isNumeric>{fee.value.toString()}%</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )
                )}
            </Flex>
        </Flex>
    );
});
