import * as React from "react";
import { pdf } from "@react-pdf/renderer";
import Grid from "app/components/grid";
import {
    Flex,
    Heading,
    Text,
    Button,
    Modal,
    ModalBody,
    useDisclosure,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    useBreakpointValue,
    ModalHeader,
    ModalFooter,
    Spinner,
    IconButton,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
} from "@chakra-ui/react";
import { Bin, Download } from "assets/icons/activity";
import FilterPopover from "app/components/filterPopover";
import { useStore } from "app/stores/store";
import { SyntheticEvent, useEffect, useState } from "react";
import {
    getTransactionAmount,
    getTransactionFee,
    getTransactionRate,
    getTransactionStatusLabel,
    getTransactionStatusOptions,
    getTransactionTypeLabel,
    getTransactionTypeOptions,
    LedgerFilterParams,
    TransactionStatus,
    TransactionType,
} from "app/models/transaction";
// import PdfStatement from "../../activity/statement/PdfStatement";
import { observer } from "mobx-react-lite";
import { getCurrencyItems } from "app/models/rate";
import DateFilterPopover from "app/components/filterPopover/DateFilterPopover";
import { getRecordNo, PagingParams } from "app/models/pagination";
import Notification from "app/components/notification";
import { MessageType } from "app/models/message";
import LoadingComponent from "app/layout/LoadingComponent";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { format, lastDayOfMonth, setDate } from "date-fns";

export default observer(function AllTransactions() {
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);
    const {
        reportsStore: { loading, transactions, loadTransactions, pagination, filterParams, setFilterParams, getTxsCSV },
        userStore: { isAuthorized },
    } = useStore();

    useEffect(() => {
        if (isAuthorized) {
            if (transactions.length < 1) {
                var newFP = filterParams;
                newFP.startDate = setDate(new Date(), 1);
                newFP.status = [TransactionStatus.Confirmed.toString()];
                setFilterParams(newFP);
                loadTransactions();
            }
        }
        return () => {
            setFilterParams(new LedgerFilterParams(), false);
        };
    }, [loadTransactions, setFilterParams]);

    const handleDownloadDocument = async () => {
        setLoadingPdf(true);
        // const doc = <PdfStatement data={transactionRegistry} title={"Ledger"} filter={getFilterLabel()} recordNo={getRecordNo(pagination)} />;
        // const asPdf = pdf([] as any); // {} is important, throws without an argument
        // asPdf.updateContainer(doc);
        // const blob = await asPdf.toBlob();
        saveAs(getTxsCSV(), "CSVExport.csv");
        setLoadingPdf(false);
    };

    function handleApplyCurrency(values: string[]) {
        var newFP = filterParams;
        newFP.currency = values;
        setFilterParams(newFP);
    }

    function handleApplyDateInterval(newStartDate?: Date, newEndDate?: Date) {
        var newFP = filterParams;
        newFP.startDate = newStartDate;
        newFP.endDate = newEndDate;
        // console.log(JSON.stringify(newFP));
        setFilterParams(newFP);
    }

    function handleApplyType(values: string[]) {
        var newFP = filterParams;
        newFP.type = values;
        setFilterParams(newFP);
    }

    function handleApplyStatus(values: string[]) {
        var newFP = filterParams;
        newFP.status = values;
        setFilterParams(newFP);
    }

    const { t } = useTranslation();

    if (loading && !loadingNext) return <LoadingComponent />;

    return (
        <>
            <Grid mt={{ base: "6", lg: "10" }}>
                <Flex direction="column" gridColumn={{ base: "1/-1" }}>
                    <Flex align="center" justify="space-between" mb={{ base: "4", sm: "6" }} direction={{ base: "column", sm: "row" }}>
                        <Flex align="center" direction={{ base: "row", md: "column", lg: "row" }}>
                            <Heading size="h2_bold" color="white" mr={{ base: "3", md: "0", lg: "3" }} mb={{ base: "0", md: "2", lg: "0" }}>
                                All Transactions
                            </Heading>
                            <Text size="smallText_semibold" color="gray.300">
                                {pagination ? pagination.totalItems : "0"} {t("transactions")}
                            </Text>
                        </Flex>
                        <Tooltip hasArrow label="Download displayed transactions as CSV" bg="gray.800" color="white">
                            <Button
                                color="gray.300"
                                variant="overlineLink"
                                isLoading={loadingPdf}
                                isDisabled={!(transactions && transactions.length > 0)}
                                onClick={handleDownloadDocument}
                                rightIcon={<Download w="20px" h="20px" />}
                            >
                                Download CSV
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Flex
                        mb="6"
                        sx={{ gap: "12px" }}
                        // d={{ base: "none", sm: "flex" }}
                        wrap="wrap"
                    >
                        <FilterPopover
                            key="type"
                            buttonText="Type"
                            items={getTransactionTypeOptions()}
                            initialValues={filterParams.type ? filterParams.type : []}
                            applyHandler={handleApplyType}
                        >
                            <></>
                        </FilterPopover>
                        <DateFilterPopover
                            key="date"
                            buttonText="Date"
                            initialStartDate={filterParams.startDate}
                            initialEndDate={filterParams.endDate}
                            applyHandler={handleApplyDateInterval}
                        >
                            <></>
                        </DateFilterPopover>
                        <FilterPopover
                            key="asset"
                            buttonText="Asset"
                            items={getCurrencyItems()}
                            initialValues={filterParams.currency ? filterParams.currency : []}
                            applyHandler={handleApplyCurrency}
                        >
                            <></>
                        </FilterPopover>
                        <FilterPopover
                            key="Status"
                            buttonText="Status"
                            items={getTransactionStatusOptions()}
                            initialValues={filterParams.status ? filterParams.status : []}
                            applyHandler={handleApplyStatus}
                        >
                            <></>
                        </FilterPopover>
                    </Flex>

                    <Flex direction="column" height={{ base: "100%", sm: "600px" }} overflow="auto">
                        {loading && !loadingNext ? (
                            <Spinner key="spinner" alignContent={"center"} color="yellow.500" size="xl" />
                        ) : transactions && transactions.length > 0 ? (
                            <>
                                <Table size="sm" variant="striped" colorScheme="blackAlpha">
                                    <Thead bg="gray.800">
                                        <Tr>
                                            <Th color="gray.500">Creation Date</Th>
                                            <Th color="gray.500">Owner</Th>
                                            <Th color="gray.500">Type</Th>
                                            <Th color="gray.500">Status</Th>
                                            <Th color="gray.500" isNumeric>
                                                Positive Amount
                                            </Th>
                                            <Th color="gray.500" isNumeric>
                                                Negative Amount
                                            </Th>
                                            <Th color="gray.500" isNumeric>
                                                Fee
                                            </Th>
                                            <Th color="gray.500" isNumeric>
                                                Rate
                                            </Th>
                                            <Th color="gray.500">External reference</Th>
                                        </Tr>
                                    </Thead>

                                    <Tbody color="white" bg="gray.900">
                                        {transactions.map((transaction) => (
                                            <Tr
                                                key={transaction.id}
                                                disabled={transaction.status == TransactionStatus.Canceled}
                                                // color={transaction.status == TransactionStatus.Confirmed ? "green.800" : "white"}
                                            >
                                                <Td>{format(transaction.date, "dd MMM yyyy h:mm aa")}</Td>
                                                <Td>
                                                    <a href={`/admin/profiles/${transaction.ownerName}`} title="Go to Profile">
                                                        {transaction.ownerName}
                                                    </a>
                                                </Td>
                                                <Td>{t(getTransactionTypeLabel(transaction.type).toLowerCase())}</Td>
                                                <Td>{t(getTransactionStatusLabel(transaction.status).toLowerCase())}</Td>
                                                <Td isNumeric>
                                                    {getTransactionAmount(transaction, true)}
                                                    {/* {getTransactionCurrency(transaction, transaction.type == TransactionType.Deposit)} */}
                                                </Td>
                                                <Td isNumeric>
                                                    {getTransactionAmount(transaction, false)}
                                                    {/* {getTransactionCurrency(transaction, transaction.type == TransactionType.Deposit)} */}
                                                </Td>
                                                <Td isNumeric>{getTransactionFee(transaction, false)}</Td>
                                                <Td isNumeric>{getTransactionRate(transaction)}</Td>
                                                <Td overflow="hidden" textOverflow="ellipsis" maxWidth="15em">
                                                    {transaction.details}
                                                    {/* {transaction.additionalInfo} */}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </>
                        ) : (
                            <Notification message="No data." bg="gray.700" mb="6" messageStyle={{ fontWeight: "normal" }} type={MessageType.Info} />
                        )}
                    </Flex>
                </Flex>
            </Grid>
        </>
    );
});
