import { Currency } from "./rate";
import { Add, Remove, Trade } from "assets/icons/activity";
import { TransactionType } from "./transaction";

// export interface FundingRequest {
//     id: string;
//     accountId: string;
//     currency: string;
//     amount: number;
//     amountAfterFees: number;
//     externalId: string;    // TX_ID, Whitelisted Address or IBAN
//     additionalInfo: string;
//     isDebit: boolean;
//     date: Date;
//     status: number;
//     approvedBy: string;
//     ownerName: string;
//     updateDate: Date;
// }

// export class FundingRequest implements FundingRequest {
//     constructor(init?: FundingRequestFormValues) {
//         Object.assign(this, init);
//       }
// }

export class FundingRequestFormValues {
    accountId: string = '';
    amount?: number ;
    isDebit: boolean = false;
    externalId?: string;
    additionalInfo?: string;
    currency?: Currency;
    constructor(init?: FundingRequestFormValues) {
        Object.assign(this, init);
      }
}

// export const FundingType = {
//     deposit: "Deposit",
//     withdrawal: "Withdraw",
// };

export  enum FundingType {
    Deposit = "Deposit",
    Withdraw = "Withdraw",
};

export function getFundingTypeLabel(isDebit: boolean) {
    if (isDebit) return FundingType.Withdraw
    else return FundingType.Deposit
}

export function getFundingIcon(isDebit: boolean) {
    if (isDebit) return <Remove color="white" w="20px" h="20px" />
    else return <Add color="white" w="20px" h="20px" />
}

export function getTransactionIcon(transactionType: number, size: string = "20px") {
    switch (transactionType) {
        case TransactionType.Deposit:
            return <Add color="white" w={size} h={size} />;
        case TransactionType.Withdrawal:
            return <Remove color="white" w={size} h={size} />;
        case TransactionType.Exchange:
            return <Trade color="white" w={size} h={size} />;
        default:
            return "";
    }
}

// export const FundingRequestStatus = {
//     Pending: 0,
//     Approved: 1,
//     Canceled: 2,
// };

// export function getFundingRequestStatusLabel(fundingRequestStatus: number) {
//     switch (fundingRequestStatus) {
//         case FundingRequestStatus.Pending:
//             return "Pending";
//         case FundingRequestStatus.Approved:
//             return "Approved";
//         case FundingRequestStatus.Canceled:
//             return "Canceled";
//         default:
//             return "";
//     }
// }