export interface Pagination {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export class PaginatedResult<T> {
    data: T;
    pagination: Pagination;

    constructor(data: T, pagination: Pagination) {
        this.data = data;
        this.pagination = pagination;
    }
}

export class PagingParams {
    pageNumber;
    pageSize;

    constructor(pageNumber = 1, pageSize = 10) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
    }
}

export function getRecordNo(pagination: Pagination | undefined) {
    if (pagination === undefined) return "";
    return Math.min(pagination.currentPage * pagination.itemsPerPage, pagination.totalItems) + " of " + pagination.totalItems;
}