import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Text } from "@chakra-ui/react";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import ProfileFiatAccountForm from "features/profiles/ProfileFiatAccountForm";

export default observer(function Seven() {
    const {  profileStore, userStore: { user } } = useStore();
  
    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);
    
    return (
        <>
            <OnboardingSteps steps={5} currentStep={5} />
            <AuthenticationTemplate
                headingText="Add a Bank Account"
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                        <Text size="paragraph_normal" color="white" mb="6">
                            Bank Account verification can take up to 2 days to be complete. We’ll let you know when the verification is complete.
                        </Text>
                        <Text size="paragraph_normal" color="white">
                            Don’t worry, you’ll still be able to access our platform in the meantime.
                        </Text>
                    </>
                }
            >
                <ProfileFiatAccountForm saveCallback={() => history.push("/onboarding/success")} editMode={true} submitButtonText="Continue"/>
                
            </AuthenticationTemplate>
        </>
    );
});
