import { Flex, Text, Input, InputGroup, InputLeftElement, Image, InputRightElement } from "@chakra-ui/react";
import { Close, Search } from "assets/icons/home";
import { CurrencyLabel } from "app/models/rate";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Asset } from "app/models/asset";
import { useTranslation } from "react-i18next";

interface Props {
    assets: Asset[] | undefined;
    setAsset: (assets: Asset) => void;
}

export default observer(function AssetList({ assets, setAsset }: Props) {
    const { t } = useTranslation();
    const [filter, setFilter] = useState("");
    

    return (
        <>
            <InputGroup mb="3">
                <InputLeftElement my="auto" bottom="0">
                    <Search w="20px" h="20px" color="gray.300" />
                </InputLeftElement>
                <Input variant="filled" placeholder="Search asset..." value={filter}  onChange={(e) => setFilter(e.currentTarget.value.toLowerCase())} />
                <InputRightElement my="auto" bottom="0" cursor="pointer" onClick={() => setFilter("")}>
                    {filter ? <Close w="16px" h="16px" color="white" /> : null}
                </InputRightElement>
            </InputGroup>
            <Flex direction="column" overflowY="auto">
                {assets &&
                    assets
                        .filter(
                            (e) => e.currency.toLowerCase().indexOf(filter) !== -1 || CurrencyLabel[e.currency].toLowerCase().indexOf(filter) !== -1
                        )
                        .map((asset) => {
                            return (
                                <Flex
                                    key={asset.currency}
                                    align="center"
                                    p="3"
                                    borderRadius="0.25rem"
                                    justify="space-between"
                                    _hover={{ bg: "gray.700" }}
                                    cursor="pointer"
                                    onClick={() => {
                                        // setAccount(user!.username, currency);
                                        setAsset(asset);
                                        // onSelect();
                                    }}
                                >
                                    <Flex align="center">
                                        <Image
                                            minW="40px"
                                            minH="40px"
                                            w="0"
                                            h="0"
                                            // src="/coins/btc.svg"
                                            src={"/coins/" + asset.currency.toLowerCase() + ".svg"}
                                            mr="4"
                                        />
                                        <Flex direction="column">
                                            <Text mb="1" color="white" size="paragraph_semibold">
                                                {CurrencyLabel[asset.currency]}
                                            </Text>
                                            <Text size="smallText_normal" color="gray.500">
                                                {asset.currency}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    <Text size="smallText_normal" color="gray.300">
                                        {t("balance")} {asset.balance}
                                    </Text>
                                </Flex>
                            );
                        })}
            </Flex>
        </>
    );
});
