import * as React from "react";
import {
  Box,
  BoxProps,
  Flex,
  Grid as ChakraGrid,
  GridProps,
} from "@chakra-ui/react";

function Spacer(props: BoxProps) {
  return (
    <Box
      w="0"
      minW={{
        base: "1rem",
        md: "2.5rem",
        xl: "5.625rem",
      }}
      {...props}
    />
  );
}

interface Props extends BoxProps {
  children: React.ReactNode;
  gridProps?: GridProps;
  spacerStyles?: BoxProps;
}

export default function Grid({
  children,
  gridProps,
  spacerStyles,
  ...rest
}: Props) {
  return (
    <Flex w="auto" justify="center" {...rest}>
      <Spacer {...spacerStyles} />
      <ChakraGrid
        maxW="82.5em"
        w="100%"
        templateColumns={{
          base: "repeat(4, minmax(0, 1fr))",
          md: "repeat(12, minmax(0, 1fr))",
        }}
        columnGap={{
          base: "0.5rem",
          sm: "1.5rem",
        }}
        {...gridProps}
      >
        {children}
      </ChakraGrid>
      <Spacer {...spacerStyles} />
    </Flex>
  );
}
