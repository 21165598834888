import * as React from "react";
import { IconButtonProps, IconButton as CIconButton } from "@chakra-ui/react";

interface Props extends IconButtonProps {
  variant?: "primary" | "secondary";
}

export default function IconButton({ variant = "secondary", ...rest }: Props) {
  const isPrimary = variant === "primary";
  return (
    <CIconButton
      style={{ height: 0, width: 0 }}
      minW="48px"
      minH="48px"
      borderRadius="50%"
      bg={isPrimary ? "yellow.800" : "gray.700"}
      _hover={{ bg: isPrimary ? "yellow.500" : "gray.600" }}
      _active={{ bg: isPrimary ? "yellow.500" : "gray.600" }}
      _disabled={{
        opacity: "0.4",
        _hover: { bg: isPrimary ? "yellow.200" : "gray.700" },
      }}
      _focus={{ boxShadow: "none", bg: isPrimary ? "yellow.500" : "gray.600" }}
      {...rest}
    />
  );
}
