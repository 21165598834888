import { showToast } from "app/components/toast";
import { MessageType } from "app/models/message";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Exchange, ExchangeFormValues } from "../models/exchange";
import { GenericAccount } from "../models/fiatAccount";
import { Currency, isFiat } from "../models/rate";

export default class ExchangeStore {
    exchange: Exchange | null = null;
    fromAccount: GenericAccount | null = null;
    toAccount: GenericAccount | null = null;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadAccounts = async (username: string, fromCurrency: Currency, toCurrency: Currency) => {
        this.loading = true;
        try {
            const profile = await agent.Profiles.get(username);
            runInAction(() => {
                if (isFiat(fromCurrency)) {
                    this.fromAccount =
                        profile.fiatAccounts?.find((x) => x.currency === fromCurrency && x.validated)!;
                    this.toAccount =
                        profile.cryptoAccounts?.find((x) => x.currency === toCurrency)!;
                } else {
                    this.fromAccount = 
                        profile.cryptoAccounts?.find((x) => x.currency === fromCurrency)!;
                    this.toAccount =
                        profile.fiatAccounts?.find((x) => x.currency === toCurrency && x.validated)!;
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    }

    createExchange = async (exchange: ExchangeFormValues) => {
        this.loading = true;
        try {
            const response = await agent.Exchanges.create(exchange);
            const newExchange = new Exchange(exchange);
            newExchange.amountTo = response.amountTo;
            newExchange.rateValue = response.rateValue;
            
            runInAction(() => {
                this.exchange = newExchange;
                // toast.success("Exchange done");
                showToast("exchange.ok", MessageType.Success);
                // history.push("/exchange/success");
                this.loading = false;
            });
            
        } catch (error) {
            //console.log(error);
            runInAction(() => {
                showToast("exchange.error", MessageType.Error);
                this.loading = false;});
        }
    };
    
}
