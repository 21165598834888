import { extendTheme } from "@chakra-ui/react";

const global = {
  "html, body": {
    bg: "black",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: " grayscale",
  },
  "select > option": {
    bg: "var(--chakra-colors-black)!important",
  },
};

const fonts = {
  heading: "'Inter', sans-serif",
  body: "'Inter', sans-serif",
  mono: "'Inter', monospace",
};

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.3125rem",
  xl: "2rem",
  "2xl": "2.5rem",
  "3xl": "3.875rem",
};

const lineHeights = {
  xs: "1rem",
  sm: "1.5rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "2.5rem",
  "2xl": "3.5rem",
  "3xl": "4rem",
};

const colors = {
  black: "#0F0F13",
  white: "#FFFFFF",
  gray: {
    "50": "#FCFCFD",
    "100": "#FCFCFD",
    "200": "#F1F2F4",
    "300": "#E5E7EA",
    "400": "#D1D2D6",
    "500": "#9EA1A9",
    "600": "#5E6370",
    "700": "#343841",
    "800": "#1D1F25",
    "900": "#14161A",
  },
  yellow: {
    "200": "#FDD888",
    "500": "#FFC860",
    "800": "#FEAF17",
  },
  red: {
    "200": "#FEA4A4",
    "500": "#FD7575",
    "800": "#FC4545",
  },
  green: {
    "200": "#8FFFDD",
    "500": "#02DF9D",
    "800": "#04B07D",
  },
  pink: {
    "500": "#CA5AF2",
  },
  teal: {
    "500": "#2FE7BB",
  },
  cyan: {
    "500": "#5AD7F2",
  },
  purple: {
    "500": "#5E5CE6",
  },
};

const breakPoints = {
  base: "0em",
  sm: "47.5em",
  md: "64em",
  lg: "80em",
  xl: "97.5em",
};

const Button = {
  baseStyle: {
    fontWeight: "semibold",
    borderRadius: "8px",
    _disabled: {
      opacity: "0.5",
    },
  },
  defaultProps: {
    size: "sm",
  },
  sizes: {
    sm: {
      fontSize: "sm",
      lineHeight: "sm",
      p: "3",
      height: "unset",
    },
  },
  variants: {
    primary: {
      background: "yellow.800",
      color: "black",
      _hover: {
        background: "yellow.500",
        _disabled: {
          background: "yellow.800",
          color: "gray.900",
        },
      },
      _focus: {
        boxShadow: "none",
        background: "yellow.500",
        _disabled: {
          background: "yellow.800",
          color: "gray.900",
        },
      },
    },
    secondary: {
      bg: "gray.700",
      color: "white",
      _hover: {
        background: "gray.600",
        _disabled: {
          background: "gray.800",
        },
      },
      _focus: {
        boxShadow: "none",
        background: "gray.600",
        _disabled: {
          background: "gray.800",
        },
      },
    },
    overlineLink: {
      color: "white",
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "semibold",
      letterSpacing: "0.125rem",
      textTransform: "uppercase",
      p: 0,
    },
  },
};

const Switch = {
  sizes: {
    md: {
      container: {
        "--switch-track-height": "1.125rem",
        "--switch-track-width": "2.25rem",
      },
      track: {
        background: "gray.500",
      },
    },
  },
};

const Text = {
  sizes: {
    caption_bold: {
      fontSize: "xs",
      lineHeight: "xs",
      fontWeight: "bold",
    },
    caption_semibold: {
      fontSize: "xs",
      lineHeight: "xs",
      fontWeight: "semibold",
    },
    caption_normal: {
      fontSize: "xs",
      lineHeight: "xs",
      fontWeight: "normal",
    },
    overline: {
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "semibold",
      letterSpacing: "0.125rem",
      textTransform: "uppercase",
    },
    smallText_bold: {
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "bold",
    },
    smallText_semibold: {
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "semibold",
    },
    smallText_normal: {
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "normal",
    },
    paragraph_bold: {
      fontSize: "md",
      lineHeight: "md",
      fontWeight: "bold",
    },
    paragraph_semibold: {
      fontSize: "md",
      lineHeight: "md",
      fontWeight: "semibold",
    },
    paragraph_normal: {
      fontSize: "md",
      lineHeight: "md",
      fontWeight: "normal",
    },
    paragraph_strike: {
      fontSize: "md",
      lineHeight: "md",
      fontWeight: "normal",
      textDecoration: "line-through",
    },
    h3_normal: {
      fontSize: "xl",
      lineHeight: "xl",
      fontWeight: "normal",
    },
    h4_bold: {
      fontSize: "lg",
      lineHeight: "lg",
      fontWeight: "bold",
    },
  },
};

const Heading = {
  sizes: {
    h1_bold: {
      fontSize: "3xl",
      lineHeight: "3xl",
      fontWeight: "bold",
    },
    h2_bold: {
      fontSize: "2xl",
      lineHeight: "2xl",
      fontWeight: "bold",
    },
    h3_bold: {
      fontSize: "xl",
      lineHeight: "xl",
      fontWeight: "bold",
    },
    h3_normal: {
      fontSize: "xl",
      lineHeight: "xl",
      fontWeight: "normal",
    },
    h4_bold: {
      fontSize: "lg",
      lineHeight: "lg",
      fontWeight: "bold",
    },
    h4_semibold: {
      fontSize: "lg",
      lineHeight: "lg",
      fontWeight: "semibold",
    },
  },
};

const Menu = {
  baseStyle: {
    button: {
      background: "gray.700",
      borderRadius: "0.5rem",
      color: "white",
      py: "3",
      px: "3",
      _focus: {
        bg: "gray.600",
      },
      _hover: {
        bg: "gray.600",
      },
    },
    list: {
      bg: "gray.800",
      border: "none",
      p: "2",
      boxShadow: "4px 4px 20px 10px rgba(0, 0, 0, 0.5)",
      borderRadius: "0.5rem",
    },
    item: {
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "normal",
      borderRadius: "0.25rem",
      color: "white",
      py: "3",
      _hover: {
        bg: "gray.700",
      },
      _focus: {
        bg: "gray.700",
      },
    },
  },
};

const Input = {
  variants: {
    filled: {
      field: {
        border: "none",
        bg: "gray.700",
        color: "gray.300",
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "semibold",
        py: "3",
        height: "3rem",
        _focus: {
          boxShadow: "none",
          bg: "gray.700",
        },
        _hover: {
          bg: "gray.600",
        },
      },
    },
    flushed: {
      field: {
        bg: "black",
        color: "white",
        fontSize: "md",
        lineHeight: "md",
        fontWeight: "semibold",
        pl: "6",
        py: "4",
        height: "auto",
        borderRadius: "0.5rem",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "black",
        maxHeight: "56px",
        _focus: {
          borderColor: "black",
          boxShadow: "none",
          _invalid: {
            boxShadow: "none",
            borderColor: "red.800",
          },
        },
        _hover: {
          borderColor: "black",
          _invalid: {
            boxShadow: "none",
            borderColor: "red.800",
          },
        },
        _placeholder: {
          color: "gray.500",
        },
        _invalid: {
          boxShadow: "none",
          borderColor: "red.800",
        },
        _disabled: {
          bg: "black",
          opacity: 0.8,
        },
      },
    },
    calendar: {
      field: {
        bg: "black",
        color: "white",
        fontSize: "md",
        lineHeight: "md",
        fontWeight: "semibold",
        pl: "6",
        py: "4",
        height: "auto",
        borderRadius: "0.5rem",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "black",
        maxHeight: "48px",
        _focus: {
          borderColor: "yellow.800",
          boxShadow: "none",
          _invalid: {
            boxShadow: "none",
            borderColor: "red.800",
          },
        },
        _placeholder: {
          color: "gray.500",
        },
        _invalid: {
          boxShadow: "none",
          borderColor: "red.800",
        },
      },
    },
  },
};

const Checkbox = {
  defaultProps: {
    colorScheme: "yellow",
  },
  baseStyle: {
    label: {
      color: "white",
      ml: 3,
      fontSize: "md",
      lineHeight: "md",
      fontWeight: "normal",
    },
    control: {
      p: 1,
      minW: "24px",
      minH: "24px",
      bg: "gray.700",
      border: "none",
      borderRadius: "0.25rem",
      mb: "auto",
      _checked: {
        bg: "yellow.800",
        _focus: {
          bg: "yellow.800",
        },
        _hover: {
          bg: "yellow.800",
        },
      },
      _focus: {
        bg: "gray.600",
      },
      _hover: {
        bg: "gray.600",
      },
    },
    icon: {
      color: "black",
      minW: "16px",
      minH: "16px",
    },
  },
};

const Popover = {
  baseStyle: {
    content: {
      width: "unset",
      color: "white",
      p: 6,
      bg: "gray.800",
      borderRadius: "0.75rem",
      border: "1px solid",
      borderColor: "gray.700",
      boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.2)",
      "--popper-arrow-bg": "var(--chakra-colors-gray-800)",
      "--popper-arrow-shadow-color": "var(--chakra-colors-gray-700)",
      "--popper-arrow-size": "17px",
    },
  },
};

const FormLabel = {
  baseStyle: {
    color: "gray.400",
    fontSize: "md",
    lineHeight: "md",
    fontWeight: "normal",
    mb: 2,
  },
};

const Tabs = {
  variants: {
    prohash: {
      tablist: {
        bg: "black",
        p: 2,
        borderRadius: "0.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "12px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "gray.700",
      },
      tab: {
        width: "100%",
        textAlign: "center",
        borderRadius: "0.5rem",
        py: 3,
        fontSize: "sm",
        lineHeight: "sm",
        fontWeight: "bold",
        color: "gray.500",
        _selected: {
          bg: "gray.700",
          color: "white",
        },
        _focus: {
          boxShadow: "none",
        },
      },
      tabpanel: {
        margin: 0,
        padding: 0,
        marginTop: 6,
      },
    },
    enclosed: {
      tablist: {
        borderBottomWidth: "1px",
        borderStyle: "solid",
        borderColor: "gray.700",
        overflowX: "auto",
        overflowY: "hidden",
      },
      tabpanel: {
        p: 0,
        mt: 8,
      },
      tab: {
        whiteSpace: "nowrap",
        color: "white",
        fontSize: "sm",
        lineHeight: "sm",
        p: 3,
        fontWeight: "semibold",
        borderWidth: "0",
        borderBottomWidth: "2px",
        borderTopLeftRadius: "0.5rem",
        borderTopRightRadius: "0.5rem",
        _focus: {
          boxShadow: "none",
          color: "white",
          borderBottomColor: "yellow.800",
          bg: "gray.700",
        },
        _selected: {
          color: "white",
          borderBottomColor: "yellow.800",
          bg: "gray.700",
        },
      },
    },
  },
};

const Modal = {
  sizes: {
    xl: {
      dialog: {
        maxW: "618px",
      },
    },
  },
  defaultProps: { size: "xl" },
  baseStyle: {
    overlay: {
      backdropFilter: "blur(50px)",
      backgroundColor: "rgba(9, 9, 11, 0.4)",
    },
    dialog: {
      background: "gray.900",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "gray.700",
      color: "white",
      borderRadius: "0.75rem",
      overflow: "hidden",
      boxShadow: "4px 4px 20px 20px rgba(0, 0, 0, 0.2)",
      pt: "10",
      px: "10",
    },
    footer: {
      background: "gray.800",
      p: "10",
      mt: "10",
      mx: "-10",
    },
    header: {
      fontSize: "lg",
      lineHeight: "lg",
      fontWeight: "bold",
      p: 0,
      mb: "6",
    },
    body: {
      p: 0,
    },
    closeButton: {
      top: "6",
      right: "6",
      background: "gray.700",
      padding: "14px",
      borderRadius: "50%",
      minW: "48px",
      minH: "48px",
      w: "0",
      h: "0",
      _hover: { bg: "gray.600" },
      _active: { bg: "gray.600" },
      _disabled: {
        _hover: { bg: "gray.600" },
        _active: { bg: "gray.600" },
      },
      _focus: {
        boxShadow: "none",
        bg: "gray.600",
      },
    },
  },
  variants: {
    transparent: {
      overlay: {
        backdropFilter: "unset",
        backgroundColor: "black",
      },
      dialog: {
        background: "transparent",
        border: "none",
        color: "white",
        overflow: "unset",
        boxShadow: "none",
        maxW: "unset",
        p: 0,
        mt: 24,
      },
      closeButton: {
        position: "fixed",
        top: 4,
        right: 4,
      },
    },
    mobileFull: {
      dialog: {
        background: "black",
        border: "none",
        color: "white",
        overflow: "unset",
        boxShadow: "none",
        maxW: "unset",
        p: 0,
        m: 0,
        borderRadius: "unset",
        position: "fixed",
        inset: 0,
      },
    },
  },
};

const Accordion = {
  baseStyle: {
    button: {
      _focus: { boxShadow: "none" },
      p: 0,
      m: 0,
    },
    container: {
      bg: "black",
      p: 6,
      border: "none",
      borderRadius: "0.5rem",
    },
    panel: {
      color: "gray.500",
      fontSize: "md",
      lineHeight: "md",
      fontWeight: "normal",
      px: 0,
      pb: 0,
      mt: 4,
      pt: 4,
      borderTopWidth: "1px",
      borderStyle: "solid",
      borderColor: "gray.700",
    },
  },
};

const Select = {
  variants: {
    flushed: {
      field: {
        bg: "black",
        color: "white",
        fontSize: "md",
        lineHeight: "md",
        fontWeight: "semibold",
        pl: "5",
        py: "4",
        height: "auto",
        borderRadius: "0.5rem",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "black",
        maxHeight: "56px",
        _focus: {
          borderColor: "black",
          boxShadow: "none",
          _invalid: {
            boxShadow: "none",
            borderColor: "red.800",
          },
        },
        _hover: {
          borderColor: "black",
          _invalid: {
            boxShadow: "none",
            borderColor: "red.800",
          },
        },
        _placeholder: {
          color: "gray.500",
        },
        _invalid: {
          boxShadow: "none",
          borderColor: "red.800",
        },
        _disabled: {
          bg: "black",
          opacity: 0.8,
        },
      },
      icon: {
        color: "white",
        mr: 3,
      },
    },
  },
};

const PinInput = {
  baseStyle: {
    minH: "56px",
  },
  sizes: {
    md: {
      fontSize: "lg",
      lineHeight: "lg",
      fontWeight: "bold",
      color: "white",
    },
  },
  variants: {
    outline: {
      borderColor: "gray.700",
      borderRadius: "0.5rem",
      bg: "black",
      _hover: {
        borderColor: "gray.700",
      },
      _focus: {
        boxShadow: "none",
        borderColor: "yellow.800",
      },
    },
  },
};

const Drawer = {
  baseStyle: {
    dialogContainer: {
      position: "fixed",
      inset: "0",
    },
    dialog: {
      w: "100%",
      h: "100%",
      bg: "black",
    },
  },
};

const Link  = {
  baseStyle: {
    fontWeight: "semibold",
    borderRadius: "8px",
    _disabled: {
      opacity: "0.5",
    },
  },
  defaultProps: {
    size: "sm",
  },
  sizes: {
    sm: {
      fontSize: "sm",
      lineHeight: "sm",
      p: "3",
      height: "unset",
    },
  },
  variants: {
    primary: {
      background: "yellow.800",
      color: "black",
      _hover: {
        background: "yellow.500",
        _disabled: {
          background: "yellow.800",
          color: "gray.900",
        },
      },
      _focus: {
        boxShadow: "none",
        background: "yellow.500",
        _disabled: {
          background: "yellow.800",
          color: "gray.900",
        },
      },
    },
    secondary: {
      bg: "gray.700",
      color: "white",
      _hover: {
        background: "gray.600",
        _disabled: {
          background: "gray.800",
        },
      },
      _focus: {
        boxShadow: "none",
        background: "gray.600",
        _disabled: {
          background: "gray.800",
        },
      },
    },
    overlineLink: {
      color: "white",
      fontSize: "sm",
      lineHeight: "sm",
      fontWeight: "semibold",
      letterSpacing: "0.125rem",
      textTransform: "uppercase",
      p: 0,
    },
  },
};


const components = {
  Switch,
  Button,
  Text,
  Heading,
  Menu,
  Input,
  Checkbox,
  Popover,
  FormLabel,
  Tabs,
  Modal,
  Accordion,
  Select,
  PinInput,
  Drawer,
  Link,
};

const theme = extendTheme({
  styles: { global },
  colors,
  fonts,
  fontSizes,
  lineHeights,
  breakPoints,
  components,
});

// console.log(theme.components.Select);
export default theme;
