import React from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { observer } from "mobx-react-lite";
import {
    AccountOperationCategory,
    getTransactionAmount,
    getTransactionFee,
    getTransactionRate,
    getTransactionStatusLabel,
    getTransactionTypeLabel,
    Transaction,
    TransactionType,
} from "../../../app/models/transaction";
import { format } from "date-fns";
import { geDisplayValue } from "../../../app/models/rate";

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

const BACKGROUND_COLOR = "#bdbdbd";
const BORDER_COLOR = "#000";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 18;
const COLN_WIDTH = (100 - 1 * COL1_WIDTH) / 6;
const styles = StyleSheet.create({
    body: {
        padding: 10,
    },
    table: {
        display: "flex",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: 10,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol1Header: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: BACKGROUND_COLOR,
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: BACKGROUND_COLOR,
    },
    tableCol1: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 2,
        fontSize: 10,
        fontWeight: "bold",
    },
    tableCell: {
        margin: 2,
        fontSize: 8,
    },
    titleContainer: {
        flexDirection: "row",
        marginTop: 24,
        // borderTopWidth: 1,
    },
    reportTitle: {
        color: "black",
        // letterSpacing: 4,
        fontSize: 15,
        // textAlign: "center",
        fontWeight: "bold",
        // textTransform: "uppercase",
    },
    logo: {
        width: 174,
        height: 66,
        // marginLeft: 'auto',
        // marginRight: 'auto'
    },
    filter: {
        marginTop: 10,
        paddingBottom: 3,
        fontSize: 10,
        fontFamily: "Helvetica-Oblique",
    },
});

/*
const pageStyle = {
  paddingTop: 16,
  paddingHorizontal: 40,
  paddingBottom: 56
};

const tableStyle = {
  display: "table",
  width: "auto"
};

const tableRowStyle = {
  flexDirection: "row"
};

const firstTableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#bdbdbd"
};

const tableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const firstTableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0
};

const tableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0
};

const tableCellHeaderStyle = {
  textAlign: "center",
  margin: 4,
  fontSize: 12,
  fontWeight: "bold"
};

const tableCellStyle = {
  textAlign: "center",
  margin: 5,
  fontSize: 10
};
*/

interface Props {
    data: Map<string, Transaction>;
    title: string;
    filter: string;
    recordNo?: string;
}

export default observer(function PdfStatement({ data, title, filter, recordNo }: Props) {
    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <Image style={styles.logo} src="/assets/logo_small.png" />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>{title}</Text>
                </View>
                <View>
                    <Text style={styles.filter}>{filter && "Filter: " + filter}</Text>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Date</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Type</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Status</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Positive Amount</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Negative Amount</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Fee</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Rate</Text>
                        </View>
                    </View>
                    {data
                        ? Array.from(data.values()).map((transaction) => (
                              <View style={styles.tableRow} key={transaction.id} wrap={false}>
                                  <View style={styles.tableCol1} wrap={false}>
                                      <Text style={styles.tableCell}> {format(transaction.date, "dd MMM yyyy h:mm aa")}</Text>
                                  </View>
                                  <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>{getTransactionTypeLabel(transaction.type)}</Text>
                                  </View>
                                  <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>{getTransactionStatusLabel(transaction.status)}</Text>
                                  </View>
                                  <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>{getTransactionAmount(transaction, true)}</Text>
                                  </View>
                                  <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>{getTransactionAmount(transaction, false)}</Text>
                                  </View>
                                  <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>{getTransactionFee(transaction)}</Text>
                                  </View>
                                  <View style={styles.tableCol}>
                                      <Text style={styles.tableCell}>{getTransactionRate(transaction)}</Text>
                                  </View>
                              </View>
                          ))
                        : ""}
                </View>
                <View>
                    <Text style={styles.filter}>{recordNo && "Records: " + recordNo}</Text>
                </View>
            </Page>
        </Document>
    );
});
