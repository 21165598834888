import { Document } from "./document";
import { Currency } from "./rate";

export interface GenericAccount{
    id: string;
    iban: string;
    currency: Currency;
    description: string;
    balance: number;
    date: Date; 
}

export interface FIATAccount extends GenericAccount {
    // id: string;
    // iban: string;
    bank: string;
    swift: string;
    // balance: number;
    // date: Date;
    ownerName: string;
    code: string;
    validated: boolean;
    document: Document;

}

export class FIATAccount implements FIATAccount {
    constructor(init?: FIATAccountFormValues) {
        Object.assign(this, init);
      }
}

export class FIATAccountFormValues {
    iban: string = '';
    currency: string = '';
    documentId: string = '';
    description: string = '';
    swift: string = '';
    bank: string = '';
}