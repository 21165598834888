import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../stores/store';

export default observer(function ModalContainer() {
    const {modalStore} = useStore();

    return (
        <Modal isOpen={modalStore.modal.open} onClose={modalStore.closeModal} size='6xl' isCentered>
            <ModalOverlay />
            <ModalContent bg="black">
                {modalStore.modal.body}
            </ModalContent>
        </Modal>
    )
})