import { Flex, Text, Button } from "@chakra-ui/react";
import Notification from "../../../app/components/notification";
import Balance from "../../../app/components/balance";
import PercentageButton from "../../../app/components/percentageButton";
import WhitelistedAddressList from "./WhitelistedAddressList";
import { MessageType } from "app/models/message";
import { observer } from "mobx-react-lite";
import { isFiat } from "app/models/rate";
import { useStore } from "app/stores/store";
import { useState } from "react";
import { WhitelistedAddress } from "app/models/cryptoAccount";
import { FundingRequestFormValues } from "app/models/fundingRequest";
import { history } from "../../..";
import { useTranslation } from "react-i18next";

export default observer(function WithdrawDetails() {
    const { t } = useTranslation();
    const {
        userStore: { user },
        profileStore: { getAssets, getWhitelistAdddressList, loadProfile, createCryptoAccount },
        fundingRequestStore: { loading, setShowSummary, setFundingRequestFormValues, asset, setAsset },
    } = useStore();
    const [error, setError] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState(0);
    // const [asset, setAsset] = useState(new Asset({currency: Currency.EUR, balance: 0}));
    const [amount, setAmount] = useState(0);
    const [selectedAddress, setSelectedAddress] = useState<WhitelistedAddress>();

    function handleSelectAmount(part: number) {
        var decimals = isFiat(asset.currency) ? 2 : 8;
        var amount = !!asset?.balance ? parseFloat((asset?.balance * part).toFixed(decimals)) : 0;
        setAllAmounts(amount);
        setError(false);
    }
    function setAllAmounts(amount: number) {
        setSelectedAmount(amount);
        setAmount(amount);
    }

    // if (loading) return <LoadingComponent />;

    return (
        <>
            <Flex mb="6">
                <PercentageButton variant="secondary" onClick={() => handleSelectAmount(1)}>
                  {t("all")}
                </PercentageButton>
                <PercentageButton variant="secondary" ml="3" onClick={() => handleSelectAmount(0.75)}>
                    75%
                </PercentageButton>
                <PercentageButton variant="secondary" ml="3" onClick={() => handleSelectAmount(0.5)}>
                    50%
                </PercentageButton>
                <PercentageButton variant="secondary" ml="3" onClick={() => handleSelectAmount(0.25)}>
                    25%
                </PercentageButton>
            </Flex>
            <Text size="paragraph_normal" color="gray.400" mb="2">
                {t("asset")}
            </Text>
            <Balance
                variant="none"
                // mb={hasWithdraw ? "4" : "2"}
                mb="10"
                hasError={error}
                isDebit={true}
                setError={setError}
                setAmount={setAllAmounts}
                hasInput={true}
                selectedAmount={selectedAmount}
                assets={getAssets()}
                setAsset={setAsset}
                asset={asset}
            />
            {asset.accountId ? (
                <>
                    {!isFiat(asset.currency) ? (
                        <>
                            {getWhitelistAdddressList(asset.currency)!.length > 0 ? (
                                <>
                                    <Flex align="center" justify="space-between" mb="2">
                                        <Text size="paragraph_normal" color="gray.400">
                                            Whitelisted Address
                                        </Text>
                                        {/* <Button variant="overlineLink">Add address</Button> */}
                                    </Flex>
                                    <WhitelistedAddressList
                                        currency={asset.currency}
                                        whiteAddresses={getWhitelistAdddressList(asset.currency)}
                                        setAddress={setSelectedAddress}
                                        mb="10"
                                    />
                                    <Button
                                        variant="primary"
                                        w="100%"
                                        onClick={() => {
                                            setFundingRequestFormValues(
                                                new FundingRequestFormValues({
                                                    accountId: asset.accountId || "",
                                                    amount: amount,
                                                    isDebit: true,
                                                    currency: asset.currency,
                                                    externalId: selectedAddress?.value,
                                                })
                                            );
                                            setShowSummary(true);
                                        }}
                                        isDisabled={error || amount === 0 || !selectedAddress}
                                    >
                                        {t("continue.bttn")}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Notification
                                        messageStyle={{ fontWeight: "normal" }}
                                        message="This account has no whitelisted address. Please add one using the settings section."
                                        type={MessageType.Error}
                                        bg="gray.700"
                                        mb="10"
                                    ></Notification>
                                    <Button variant="primary" w="100%" onClick={() => history.push("/profiles/settings/4")}>
                                        Add an Address
                                    </Button>
                                </>
                            )}
                        </>
                    ) : (
                        <Button
                            variant="primary"
                            w="100%"
                            onClick={() => {
                                setFundingRequestFormValues(
                                    new FundingRequestFormValues({
                                        accountId: asset.accountId || "",
                                        amount: amount,
                                        isDebit: true,
                                        currency: asset.currency,
                                        externalId: asset.iban,
                                    })
                                );
                                setShowSummary(true);
                            }}
                            isDisabled={error || amount === 0}
                        >
                            {t("continue.bttn")}
                        </Button>
                    )}
                </>
            ) : (
                <>
                    {!isFiat(asset.currency) ? (
                        <>
                            <Notification
                                messageStyle={{ fontWeight: "normal" }}
                                message={t("invalid.acc.message.Withdraw")}
                                type={MessageType.Error}
                                bg="gray.700"
                                mb="10"
                            />
                            <Button
                                variant="primary"
                                w="100%"
                                onClick={() => createCryptoAccount(asset.currency).then(() => loadProfile(user?.username!))}
                                isLoading={loading}
                            >
                                {t("enable.bttn")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Notification
                                messageStyle={{ fontWeight: "normal" }}
                                message="This account is currently inactive. Please add an bank account using the settings section and wait for validation."
                                type={MessageType.Error}
                                bg="gray.700"
                                mb="10"
                            />
                            <Button variant="primary" w="100%" onClick={() => history.push("/profiles/settings/3")}>
                                Add a Bank Account
                            </Button>
                        </>
                    )}
                </>
            )}
            {/* {hasWithdraw ? (
                <>
                    <Flex align="center" justify="space-between" mb="2">
                        <Text size="paragraph_normal" color="gray.400">
                            Whitelisted Address
                        </Text>
                        <Button variant="overlineLink">Add address</Button>
                    </Flex>
                    <PopoverMenu triggerContent={<AssetOption mb="3" />}>
                        <AccountsList />
                    </PopoverMenu>
                </>
            ) : null}
            <Notification
                messageStyle={{ fontWeight: "normal" }}
                message={
                    hasWithdraw
                        ? "The request will take up to 2 days to be completed"
                        : "There is no destination added in your accounts. Add one now."
                }
                type={MessageType.Info}
                bg="gray.700"
                mb="10"
            />
            <Button
                variant="primary"
                w="100%"
                onClick={() => {
                    if (hasWithdraw) {
                        // setHasWithdraw(false);
                    } else {
                        onOpen();
                    }
                }}
            >
                {hasWithdraw ? "Continue" : "Add bank account"}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalBody>
                        <TextInput name="bank" type="text" label="Bank Name" placeholder="Type Bank Name..." mb="4" />
                        <TextInput name="IBAN" type="text" label="IBAN" placeholder="Type Iban..." mb="4" />
                        <TextInput name="swift" type="text" label="BIC" placeholder="Type SWIFT/BIC..." mb="4" />
                        <Uploader bg="gray.800" />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="primary"
                            w="100%"
                            onClick={() => {
                                // setHasWithdraw(true);
                                onClose();
                            }}
                        >
                            Continue
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal> */}
        </>
    );
});
