import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { MessageType } from "app/models/message";
import { showToast } from "app/components/toast/Toast";
import * as Yup from "yup";
import agent from "../../app/api/agent";
import MyTextInput from "app/common/form/MyTextInput";
import useQuery from "../../app/common/util/hooks";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Button } from "@chakra-ui/react";
import { Regx } from "app/common/util/regx";
import { useStore } from "app/stores/store";
import { useTranslation } from "react-i18next";

export default observer(function ResetPwdForm() {
    const { t } = useTranslation();
    const email = useQuery().get("email") as string;
    const token = useQuery().get("token") as string;
    const {
        commonStore: { notification, setNotification },
    } = useStore();

    // const [notification, setNotification] = React.useState({
    //     message:
    //         "For safety purposes, no withdrawals can be made for 24h after your password was changed.",
    //     icon: <Info />,
    // });

    useEffect(() => {
        setNotification({
            message: t("notice.reset.passrd"),
            type: MessageType.Info,
        });
    }, [setNotification]);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required(t("passwrd.criteria.p3"))
            .matches(Regx.Pwd8, t("passwrd.criteria.p1")),
        confirmPassword: Yup.string()
            .required("passwrd.criteria.p3")
            .oneOf([Yup.ref("password")], "Passwords must match"),
    });

    return (
        <Formik
            initialValues={{
                password: "",
                confirmPassword: "",
                error: null,
            }}
            onSubmit={(values, { setErrors }) =>
                agent.Account.resetPassword({ email: email, token: token, newPassword: values.password })
                    .then(() => {
                        showToast(t("passwes.reseted"), MessageType.Success);
                        setNotification({
                            message: t("passwes.reseted"),
                            type: MessageType.Info,
                        });
                        // history.push("/");
                    })
                    .catch(() => {
                        setNotification({
                            message: "Password reset failed",
                            type: MessageType.Error,
                        });
                        // setErrors({ error: "Password reset failed" });
                    })
            }
            validationSchema={validationSchema}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <AuthenticationTemplate notification={notification} headingText="Reset your password">
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name="password" placeholder={t("profile.psswrd.p2")} type="password" isRequired />
                        <MyTextInput name="confirmPassword" placeholder={t("profile.psswrd.p3")} type="password" isRequired mb="10" />
                        <Button
                            variant="primary"
                            mb="6"
                            disabled={!isValid || !dirty || isSubmitting}
                            isLoading={isSubmitting}
                            type="submit"
                            isFullWidth
                        >
                           {t("reset.passwrd.bttn")}
                        </Button>
                    </Form>
                </AuthenticationTemplate>
            )}
        </Formik>
    );
});
