import * as React from "react";
import Grid from "app/components/grid";
import { useBreakpointValue, Heading, Grid as CGrid, Text, Box, Flex, Image, Button, Divider } from "@chakra-ui/react";
import { history } from "../..";
import { useTranslation } from "react-i18next";

export default function Rates() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();
    return (
        <Grid color="white" position="relative" zIndex="3" bg="gray.900" pt={{ base: "6", md: "10", lg: "20" }}>
            <Heading size={headerSize} gridColumn="1/-1" textAlign="center" mb={{ base: "6", sm: "10" }}>
                {t("landing.rates.p1")}
            </Heading>
            <CGrid
                gridColumn={{ base: "1/-1", md: "2 / span 10" }}
                templateColumns={{ base: "repeat(4 , 1fr)", md: "repeat(5,1fr)" }}
                mb="4"
                d={{ base: "none", sm: "grid" }}
            >
                <Text ml="4" size="smallText_normal" color="gray.300">
                    {t("landing.rates.p2")}
                </Text>
                <Box d={{ base: "none", md: "flex" }} />
                <Text size="smallText_normal" color="gray.300" textAlign="center">
                    {t("landing.rates.p3")}
                </Text>
                <Text size="smallText_normal" color="gray.300" textAlign="center" mr="3">
                    {t("landing.rates.p4")}
                </Text>
                <Box />
            </CGrid>
            {["coins/btc.svg", "coins/eth.svg", "coins/ltc.svg"].map((src, i) => (
                <CGrid
                    gridColumn={{ base: "1/-1", md: "2 / span 10" }}
                    key={src}
                    templateColumns={{
                        base: "1fr",
                        sm: "repeat(3, 1fr) auto",
                        md: "repeat(5, 1fr)",
                    }}
                    mb={i === 2 ? 0 : 3}
                    mr={{ base: i === 2 ? 0 : 2, sm: 0 }}
                    p="3"
                    bg="black"
                    borderRadius="0.5rem"
                    color="white"
                    alignItems="center"
                >
                    <Flex>
                        <Flex ml={{ base: "0", sm: "3" }} mb={{ base: "4", sm: "0" }} align="center">
                            <Image w="40px" h="40px" src={src} />
                            <Flex direction="column" ml="4">
                                <Text size="paragaph_semibold">Bitcoin</Text>
                                <Text size="smallText_normal" color="gray.500">
                                    BTC
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Box d={{ base: "none", md: "flex" }} />
                    <Text
                        textAlign="center"
                        size="paragaph_normal"
                        d="flex"
                        justifyContent={{ base: "flex-start", md: "center" }}
                        mb={{ base: "3", md: "0" }}
                    >
                        <Text as="span" color="gray.300" size="smallText_normal" d={{ base: "flex", sm: "none" }} mr="2">
                            Price (Buy)
                        </Text>
                        207,811.32
                    </Text>
                    <Text textAlign="center" size="paragaph_normal" d="flex" justifyContent={{ base: "flex-start", md: "center" }}>
                        <Text as="span" color="gray.300" size="smallText_normal" d={{ base: "flex", sm: "none" }} mr="2">
                            Price (Sell)
                        </Text>
                        207,804.29
                    </Text>
                    <Button
                        variant="primary"
                        w="min-content"
                        ml="auto"
                        d={{ base: "none", sm: "flex" }}
                        onClick={() => history.push("/security/register")}
                    >
                        {t("landing.rates.p5")}
                    </Button>
                </CGrid>
            ))}
            <Divider
                mt={{ base: "6", sm: "10", md: "20" }}
                mb={{ base: "10", md: "20" }}
                borderColor="gray.700"
                gridColumn={{ base: "1/-1", md: "2 / span 10" }}
            />
        </Grid>
    );
}
