import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const EyeClosed = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M19.333 8.791a17.46 17.46 0 0 0-3.583-3l3.333-3.33a.834.834 0 1 0-1.178-1.178l-.015.015a.615.615 0 0 1-.144.243l-16.25 16.25c-.007.006-.015.008-.022.014a.833.833 0 0 0 1.36.907l3.454-3.455a9.383 9.383 0 0 0 3.592.782h.25c3.546 0 7.074-2.486 9.209-4.833a1.81 1.81 0 0 0-.006-2.415zm-5.739 2.268a3.818 3.818 0 0 1-2.5 2.523c-.85.27-1.772.214-2.583-.159a.212.212 0 0 1-.057-.339l4.633-4.632a.21.21 0 0 1 .34.056c.367.8.427 1.709.167 2.55zM6.346 11.167a.203.203 0 0 0 .05-.2A3.596 3.596 0 0 1 6.25 10 3.75 3.75 0 0 1 10 6.25c.327.003.652.052.966.144a.203.203 0 0 0 .2-.052l1.618-1.618a.21.21 0 0 0-.083-.35A8.677 8.677 0 0 0 10 3.957C6.416 3.91 2.833 6.417.677 8.791a1.792 1.792 0 0 0-.006 2.416c.861.943 1.822 1.79 2.865 2.525a.213.213 0 0 0 .272-.024l2.538-2.541z"
      fill="currentColor"
    />
  </Icon>
);

export const EyeOpened = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M19.5264 8.76992C17.3223 6.34492 13.6664 3.78158 9.99975 3.83325C6.33309 3.78075 2.67725 6.34575 0.473088 8.76992C0.170946 9.10772 0.00390625 9.54504 0.00390625 9.99825C0.00390625 10.4515 0.170946 10.8888 0.473088 11.2266C2.65142 13.6258 6.25559 16.1666 9.86559 16.1666H10.1223C13.7448 16.1666 17.3481 13.6258 19.5289 11.2258C19.8307 10.8878 19.9972 10.4504 19.9968 9.99736C19.9963 9.54428 19.8288 9.10728 19.5264 8.76992ZM6.16642 9.99992C6.16642 9.24176 6.39124 8.50062 6.81245 7.87023C7.23367 7.23984 7.83235 6.74852 8.5328 6.45838C9.23325 6.16824 10.004 6.09233 10.7476 6.24024C11.4912 6.38815 12.1742 6.75324 12.7103 7.28934C13.2464 7.82544 13.6115 8.50848 13.7594 9.25207C13.9073 9.99567 13.8314 10.7664 13.5413 11.4669C13.2512 12.1673 12.7598 12.766 12.1294 13.1872C11.4991 13.6084 10.7579 13.8333 9.99975 13.8333C8.98309 13.8333 8.00807 13.4294 7.28918 12.7105C6.57029 11.9916 6.16642 11.0166 6.16642 9.99992Z"
      fill="currentColor"
    />
    <path
      d="M9.99975 11.6666C10.9202 11.6666 11.6664 10.9204 11.6664 9.99992C11.6664 9.07944 10.9202 8.33325 9.99975 8.33325C9.07928 8.33325 8.33309 9.07944 8.33309 9.99992C8.33309 10.9204 9.07928 11.6666 9.99975 11.6666Z"
      fill="currentColor"
    />
  </Icon>
);

export const SolidX = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M10 19.583A9.584 9.584 0 1 0 .417 9.999 9.594 9.594 0 0 0 10 19.583zM5.875 7.053a.833.833 0 1 1 1.178-1.178l2.8 2.8a.208.208 0 0 0 .293 0l2.8-2.8a.832.832 0 0 1 1.365.908.834.834 0 0 1-.187.27l-2.8 2.8a.208.208 0 0 0 0 .294l2.8 2.799a.834.834 0 1 1-1.178 1.178l-2.8-2.799a.208.208 0 0 0-.294 0l-2.799 2.8a.833.833 0 0 1-1.178-1.179l2.799-2.8a.209.209 0 0 0 0-.293l-2.8-2.8z"
      fill="#D1D2D6"
    />
  </Icon>
);

export const Alert = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M19.166 8.822 11.178.833a1.667 1.667 0 0 0-2.356 0L.833 8.823a1.667 1.667 0 0 0 0 2.356l7.989 7.988a1.667 1.667 0 0 0 2.356 0l7.988-7.988a1.667 1.667 0 0 0 0-2.357zM9.223 5.348a.833.833 0 0 1 1.667 0v5a.833.833 0 0 1-1.667 0v-5zm.876 9.591h-.023a1.274 1.274 0 0 1-1.269-1.225 1.23 1.23 0 0 1 1.207-1.275h.023a1.272 1.272 0 0 1 1.269 1.225 1.23 1.23 0 0 1-1.207 1.275z"
      fill="#FC4545"
    />
  </Icon>
);

export const Check = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M10 0a10 10 0 1 0 10 10A10.012 10.012 0 0 0 10 0zm5.773 6.833-5.705 7.741a.842.842 0 0 1-1.191.157l-4.074-3.257a.834.834 0 0 1 1.042-1.302l3.397 2.718 5.189-7.043a.834.834 0 1 1 1.342.986z"
        fill="#04B07D"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export const Unchecked = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M10 19.583A9.583 9.583 0 1 0 .417 10 9.594 9.594 0 0 0 10 19.583zM5.875 7.054a.833.833 0 1 1 1.178-1.178l2.8 2.8a.208.208 0 0 0 .293 0l2.8-2.8a.832.832 0 1 1 1.178 1.178l-2.8 2.8a.208.208 0 0 0 0 .294l2.8 2.799a.833.833 0 1 1-1.178 1.178l-2.8-2.8a.208.208 0 0 0-.294 0l-2.799 2.8a.833.833 0 0 1-1.178-1.178l2.799-2.8a.209.209 0 0 0 0-.294l-2.8-2.799z"
      fill="#FC4545"
    />
  </Icon>
);

export const Info = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0zm.208 4.167a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5zm1.875 11.25H8.75a.833.833 0 1 1 0-1.667h.625a.209.209 0 0 0 .208-.208v-3.75a.208.208 0 0 0-.208-.209H8.75a.833.833 0 0 1 0-1.666h.833a1.666 1.666 0 0 1 1.667 1.666v3.959a.209.209 0 0 0 .208.208h.625a.833.833 0 1 1 0 1.667z"
        fill="#FEAF17"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Icon>
);
