import * as React from "react";
import { history } from "../..";
import { Button, Text, Image, Flex, Grid as CGrid, Box, Heading } from "@chakra-ui/react";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import OnboardingSteps from "./OnboardingSteps";
import CheckImage from "assets/images/onboarding/check.webp";
import MessageImage from "assets/images/onboarding/message.webp";
import ListImage from "assets/images/onboarding/list.webp";
import Grid from "app/components/grid";
import { ArrowBack, ArrowForward } from "assets/icons/onboarding";
import IconButton from "app/components/iconButton";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { useTranslation } from "react-i18next";

export default observer(function One() {
    

    const {
        profileStore: { onboardingVariant },
        userStore,
    } = useStore();
    const steps = 3;
    const [currentStep, setCurrentStep] = React.useState(1);
    const {
        profileStore,
        userStore: { user },
    } = useStore();
    const { t } = useTranslation();
    const sections = [
        null,
        <>
            <Image mx="auto" w="180px" h="160px" mb="6" src={CheckImage} />
            <Text mb="10" size="paragraph_regular" color="white" textAlign="center">
                {t("onboarding.mess1")}
            </Text>
            <Button
                variant="primary"
                mb="4"
                onClick={() => {
                    history.push("/onboarding/two");
                }}
            >
                {t("onboarding.bttn1")}
            </Button>
            <Button
                key="secondary"
                variant="overlineLink"
                onClick={() => {
                    runInAction(() => {
                        userStore.user!.onboardingDone = true;
                    });
                    history.push("/home");
                }}
            >
                {t("onboarding.bttn2")}
            </Button>
        </>,
        <>
            <Image mx="auto" w="160px" h="160px" mb="6" src={MessageImage} />
            <Heading mb="2" size="h4_bold" color="white" textAlign="center">
                {t("onboarding.mess2")}
            </Heading>
            <Text mb="10" size="paragraph_regular" color="white" textAlign="center">
                {t("onboarding.mess3")}
            </Text>
            <Button
                variant="primary"
                mb="4"
                onClick={() => {
                    history.push("/onboarding/two");
                }}
            >
                {t("onboarding.bttn1")}
            </Button>
            <Button
                variant="overlineLink"
                onClick={() => {
                    runInAction(() => {
                        userStore.user!.onboardingDone = true;
                    });
                    history.push("/home");
                }}
            >
                {t("onboarding.bttn2")}
            </Button>
        </>,
        <>
            <Image mx="auto" w="160px" h="160px" mb="6" src={ListImage} />
            <Heading mb="2" size="h4_bold" color="white" textAlign="center">
                {t("onboarding.mess4")}
            </Heading>
            <Text mb="10" size="paragraph_regular" color="white" textAlign="center">
                {t("onboarding.mess5")}
            </Text>
            <Button
                variant="primary"
                mb="4"
                onClick={() => {
                    profileStore.onboardingVariant = "start";
                    history.push("/onboarding/two");
                }}
            >
                {t("onboarding.bttn1")}
            </Button>
            <Button
                variant="overlineLink"
                onClick={() => {
                    runInAction(() => {
                        userStore.user!.onboardingDone = true;
                    });
                    history.push("/home");
                }}
            >
               {t("onboarding.bttn2")}
            </Button>
        </>,
    ];
    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);

    return (
        <>
            <OnboardingSteps steps={5} currentStep={1} />
            <AuthenticationTemplate headingText={t("welcome.PH")} mb="3">
                {sections[currentStep]}
            </AuthenticationTemplate>
            <Grid mb="10">
                <Flex
                    justify="space-between"
                    align="center"
                    gridColumn={{ base: "1/-1", lg: "3 / span 8", xl: "4 / span 6" }}
                    px="4"
                    py="3"
                    bg="gray.800"
                    borderRadius="0.75rem"
                    borderStyle="solid"
                    borderWidth="1px"
                    borderColor="gray.700"
                >
                    <IconButton
                        aria-label="carousel back"
                        icon={<ArrowBack w="20px" h="20px" />}
                        disabled={currentStep <= 1}
                        onClick={() => setCurrentStep((v) => v - 1)}
                    />
                    <CGrid px="3" py="2" bg="gray.700" gap="4" templateColumns={`repeat(${steps}, 1fr)`} borderRadius="full">
                        {[...new Array(steps)].map((_, i) => (
                            <Box key={i} minW="8px" minH="8px" borderRadius="50%" bg={i + 1 === currentStep ? "yellow.800" : "gray.600"} />
                        ))}
                    </CGrid>
                    <IconButton
                        aria-label="carousel back"
                        icon={<ArrowForward w="20px" h="20px" />}
                        disabled={currentStep >= steps}
                        onClick={() => setCurrentStep((v) => v + 1)}
                    />
                </Flex>
            </Grid>
        </>
    );
});
