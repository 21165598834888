import { Flex, Box, Text } from "@chakra-ui/react";
import { CurrencyColor } from "app/models/rate";

interface Props {
    color: CurrencyColor;
    text: string;
    percentage: number;
}

export default function Tag({ color, text, percentage }: Props) {
    return (
        <Flex
            p="2"
            pos="relative"
            alignSelf="stretch"
            _after={{
                pos: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                bg: "white",
                content: "''",
                zIndex: 0,
                opacity: 0.3,
                borderRadius: "0.25rem",
            }}
        >
            <Box bg={`${color}.500`} w="4px" h="100%" alignSelf="stretch" borderRadius="100px" zIndex="1" />
            <Text size="caption_normal" ml="2" zIndex="1">
                {text}
            </Text>
            <Text size="caption_normal" mx="1" zIndex="1">
                •
            </Text>
            <Text size="caption_bold" zIndex="1">
                {percentage}%
            </Text>
        </Flex>
    );
}
