import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import OnboardingNavigation from "app/components/onboardingNavigation";
import UnauthenticatedNavigation from "app/components/unauthenticatedNavigation";
import Navigation from "app/components/navigation";
import Notification from "app/components/notification";
import { MessageType } from "app/models/message";
import React from "react";
import { useTranslation } from "react-i18next";


export default observer(function NavigationBar() {
    const {
        userStore: { user, isLoggedIn },
    } = useStore();
    const [showNotification, setShowNotification] = React.useState(process.env.REACT_APP_SHOW_NEWS === "true");
    // const [showNotification, setShowNotification] = React.useState(true);
    const { t } = useTranslation();

    return (
        <>
            {isLoggedIn ? user?.onboardingDone ? <Navigation /> : <OnboardingNavigation /> : <UnauthenticatedNavigation />}
            {showNotification && (
            <Notification
                // gridColumn="1/-1"
                type={MessageType.Info}
                message={t("news")}
                bg="yellow.800"
                color="black"
                mx="3"
                my="3"
                messageStyle={{ fontWeight: "semibold" }}
                handleClose={() => setShowNotification(false)}
            />)}
        </>
    );
});
