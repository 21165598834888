import * as React from "react";
import Grid from "app/components/grid";
import { Logo } from "assets/icons/navigation";
import { history } from "../../..";
// import { useNavigate } from "react-router-dom";
import { Grid as CGrid, Button, HStack, BoxProps, Text, Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import LanguageNav from "../navigation/LanguageNav";
import { useTranslation } from "react-i18next";

export default observer( function UnauthenticatedNavigation() {
    // const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Grid
            as="nav"
            minH="4.75rem"
            bg="gray.800"
            pos="relative"
            
            _after={{
                pos: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "2px",
                bg: "gray.700",
                content: "''",
                zIndex: "0",
            }}
        >
            <CGrid gridColumn="1/-1" alignItems="center" templateColumns="auto 1fr" gap="4">
                <Logo w="123px" h="20px" color="gray.800" />
                <HStack spacing="3" justifySelf="end">
                    <Button variant="primary" onClick={() => history.push("/security/login")}>
                        {t("log.in.bttn")}
                    </Button>
                    <Button variant="secondary" d={{ base: "none", lg: "flex" }} onClick={() => history.push("/security/register")}>
                        {t("sign.up.bttn")}
                    </Button>
                    <LanguageNav />
                </HStack>
            </CGrid>
        </Grid>
    );
})
