import * as React from "react";
import Grid from "../grid";
import { Logo } from "assets/icons/navigation";
import { Grid as CGrid, HStack, BoxProps, useBreakpointValue, Button } from "@chakra-ui/react";
import { history } from "../../..";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import LanguageNav from "../navigation/LanguageNav";
import { useTranslation } from "react-i18next";

export default observer(function OnboardingNavigation() {
    
    const {
        profileStore: { onboardingVariant },
        userStore,
    } = useStore();
    const { t } = useTranslation();

    return (
        <Grid
            as="nav"
            minH="4.75rem"
            bg="gray.800"
            pos="relative"
            _after={{
                pos: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "2px",
                bg: "gray.700",
                content: "''",
                zIndex: "0",
            }}
        >
            <CGrid gridColumn="1/-1" alignItems="center" templateColumns="auto 1fr" gap="4">
                <Logo w="123px" h="20px" color="gray.800" display="flex" />
                <HStack spacing="3" justifySelf="end">
                    {onboardingVariant != "end" && (
                        <>
                            {/* {onboardingVariant == "mid" && (
                                <Button variant="overlineLink" mr="6">
                                    Submit & Exit
                                </Button>
                            )} */}
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    runInAction(() => {
                                        userStore.user!.onboardingDone = true;
                                    });
                                    history.push("/home");
                                }}
                            >
                                {t("skip.bttn")}
                            </Button>
                        </>
                    )}
                    <LanguageNav />
                </HStack>
            </CGrid>
        </Grid>
    );
});
