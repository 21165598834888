import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { MessageType } from "app/models/message";
import { Button, Checkbox, Text } from "@chakra-ui/react";
import { FIATAccountFormValues } from "app/models/fiatAccount";
import { useState } from "react";
import MySelectInput from "app/common/form/MySelectInput";
import PhotoWidgetDropzone from "app/common/imageUpload/PhotoWidgetDropzone";
import { getFiatCurrencyOptions } from "app/models/rate";
import { showToast } from "app/components/toast";
import { Regx } from "app/common/util/regx";
import { useTranslation } from "react-i18next";

interface Props {
    saveCallback: () => void;
    editMode?: boolean;
    submitButtonText?: string;
}

export default observer(function ProfileFiatAccountForm({ saveCallback, editMode = false, submitButtonText = "Save" }: Props) {
    const { t } = useTranslation();
    const {
        profileStore: { loading, createFIATAccount },
    } = useStore();
    const [files, setFiles] = useState<any>([]);
    const [feesAgreement, setFeesAgreement] = useState(false);
    const [ilicitAgreement, setIlicitAgreement] = useState(false);
    const [shellAgreement, setShellAgreement] = useState(false);

    const validationSchema = Yup.object({
        bank: Yup.string().required("The bank is required"),
        iban: Yup.string().required("The IBAN is required").matches(Regx.Iban, "Invalid format"),
        swift: Yup.string().required("The SWIFT/BIC is required").matches(Regx.Swift, "Invalid format"),
        currency: Yup.string().required("The currency is required"),
    });

    return (
        <Formik
            initialValues={new FIATAccountFormValues()}
            onSubmit={(values) => {
                createFIATAccount(values, files[0])
                    .then(() => {
                        //history.push("/onboarding/success");
                        saveCallback();
                    })
                    .catch(() => {
                        showToast("error.save", MessageType.Error);
                    });
            }}
            validationSchema={validationSchema}
        >
            {({ isValid, dirty }) => (
                <Form>
                    <MyTextInput type="text" label={t("bank.name")} placeholder={t("bank.name")} name="bank" isRequired disabled={!editMode} />
                    <MyTextInput label={t("IBAN")} placeholder={t("IBAN")} name="iban" isRequired disabled={!editMode} />
                    <MyTextInput label={t("SWIFT/BIC")} placeholder={t("SWIFT/BIC")} name="swift" isRequired disabled={!editMode} />
                    <MySelectInput options={getFiatCurrencyOptions()} label={t("currency")} name="currency" isRequired isDisabled={!editMode} />
                    <MyTextInput label={t("label")} placeholder={t("label")} name="description" disabled={!editMode} />
                    {editMode && (
                        <>
                            <Text size="paragraph_bold" mb="4" color="white">
                                {t("add.bank.doc")}
                            </Text>
                            {/*                             
                            <Text size="paragraph" mb="4" color="white">
                                {t("bank.statement.info.p1")}
                            </Text>
                            <Text size="paragraph" mb="4" color="white">
                                {t("bank.statement.info.p2")}
                            </Text>
                            <Text size="paragraph" mb="4" color="white">
                                {t("bank.statement.info.p3")}
                            </Text>
                            <Text size="paragraph" mb="4" color="white">
                                {t("bank.statement.info.p4")}
                            </Text>
                            <Text size="paragraph" mb="4" color="white">
                                {t("bank.statement.info.p5")}
                            </Text> */}
                            <PhotoWidgetDropzone setFiles={setFiles} />
                            <Checkbox colorScheme="yellow" onChange={() => setFeesAgreement(!feesAgreement)} isChecked={feesAgreement} mb="4">
                                {t("fees.agreement")}
                            </Checkbox>
                            <Checkbox colorScheme="yellow" onChange={() => setIlicitAgreement(!ilicitAgreement)} isChecked={ilicitAgreement} mb="4">
                                {t("ilicit.agreement")}
                            </Checkbox>
                            <Checkbox colorScheme="yellow" onChange={() => setShellAgreement(!shellAgreement)} isChecked={shellAgreement} mb="10">
                                {t("shell.agreement")}
                            </Checkbox>
                            <Button
                                variant="primary"
                                isDisabled={!isValid || !dirty || !(files.length > 0) || !feesAgreement || !ilicitAgreement || !shellAgreement}
                                isLoading={loading}
                                loadingText={submitButtonText}
                                type="submit"
                                isFullWidth
                            >
                                {submitButtonText}
                            </Button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
});
