import * as React from "react";
import { Flex, FlexProps, Text } from "@chakra-ui/react";

interface Props extends FlexProps {
  label: string;
  children: React.ReactNode;
}

export default function Row({ label, children, ...rest }: Props) {
  return (
    <Flex
      p="6"
      align={{ base: "flex-start", sm: "center" }}
      bg="black"
      borderRadius="0.5rem"
      justify="space-between"
      sx={{ gap: "12px" }}
      direction={{ base: "column", sm: "row" }}
      {...rest}
    >
      <Text size="paragraph_normal" color="gray.500">
        {label}
      </Text>
      {children}
    </Flex>
  );
}
