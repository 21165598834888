import { Flex, FlexProps, Text, Divider, Button } from "@chakra-ui/react";
import ActivityIcon from "app/components/activityIcon";
import { Bin } from "assets/icons/activity";
import Row from "app/components/row/Row";
import Notification from "app/components/notification";
import SmallBadge from "app/components/smallBadge";
import { MessageType } from "app/models/message";
import {
    getTransactionAmount,
    getTransactionFee,
    getTransactionStatusColor,
    getTransactionStatusLabel,
    getTransactionTypeLabel,
    Transaction,
    TransactionStatus,
    TransactionType,
    getTransactionRate,
    getTransactionAmountBeforeFees,
    getTransactionAmountAfterFees,
    getTransactionCurrency,
} from "app/models/transaction";
import { format } from "date-fns";
import { getTransactionIcon } from "app/models/fundingRequest";
import { useTranslation } from "react-i18next";
import { isFiat } from "app/models/rate";

interface Props extends FlexProps {
    transaction: Transaction;
    handleCancel: () => void;
}

export default function TransactionOverview({ transaction, handleCancel, ...rest }: Props) {
    const { t } = useTranslation();
    return (
        <Flex
            bg="gray.800"
            p={{ base: "6", xl: "10" }}
            height="min-content"
            borderWidth="1px"
            borderRadius="0.75rem"
            borderStyle="solid"
            borderColor="gray.700"
            direction="column"
            // position="fixed"
            {...rest}
        >
            <Flex justify="space-between" align={{ base: "flex-end", sm: "center" }} w="100%" sx={{ gap: "12px" }}>
                <Flex align={{ base: "flex-start", sm: "center" }} direction={{ base: "column", sm: "row" }}>
                    <ActivityIcon
                        isPending={transaction.status == TransactionStatus.Pending}
                        color="white"
                        size="big"
                        icon={getTransactionIcon(transaction.type, "36px")}
                        mr={{ base: "0", sm: "6" }}
                        mb={{ base: "4", sm: "0" }}
                    />
                    <Flex direction="column">
                        <Text size="h4_bold" color="white" mb="1">
                            {t(getTransactionTypeLabel(transaction.type).toLowerCase())}{" "}
                            {getTransactionCurrency(transaction, transaction.type == TransactionType.Deposit)}
                            {transaction.type == TransactionType.Exchange && " to " + getTransactionCurrency(transaction, true)}
                        </Text>
                        <Text size="smallText_normal" color="gray.500">
                            {format(transaction.date, "dd MMM yyyy h:mm aa")}
                        </Text>
                    </Flex>
                </Flex>
                <Flex direction="column" align="flex-end" justify="space-between">
                    <Text
                        size={transaction.status == TransactionStatus.Canceled ? "paragraph_strike" : "paragraph_normal"}
                        color="white"
                        mb="1"
                        textAlign="right"
                    >
                        {getTransactionAmount(transaction, transaction.type == TransactionType.Deposit)}
                    </Text>
                    <Text size="smallText_normal" color="gray.500" textAlign="right">
                        {getTransactionAmount(transaction, transaction.type != TransactionType.Deposit)}
                    </Text>
                </Flex>
            </Flex>
            <Divider borderColor="gray.700" my="6" />
            <Row label="Status" mb="2">
                <SmallBadge color={getTransactionStatusColor(transaction.status)} bg="gray.800">
                    {t(getTransactionStatusLabel(transaction.status).toLowerCase())}
                </SmallBadge>
            </Row>
            {transaction.status != TransactionStatus.Canceled && (
                <>
                    {transaction.type == TransactionType.Exchange ? (
                        <Row label={t("exchange.rate")} mb="2">
                            <Text color="white" size="paragraph_semibold">
                                {getTransactionRate(transaction)}
                            </Text>
                        </Row>
                    ) : (
                        <>
                            <Row label={t("activity.details")} mb="2">
                            <Flex direction="column" align={{ base: "flex-start", sm: "flex-end" }}>
                                    {transaction.type == TransactionType.Deposit && !isFiat(getTransactionCurrency(transaction, true)) ? (
                                        <a
                                            href={`https://chain.so/tx/${getTransactionCurrency(
                                                transaction,
                                                transaction.type == TransactionType.Deposit
                                            )}/${transaction?.details}`}
                                            target="_blank"
                                        >
                                            <Text
                                                color="white"
                                                size="paragraph_semibold"
                                                mb="1"
                                                textAlign="right"
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                                maxWidth="15em"
                                            >
                                                {transaction?.details}
                                            </Text>
                                        </a>
                                    ) : (
                                        <Text
                                            color="white"
                                            size="paragraph_semibold"
                                            mb="1"
                                            textAlign="right"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            maxWidth="15em"
                                        >
                                            {transaction?.details}
                                        </Text>
                                    )}

                                    <Text color="white" size="paragraph_normal" textAlign="right"></Text>
                                </Flex>
                            </Row>
                            <Row label={t("activity.amount.no.fee")} mb="2">
                                <Text color="white" size="paragraph_semibold">
                                    {getTransactionAmountBeforeFees(transaction)}
                                </Text>
                            </Row>
                            <Row label={t("activity.amount.w.fee")} mb="2">
                                <Text color="white" size="paragraph_semibold">
                                    {getTransactionAmountAfterFees(transaction)}
                                </Text>
                            </Row>
                        </>
                    )}
                    <Row label={t("prohash.fee")} mb="3">
                        <Text color="white" size="paragraph_semibold">
                            {getTransactionFee(transaction)}
                        </Text>
                    </Row>
                    {transaction.status == TransactionStatus.Pending && (
                        <>
                            <Notification
                                message={t("Txs.overview.mess")}
                                bg="gray.700"
                                mb="6"
                                messageStyle={{ fontWeight: "normal" }}
                                type={MessageType.Info}
                            />

                            <Button w="100%" variant="secondary" leftIcon={<Bin w="17px" h="16px" />} onClick={handleCancel}>
                                {t("cancel.tx.btn")}
                            </Button>
                        </>
                    )}
                </>
            )}
        </Flex>
    );
}
