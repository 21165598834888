import { Button, Text, useBreakpointValue, Flex } from "@chakra-ui/react";
import { Check, Unchecked } from "assets/icons/unauthenticated";
import { observer } from "mobx-react-lite";
import { DocumentType } from "../../app/models/document";
import { useStore } from "app/stores/store";
import { useState } from "react";
import PhotoWidgetDropzone from "app/common/imageUpload/PhotoWidgetDropzone";
import { useTranslation } from "react-i18next";

interface Props {
    saveCallback: () => void;
    documentType: DocumentType;
    submitButtonText?: string;
}

export default observer(function DocumentUploadForm({ saveCallback, documentType, submitButtonText = "Save" }: Props) {
    const hideLogo = useBreakpointValue({ base: true, sm: false });
    const [files, setFiles] = useState<any>([]);
    const {
        commonStore,
        profileStore,
        userStore: { user },
    } = useStore();
    const { uploadDocument, uploading } = profileStore;
    const { t } = useTranslation();

    function handlePhotoUpload() {
        uploadDocument(documentType, "client comment", files[0]).then(() => {
            saveCallback();
        });
    }

    function getDescription(documentType: DocumentType) {
        switch (documentType) {
            case DocumentType.ID:
                return (
                    <Flex direction="column" color="white" mb="10">
                        <Flex align="center" mb="6">
                            
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria.ID")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                               {t("documents.criteria.ID.p1")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                               {t("documents.criteria.ID.p2")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                               {t("documents.criteria.ID.p3")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                               {t("documents.criteria.ID.p4")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                               {t("documents.criteria.ID.p5")}
                            </Text>
                        </Flex>
                        <Flex align="center">
                            <Unchecked w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria.ID.p6")}
                            </Text>
                        </Flex>
                    </Flex>
                );
            case DocumentType.POR:
                return (
                    <Flex direction="column" color="white" mb="10">
                        <Flex align="center" mb="6">
                            
                            <Text size="smallText_semibold" ml="3">
                            {t("documents.criteria.Selfie")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria,residence.p1")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria,residence.p2")}
                            </Text>
                        </Flex>
                        <Flex align="center">
                            <Unchecked w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria,residence.p3")}
                            </Text>
                        </Flex>
                    </Flex>
                );
            case DocumentType.Selfie:
                return (
                    <Flex direction="column" color="white" mb="10">
                        <Flex align="center" mb="6">
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria.Selfie")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria.Selfie.p1")}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="6">
                            <Check w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria.Selfie.p2")}
                            </Text>
                        </Flex>
                        <Flex align="center">
                            <Unchecked w="20px" h="20px" />
                            <Text size="smallText_semibold" ml="3">
                                {t("documents.criteria.Selfie.p3")}
                            </Text>
                        </Flex>
                    </Flex>
                );
            default:
                return "";
        }
    }

    function getTitle(documentType: DocumentType) {
        switch (documentType) {
            case DocumentType.ID:
                return t("documents.criteria.ID.p1");//"Upload a valid ID";
            case DocumentType.POR:
                return t("documents.criteria,residence.p1");//"Upload proof of residence";
            case DocumentType.Selfie:
                return t("documents.criteria.Selfie.p1");//"Take a Selfie";
            default:
                return documentType;
        }
    }

    return (
        <>
            <Text size="paragraph_bold" mb="4" color="white">
                {getTitle(documentType)}
            </Text>

            <PhotoWidgetDropzone setFiles={setFiles} />
            {getDescription(documentType)}

            <Button
                variant="primary"
                isDisabled={!(files.length > 0)}
                isLoading={uploading}
                loadingText={submitButtonText}
                onClick={handlePhotoUpload}
            >
                {submitButtonText}
            </Button>
        </>
    );
});
