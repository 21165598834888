import * as React from "react";
import { Drawer, DrawerContent, Flex, Button, FlexProps, Text, Divider } from "@chakra-ui/react";
import { Logo, ArrowDown, Home, Trade, DepositWithdraw, Activity, Settings, Hamburger, Account } from "assets/icons/navigation";
import { EmptyX } from "assets/icons/settings";
import { useLocation } from "react-router-dom";
import { history } from "../../..";
import { useTranslation } from "react-i18next";
import { useStore } from "app/stores/store";
import { SyntheticEvent, useState } from "react";

interface MenuItemProps extends FlexProps {
    to: string;
    children: string;
    icon: React.ReactNode;
    handleClose: () => void;
}

function MenuItem({ to, children, icon, handleClose, ...rest }: MenuItemProps) {
    // const navigate = useNavigate();
    const { pathname } = useLocation();
    const isActive = React.useMemo(() => pathname === to, [pathname, to]);
    const route = React.useCallback(
        (to: string) => {
            history.push(to);
            handleClose();
        },
        [history, handleClose]
    );

    return (
        <Flex direction="column" w="100%">
            <Flex w="100%" py="6" justify="space-between" align="center" color="gray.300" onClick={() => route(to)} {...rest}>
                <Flex align="center">
                    <Flex color={isActive ? "yellow.800" : "gray.300"}>{icon}</Flex>
                    <Text ml="4" size="paragraph_semibold">
                        {children}
                    </Text>
                </Flex>
                <ArrowDown w="20px" h="20px" transform="rotate(-90deg)" />
            </Flex>
            <Divider borderColor="gray.600" />
        </Flex>
    );
}

interface Props {
    isOpen: boolean;
    handleClose: () => void;
}

export default function MobileNav({ isOpen, handleClose }: Props) {
    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(i18n.language);
    const {
        userStore: { user, logout, isAuthorized },
    } = useStore();
    const changeLanguage = (event: SyntheticEvent, data: string) => {
        setSelectedLang(data);
        i18n.changeLanguage(data);
    };
    return (
        <Drawer placement="top" onClose={handleClose} isOpen={isOpen}>
            <DrawerContent>
                <Flex py="6" px="4" w="100%" bg="gray.800" justify="space-between">
                    <Logo w="123px" h="20px" color="gray.800" />
                    <EmptyX w="20px" h="20px" onClick={handleClose} color="white" />
                </Flex>
                <Flex height="100%" w="100%" p="4" direction="column" overflowY="auto">
                    <Flex direction="column" cursor="pointer" mb="6">
                        <MenuItem to="/home" icon={<Home w="20px" h="20px" />} handleClose={handleClose}>
                            {t("navigation.home")}
                        </MenuItem>
                        <MenuItem to="/exchange" icon={<Trade w="20px" h="20px" />} handleClose={handleClose}>
                            {t("navigation.trade")}
                        </MenuItem>
                        <MenuItem to="/funding" icon={<DepositWithdraw w="20px" h="20px" />} handleClose={handleClose}>
                            {t("navigation.funding")}
                        </MenuItem>
                        <MenuItem to="/activity" icon={<Activity w="20px" h="20px" />} handleClose={handleClose}>
                            {t("navigation.activity")}
                        </MenuItem>
                        {/* <MenuItem to={`/profiles/${user?.username}`} icon={<Settings w="20px" h="20px" />} handleClose={handleClose}>
                            Settings - old
                        </MenuItem> */}
                        <MenuItem to={`/profiles/settings`} icon={<Settings w="20px" h="20px" />} handleClose={handleClose}>
                            Settings
                        </MenuItem>
                        {isAuthorized && (
                            <>
                                <Flex w="100%" py="6" justify="space-between" cursor="default" align="center" color="gray.300">
                                    <Text ml="4" size="paragraph_semibold">
                                        Admin
                                    </Text>
                                    <ArrowDown w="20px" h="20px" />
                                </Flex>
                                <MenuItem to="/admin/fees/FundingFees" icon={<></>} handleClose={handleClose}>
                                    Fees
                                </MenuItem>
                                <MenuItem to="/admin/fundingrequests" icon={<></>} handleClose={handleClose}>
                                    Funding requests
                                </MenuItem>
                                <MenuItem to="/admin/kyc" icon={<></>} handleClose={handleClose}>
                                    KYC
                                </MenuItem>
                                <MenuItem to="/admin/users" icon={<></>} handleClose={handleClose}>
                                    Users
                                </MenuItem>
                                <MenuItem to="/admin/reports/balances" icon={<></>} handleClose={handleClose}>
                                    Balances
                                </MenuItem>
                                <MenuItem to="/admin/reports/allTransactions" icon={<></>} handleClose={handleClose}>
                                    All Transactions
                                </MenuItem>
                            </>
                        )}
                    </Flex>
                    <Flex w="100%" justify="center" align="center" mb="6">
                        <Text
                            size="smallText_bold"
                            color={selectedLang === "en" ? "yellow.800" : "white"}
                            textDecor={selectedLang === "en" ? "underline" : ""}
                            mr="8"
                            onClick={(e) => changeLanguage(e, "en")}
                        >
                            EN
                        </Text>
                        <Text
                            size="smallText_bold"
                            color={selectedLang === "ro" ? "yellow.800" : "white"}
                            textDecor={selectedLang === "ro" ? "underline" : ""}
                            onClick={(e) => changeLanguage(e, "ro")}
                        >
                            RO
                        </Text>
                    </Flex>
                    <Button variant="secondary" mt="auto" w="100%" onClick={logout}>
                        Log Out
                    </Button>
                </Flex>
            </DrawerContent>
        </Drawer>
    );
}
