import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Download = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="#E5E7EA">
      <path d="M18.89 14.115a1.01 1.01 0 0 0-1.01 1.01v1.546a1.31 1.31 0 0 1-1.31 1.308H3.43a1.31 1.31 0 0 1-1.31-1.308v-1.546a1.01 1.01 0 1 0-2.02 0v1.546a3.333 3.333 0 0 0 3.33 3.333h13.14a3.333 3.333 0 0 0 3.333-3.333v-1.546a1.009 1.009 0 0 0-1.014-1.01z" />
      <path d="M10 0a1.616 1.616 0 0 0-1.616 1.616V9.17a.208.208 0 0 1-.208.208H5.96a.808.808 0 0 0-.61 1.338l4.04 4.647a.808.808 0 0 0 1.22 0l4.04-4.647a.808.808 0 0 0-.61-1.338h-2.216a.208.208 0 0 1-.208-.208V1.616A1.616 1.616 0 0 0 10 0z" />
    </g>
  </Icon>
);

export const Add = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M10 19.583A9.583 9.583 0 1 0 .417 9.999 9.594 9.594 0 0 0 10 19.583zM5 9.166H8.96a.208.208 0 0 0 .208-.208V4.999a.833.833 0 0 1 1.667 0v3.959a.208.208 0 0 0 .208.208H15a.833.833 0 0 1 0 1.667h-3.958a.208.208 0 0 0-.208.208v3.958a.833.833 0 0 1-1.667 0v-3.958a.208.208 0 0 0-.208-.208H5a.833.833 0 1 1 0-1.667z"
      fill="currentColor"
    />
  </Icon>
);

export const Remove = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M10 19.583A9.583 9.583 0 1 0 .417 9.999 9.594 9.594 0 0 0 10 19.583zM5 9.166h10a.833.833 0 0 1 0 1.667H5a.833.833 0 1 1 0-1.667z"
      fill="currentColor"
    />
  </Icon>
);

export const Trade = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <g fill="currentColor">
      <path d="M9.1 4.113a6.032 6.032 0 015.916 1.877 1.042 1.042 0 101.56-1.38 8.115 8.115 0 00-7.957-2.527 8.557 8.557 0 00-6.137 5.925.208.208 0 01-.226.149l-.861-.109A.41.41 0 001 8.231a.417.417 0 00-.016.438l2.064 3.619a.416.416 0 00.31.206.343.343 0 00.052 0 .416.416 0 00.3-.126l2.897-2.995a.417.417 0 00-.25-.703l-1.565-.193a.208.208 0 01-.167-.278 6.417 6.417 0 014.474-4.086zm10.905 7.637a.417.417 0 00.012-.438l-2.083-3.608a.417.417 0 00-.663-.078l-2.88 3.012a.416.416 0 00.25.701l1.54.184a.208.208 0 01.171.278 6.406 6.406 0 01-4.474 4.083 6.036 6.036 0 01-5.916-1.877 1.042 1.042 0 10-1.56 1.38 8.116 8.116 0 007.957 2.525 8.55 8.55 0 006.141-5.934.21.21 0 01.225-.149l.882.106a.38.38 0 00.05 0 .417.417 0 00.348-.185z"></path>
    </g>
  </Icon>
);

export const Bin = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      d="M13.5 5h-10a.333.333 0 0 0-.334.333v9.334A1.333 1.333 0 0 0 4.499 16h8a1.334 1.334 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13.499 5zm-6.167 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6zm3.333 0a.5.5 0 0 1-1 0v-6a.5.5 0 1 1 1 0v6zM15.166 2.667h-3.167a.167.167 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 10.166 0H6.833a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.833a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333zM6.499 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.166.166 0 0 1-.166.167H6.666a.167.167 0 0 1-.167-.167z"
      fill="#fff"
    />
  </Icon>
);

export const Filters = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g>
      <path
        d="M3.334 8.333V2.5m13.333 15v-4.167V17.5zm-2.5-4.167h5-5zM3.334 17.5v-5.833V17.5zm-2.5-5.833h5-5zm9.167 5.833V10v7.5zM7.5 6.667h5-5zM16.667 10V2.5 10zm-6.666-3.333V2.5v4.167z"
        stroke="#09090B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);

export const Lock = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g>
      <path
        d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0zm2.083 15.11a.418.418 0 0 1-.41.476h-3.58a.416.416 0 0 1-.408-.495l1.046-4.883a2.858 2.858 0 0 1 .577-5.375 2.911 2.911 0 0 1 3.609 2.792 2.866 2.866 0 0 1-1.649 2.583l.815 4.901z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export const CalendarIcon = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      d="M10 14H8.667a1.334 1.334 0 0 0 0 2.667H10A1.333 1.333 0 0 0 10 14zM16.667 14h-1.334a1.333 1.333 0 0 0 0 2.667h1.334a1.333 1.333 0 1 0 0-2.667zM23.333 14H22a1.333 1.333 0 1 0 0 2.667h1.333a1.333 1.333 0 1 0 0-2.667zM10 19.333H8.667a1.333 1.333 0 0 0 0 2.667H10a1.334 1.334 0 0 0 0-2.667zM16.667 19.333h-1.334a1.333 1.333 0 1 0 0 2.667h1.334a1.333 1.333 0 0 0 0-2.667zM23.333 19.333H22A1.333 1.333 0 1 0 22 22h1.333a1.333 1.333 0 0 0 0-2.667zM10 24.667H8.667a1.333 1.333 0 1 0 0 2.666H10a1.333 1.333 0 1 0 0-2.666zM16.667 24.667h-1.334a1.333 1.333 0 1 0 0 2.666h1.334a1.333 1.333 0 1 0 0-2.666zM23.333 24.667H22a1.333 1.333 0 1 0 0 2.666h1.333a1.333 1.333 0 1 0 0-2.666z"
      fill="currentColor"
    />
    <path
      d="M28.667 4H25a.333.333 0 0 1-.333-.333V1.333a1.333 1.333 0 1 0-2.667 0v6.334a1 1 0 0 1-2 0v-3A.667.667 0 0 0 19.333 4H11a.333.333 0 0 1-.333-.332V1.333a1.334 1.334 0 0 0-2.667 0v6.334a1 1 0 0 1-2 0v-3A.667.667 0 0 0 5.333 4h-2A2.667 2.667 0 0 0 .667 6.667v22.666A2.667 2.667 0 0 0 3.333 32h25.334a2.667 2.667 0 0 0 2.666-2.667V6.667A2.667 2.667 0 0 0 28.667 4zm0 24.667a.667.667 0 0 1-.667.666H4a.667.667 0 0 1-.667-.666v-16A.667.667 0 0 1 4 12h24a.667.667 0 0 1 .667.667v16z"
      fill="currentColor"
    />
  </Icon>
);
