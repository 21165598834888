import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Text, Flex, PinInput, PinInputField, Heading, useBreakpointValue } from "@chakra-ui/react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { MessageType } from "app/models/message";
import { useState } from "react";
import Notification from "app/components/notification";
import { useTranslation } from "react-i18next";

interface Props {
    callback: (params: any) => Promise<void>;
    params: any;
}

export default observer(function TFAVerification({ callback, params }: Props) {
    const { userStore } = useStore();
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    const [TFACode, setTFACode] = useState("");
    const [err, setErr] = useState("");

    const validationSchema = Yup.object({
        TFAGoogleCode: Yup.string()
            .required("Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Must be a 6 digit code")
            .max(6, "Must be a 6 digit code"),
    });
    const { t } = useTranslation();

    function handleSubmit() {
        if (!userStore.isLoggedIn)
            userStore
                .login({
                    ...params,
                    TFAGoogleCode: TFACode,
                })
                .catch(() => setErr("Invalid credentials"));
        else
            userStore
                .verifyTFA(TFACode)
                .then((TFAVerified) => {
                    if (TFAVerified) callback(params);
                    else setErr("Invalid TFA code");
                })
                .catch((error) => setErr("Invalid TFA code"));
    }

    return (
        <Flex direction="column" w="100%" px={{ base: "0", md: "20" }} py="3">
            <Heading size={headingSize} mb="6" textAlign="center">
                {t("2FA.header")}
            </Heading>
            {err != "" && (
                <Flex
                    gridColumn="1/-1"
                    justify="center"
                    order={{ base: 1, md: 0 }}
                    // pos={{ base: "static", md: "absolute" }}
                    mt="3"
                    top="0"
                    left="0"
                    right="0"
                >
                    <Notification messageStyle={{ fontWeight: "normal" }} message={err} type={MessageType.Error} bg="gray.700" mb="6" />
                </Flex>
            )}

            <Text mb="4" size="paragraph_semibold" color="gray.400">
                {t("2FA.login")}
            </Text>

            <Flex
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                py={{ base: "6", sm: "8" }}
                px={{ base: "4", sm: "8" }}
                direction="column"
                mb="10"
            >
                <Flex sx={{ gap: "8px" }}>
                    <PinInput otp placeholder="•" onChange={(value) => setTFACode(value)}>
                        <PinInputField w="100%" />
                        <PinInputField w="100%" />
                        <PinInputField w="100%" />
                        <PinInputField w="100%" />
                        <PinInputField w="100%" />
                        <PinInputField w="100%" />
                    </PinInput>
                </Flex>
            </Flex>

            <Button
                variant="primary"
                mb="10"
                isLoading={userStore.loading}
                isDisabled={TFACode.length != 6}
                loadingText="Verify"
                onClick={handleSubmit}
                isFullWidth
            >
                {t("validate")}
            </Button>
        </Flex>
    );
});
