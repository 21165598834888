import { ChakraProvider, Link, Text } from "@chakra-ui/react";
import theme from "../../theme";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useLocation } from "react-router-dom";
import Landing from "features/landing/Landing";
import Home from "../../features/home/Home";
import TestErrors from "../../features/errors/TestError";
// import { ToastContainer } from "react-toastify";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import { useStore } from "../stores/store";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import ProfileSettings from "../../features/profiles/ProfileSettings";
import PrivateRoute from "./PrivateRoute";
import RegisterSuccess from "../../features/users/RegisterSuccess";
import ConfirmEmail from "../../features/users/ConfirmEmail";
import ForgotPwd from "../../features/users/ForgotPwd";
import ResetPwdForm from "../../features/users/ResetPwdForm";
import VerifyWhitelistedAddresses from "../../features/profiles/VerifyWhitelistedAddresses";
import Exchange from "../../features/exchange/Exchange";
import FeesPage from "../../features/admin/fees/FeesPage";
import { Role } from "../models/user";
import KYCDocuments from "../../features/admin/kyc/KYCDocuments";
import FundingList from "../../features/admin/fundings/FundingList";
import UsersList from "../../features/admin/users/UsersList";
import LoginForm from "../../features/users/LoginForm";
import RegisterForm from "features/users/RegisterForm";
import One from "features/onboarding/One";
import Two from "features/onboarding/Two";
import Three from "features/onboarding/Three";
import Four from "features/onboarding/Four";
import Success from "features/onboarding/Success";
import Five from "features/onboarding/Five";
import Six from "features/onboarding/Six";
import Seven from "features/onboarding/Seven";
import NavigationBar from "./NavigationBar";
import DepositWithdraw from "features/funding/DepositWidthdraw";
import Activity from "features/activity/Activity";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import AllTransactions from "features/admin/reports/AllTransactions";
import TotalBalances from "features/admin/reports/TotalBalances";
import TermsCond from "features/infos/TermsCond";
import AML from "features/infos/AML";
import PrivacyPolicy from "features/infos/PrivacyPolicy";
import Questions from "features/landing/Questions";

function App() {
    const location = useLocation();
    const { commonStore, userStore } = useStore();
    const { t } = useTranslation();

    useEffect(() => {
        if (commonStore.token) {
            // console.log("app get user");
            userStore.getUser().finally(() => commonStore.setAppLoaded());
        } else {
            commonStore.setAppLoaded();
        }
    }, [commonStore, userStore]);

    if (!commonStore.appLoaded) return <LoadingComponent content="Loading app..." />;

    return (
        <ChakraProvider theme={theme} resetCSS>
            {/* <ToastContainer
                position="bottom-right"
                hideProgressBar
                toastStyle={{
                    backgroundColor: "black",
                    borderRadius: "0.75rem",
                }}
            /> */}
            <ModalContainer />
            <CookieConsent
                // debug
                buttonText={t("coockie.accept.bttn")}
                buttonStyle={{ borderRadius: "8px", background: "#FEAF17", fontWeight: "semibold", fontSize: "sm" }}
                style={{ textAlign: "center", background: "#1D1F25" }}
                enableDeclineButton
                declineButtonText={t("coockie.decline.bttn")}
                declineButtonStyle={{ borderRadius: "8px", background: "#343841", fontWeight: "semibold" }}
                flipButtons
                expires={30}
            >
                <Text size="paragraph_normal">
                    {t("coockie.mess1")}{" "}
                    <Link to="/" size="paragraph_normal">
                        {t("coockie.mess2")}
                    </Link>{" "}
                    {t("coockie.mess3")}
                </Text>
            </CookieConsent>
            {/* <ToastContainer/> */}

            <Route exact path="/" component={Landing} />
            {/* <Route exact path='/' component={LoginForm} /> */}

            <Route
                path={"/(.+)"}
                render={() => (
                    <>
                        <NavigationBar />

                        <Switch>
                            <PrivateRoute exact path="/onboarding/one" component={One} />
                            <PrivateRoute exact path="/onboarding/two" component={Two} />
                            <PrivateRoute exact path="/onboarding/three" component={Three} />
                            <PrivateRoute exact path="/onboarding/four" component={Four} />
                            <PrivateRoute exact path="/onboarding/five" component={Five} />
                            <PrivateRoute exact path="/onboarding/six" component={Six} />
                            <PrivateRoute exact path="/onboarding/seven" component={Seven} />
                            <PrivateRoute exact path="/onboarding/success" component={Success} />
                            <PrivateRoute exact path="/exchange" component={Exchange} />
                            <PrivateRoute exact path="/home" component={Home} />
                            <PrivateRoute path="/activity" component={Activity} />
                            <PrivateRoute exact path="/funding" component={DepositWithdraw} />
                            <PrivateRoute path="/admin/fees" component={FeesPage} roles={[Role.Admin]} />
                            <PrivateRoute exact path="/admin/kyc" component={KYCDocuments} roles={[Role.Operator, Role.Admin]} />
                            <PrivateRoute exact path="/admin/fundingrequests" component={FundingList} roles={[Role.Operator, Role.Admin]} />
                            <PrivateRoute exact path="/admin/users" component={UsersList} roles={[Role.Operator, Role.Admin]} />
                            <PrivateRoute exact path="/admin/reports/allTransactions" component={AllTransactions} roles={[Role.Operator, Role.Admin]} />
                            <PrivateRoute exact path="/admin/reports/balances" component={TotalBalances} roles={[Role.Operator, Role.Admin]} />
                            <PrivateRoute
                                path={["/admin/profiles/:profileName", "/profiles/settings/:tabIndex", "/profiles/settings"]}
                                component={ProfileSettings}
                            />
                            {/* <PrivateRoute path="/admin/profiles/:profileName" component={ProfileSettings} /> */}

                            <PrivateRoute path="/security/whitelistedaddresses/verify" component={VerifyWhitelistedAddresses} />

                            <PrivateRoute path="/errors" component={TestErrors} />
                            <Route path="/server-error" component={ServerError} />

                            <Route path="/security/login" component={LoginForm} />
                            <Route path="/security/register" component={RegisterForm} />
                            <Route path="/security/registerSuccess" component={RegisterSuccess} />
                            <Route path="/security/verifyEmail" component={ConfirmEmail} />
                            <Route path="/security/forgotPwd" component={ForgotPwd} />
                            <Route path="/security/resetPassword" component={ResetPwdForm} />

                            <Route path="/terms" component={TermsCond} />
                            <Route path="/aml" component={AML} />
                            <Route path="/privacypolicy" component={PrivacyPolicy} />
                            <Route path="/faq" component={Questions} />
                            

                            <Route component={NotFound} />
                        </Switch>
                        <Footer />
                    </>
                )}
            />
        </ChakraProvider>
    );
}

export default observer(App);
