import * as React from "react";
import { FlexProps, Flex, Text } from "@chakra-ui/react";
interface Props extends FlexProps {
  color: string;
  children: string;
}

export default function SmallBadge({ color, children, ...rest }: Props) {
  return (
    <Flex
      p="2"
      bg="black"
      borderRadius="0.25rem"
      w="min-content"
      h="min-content"
      {...rest}
    >
      <Flex bg={color} w="4px" borderRadius="full" mr="2" />
      <Text size="caption_bold" color="white">
        {children}
      </Text>
    </Flex>
  );
}
