import * as React from "react";
// import { Intro, Rates, Security, Action, Steps, Questions, Call } from "features/landing";
import { history } from "../..";
import UnauthenticatedNavigation from "app/components/unauthenticatedNavigation";
import { useStore } from "app/stores/store";
import Footer from "app/layout/Footer";
import Intro from "./Intro";
import Rates from "./Rates";
import Action from "./Action";
import Call from "./Call";
import Questions from "./Questions";
import Security from "./Security";
import Steps from "./Steps";
import { useTranslation } from "react-i18next";

export default function Landing() {
    const {
        userStore: { user, isLoggedIn },
    } = useStore();
    React.useEffect(() => {
        if (user && isLoggedIn) {

            // const state: any  = history.location.state;
            // if (state && state.from) {
            //     history.push(state.from);
            // } else {
                user.onboardingDone ?
                history.push("/home")
                :
                history.push("/onboarding/one");
            // }l
        }
    }, []);
    const { t } = useTranslation();
    return (
        <>
            <UnauthenticatedNavigation />

            <Intro />
            <Rates />
            <Security />
            <Action headerText={t("landing.p1")} color="yellow.800" imageSrc="landing/trade" order="normal" showDivider />
            <Action headerText={t("landing.p2")} color="teal.500" imageSrc="landing/withdraw" order="reverse" />
            <Steps />
            <Questions />
            <Call />
            <Footer />
        </>
    );
}
