import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { parse, isDate, format } from "date-fns";
import { Regx } from "app/common/util/regx";
import { MessageType } from "app/models/message";
import { Checkbox, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    saveCallback: () => void;
    editMode?: boolean;
    submitButtonText?: string;
}

export default observer(function ProfileInfoForm({ saveCallback, editMode = false, submitButtonText= "Save" }: Props) {
    const {
        profileStore: { profile, updateProfile, loading },
        commonStore: { setNotification },
    } = useStore();
    const today = new Date();
    const [agreement, setAgreement] = useState(false);

    const validationSchema = Yup.object({
        displayName: Yup.string().required("Required"),
        dob: Yup.date()
            .required("Required")
            .transform((value, originalValue) => {
                return isDate(originalValue) ? originalValue : parse(originalValue, "dd/MM/yyyy", today);
            })
            .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), "The date is too big")
            .min(new Date(today.getFullYear() - 100, today.getMonth()), "The date is too small")
            .typeError("Invalid format (DD/MM/YYYY)"),
        phoneNumber: Yup.string().required("Required").matches(Regx.Phone, "Invalid format"),
    });
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{
                displayName: profile?.displayName,
                phoneNumber: profile?.phoneNumber,
                dob: profile?.dob ? format(profile?.dob, "dd/MM/yyyy") : "",
            }}
            onSubmit={(values) => {
                updateProfile({ ...values, dob: parse(values.dob!, "dd/MM/yyyy", new Date()) }).then(() => {
                    // setNotification({
                    //     message: "Profile updated",
                    //     type: MessageType.Info,
                    // });
                    saveCallback();
                });
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <MyTextInput type="text" name="displayName" label={t("profile.name")} placeholder={t("profile.name")} isRequired  disabled={!editMode}/>
                    <MyTextInput type="text" name="dob" label={t("profile.birth.date")} placeholder="DD/MM/YYYY" isRequired disabled={!editMode}/>
                    <MyTextInput type="tel" name="phoneNumber" placeholder={t("profile.phone.nr")}  label={t("profile.phone.nr")} mb="6" isRequired disabled={!editMode}/>
                    {!!!profile?.displayName && <Checkbox mb="10" onChange={() => setAgreement(!agreement)} isChecked={agreement}>I certify my name and date of birth match my photo ID.</Checkbox>}
                    {editMode &&<Button
                        variant="primary"
                        type="submit"
                        isLoading={isSubmitting && loading}
                        isDisabled={!isValid || !dirty || (!!!profile?.displayName && !agreement)}
                        loadingText={submitButtonText}
                        isFullWidth
                    >
                        {submitButtonText}
                    </Button>
}
                </Form>
            )}
        </Formik>
    );
});
