import * as React from "react";
import { Flex, FlexProps, Text, TextProps, useDisclosure } from "@chakra-ui/react";
import WhitelistedAddressInfo from "./WhitelistedAddressInfo";
import PopoverMenu from "app/components/popoverMenu";
import { ArrowDown } from "assets/icons/navigation";
import { Currency } from "app/models/rate";
import { WhitelistedAddress } from "app/models/cryptoAccount";
import { useState } from "react";

interface Props extends FlexProps {
    variant?: "to" | "from" | "none";
    currency: Currency;
    whiteAddresses: WhitelistedAddress[] | undefined;
    setAddress?: (whitelistedAddress: WhitelistedAddress) => void;
}

export default function WhitelistedAddressList({ variant, currency, whiteAddresses, setAddress, ...rest }: Props) {
    const [value, setValue] = useState(currency.toString());
    const { isOpen, onOpen, onClose } = useDisclosure();

    function selectAddress(address: WhitelistedAddress) {
        if (address.verified) {
            setValue(address.value);
            setAddress && setAddress(address);
        }
        onClose();
    }

    return (
        <Flex
            borderRadius="0.5rem"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            bg="black"
            w="100%"
            overflow="hidden"
            direction="column"
            {...rest}
        >
            <PopoverMenu
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                
                triggerContent={
                    <Text
                        mb="3"
                        cursor="pointer"
                        w="100%"
                        maxW="100%"
                        d="flex"
                        justifyContent="space-between"
                        // bg="black"
                        p="6"
                        // borderRadius="0.5rem"
                        // borderWidth="1px"
                        // borderStyle="solid"
                        // borderColor="gray.700"
                        whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" 
                    >
                        {value}
                        <ArrowDown my="auto" width="16px" h="16px" color="gray.500" ml="2" />
                    </Text>
                }
            >
                <Flex direction="column" overflowY="auto" maxHeight={{base:"fit-content", md:"10em"}}>
                    {whiteAddresses &&
                        whiteAddresses.map((address) => {
                          return (
                            <WhitelistedAddressInfo key={address.value}
                                variant={address.verified ? "whitelisted" : "pending"}
                                address={address}
                                onClick={() => selectAddress(address)}
                            />
                          )
                        })}
                </Flex>
            </PopoverMenu>
        </Flex>
    );
}
