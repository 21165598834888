import * as React from "react";
import { Flex, FlexProps, Heading, Button, Text } from "@chakra-ui/react";
import { useStore } from "app/stores/store";
import * as Yup from "yup";
import { Regx } from "app/common/util/regx";
import { Form, Formik } from "formik";
import agent from "app/api/agent";
import { MessageType } from "app/models/message";
import { showToast } from "../../app/components/toast";
import MyTextInput from "app/common/form/MyTextInput";
import { useTranslation } from "react-i18next";

export default function ProfilePassword(props: FlexProps) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = React.useState(true);
    const {
        profileStore: { isCurrentUser },
    } = useStore();

    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required("Required")
            .matches(Regx.Pwd8, "Must contain at least 8 Characters, one Uppercase, one Number and one Special Case Character"),
        password: Yup.string()
            .required("Required")
            .matches(Regx.Pwd8, "Must contain at least 8 Characters, one Uppercase, one Number and one Special Case Character"),
        confirmPassword: Yup.string()
            .required("Required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
    return (
        <Flex color="white" direction="column" {...props}>
            <Flex justify="space-between" align="center" w="100%" mb="6">
                <Heading size="h4_bold">{t("profile.psswrd.h1")}</Heading>
                {/* <Button variant="overlineLink" onClick={() => setShowPassword((p) => !p)}>
                    {showPassword ? "Cancel" : "Update"}
                </Button> */}
            </Flex>
            <Flex
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                py={{ base: "6", sm: "8" }}
                px={{ base: "4", sm: "8" }}
            >
                {isCurrentUser && showPassword ? (
                    <Flex direction="column" w="100%">
                        <Formik
                            initialValues={{
                                currentPassword: "",
                                password: "",
                                confirmPassword: "",
                                error: null,
                            }}
                            onSubmit={(values, { setErrors }) =>
                                agent.Account.changePassword({
                                    password: values.currentPassword,
                                    newPassword: values.password,
                                })
                                    .then(() => {
                                        showToast("Password Changed", MessageType.Success);
                                        values.password = "";
                                        values.confirmPassword = "";
                                        values.currentPassword = "";
                                        // history.push("/");
                                    })
                                    .catch((error) => {
                                        showToast("Change password failed", MessageType.Error);
                                        setErrors({
                                            error: "Change password failed",
                                        });
                                    })
                            }
                            validationSchema={validationSchema}
                        >
                            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                                <Form onSubmit={handleSubmit} autoComplete="off">
                                    <MyTextInput name="currentPassword" label={t("profile.psswrd.p1")} type="password" isRequired />
                                    <MyTextInput name="password" label={t("profile.psswrd.p2")} type="password" isRequired />
                                    <MyTextInput name="confirmPassword" label={t("profile.psswrd.p3")} type="password" isRequired mb="10" />

                                    <Button
                                        disabled={!isValid || !dirty || isSubmitting}
                                        isLoading={isSubmitting}
                                        variant="primary"
                                        type="submit"
                                        mb="6"
                                        isFullWidth
                                    >
                                        {t("save,bttn")}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Flex>
                ) : (
                    <Text size="paragraph_normal">Last updated on 12/03/2021</Text>
                )}
            </Flex>
        </Flex>
    );
}
