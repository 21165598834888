import * as React from "react";
import { Flex, Text, Grid, GridProps } from "@chakra-ui/react";
import SmallBadge from "../../../app/components/smallBadge";
import { WhitelistedAddress } from "app/models/cryptoAccount";

interface Props extends GridProps {
  variant: "whitelisted" | "pending";
    address: WhitelistedAddress;
}

export default function WhitelistedAddressInfo({ variant, address, ...rest }: Props) {
  return (
    <Grid
      templateColumns="minmax(0,2fr) auto"
      alignItems="center"
      justifyItems="end"
      gap="4"
      _hover={{ bg: "gray.700" }}
      cursor={variant== "whitelisted" ? "pointer": "not-allowed"}
      py="3"
      px="2"
      borderRadius="0.25rem"
      maxW="100%"
      // overflow="hidden"
      {...rest}
    >
      <Flex direction="column" w="100%">
        <Text color="white" size="paragraph_semibold" mb="1">
          {address.label}
        </Text>
        <Text
          color="gray.500"
          size="smallText_normal"
          // whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {address.value}
        </Text>
      </Flex>
      {variant === "whitelisted" ? (
        <SmallBadge color="green.500">Whitelisted</SmallBadge>
      ) : (
        <SmallBadge color="yellow.800">Pending</SmallBadge>
      )}
    </Grid>
  );
}
