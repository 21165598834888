import { FlexProps, Button, Heading, Spinner } from "@chakra-ui/react";
import Notification from "app/components/notification";
import ReadOnlyField from "app/components/readOnlyField";
import { MessageType } from "app/models/message";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useEffect, useState } from "react";
import { FIATAccount } from "app/models/fiatAccount";
import { useTranslation } from "react-i18next";

export default observer(function DepositSummary(props: FlexProps) {
    // const [addressExists, setAddressExists] = React.useState(false);
    // const [showRon, setShowRon] = React.useState(false);
    // const [showDepositSummary, setShowDepositSummary] = React.useState(false);
    const [appIban, setAppIban] = useState("");
    const { t } = useTranslation();

    const {
        userStore: { user },
        adminStore: { loadFundingFees, fundingFees, getFundingFeeLabel, getMinFundingFee },
        profileStore: { accounts, loadProfile, profile },
        fundingRequestStore: { showSummary, loading, setShowSummary, fundingRequestFormValues, getAppAccountIban, createFundingRequest },
    } = useStore();

    useEffect(() => {
        if (fundingRequestFormValues?.currency) {
            getAppAccountIban(fundingRequestFormValues.currency?.toString()).then((iban) => {
                iban && setAppIban(iban);
            });
            if (fundingFees.length == 0) loadFundingFees();
        }
    }, [getAppAccountIban, loadFundingFees]);

    function getCode() {
        var acc = accounts?.find((x) => x.currency === fundingRequestFormValues?.currency) as FIATAccount;
        return acc.code || "";
    }

    // if (loading) return <LoadingComponent />;

    return (
        <>
            {loading ? (
                <Spinner color="yellow.500" size="xl" />
            ) : (
                <>
                    <Heading size="h4_semibold" mb="10">
                        {t("first.deposit.p3")} {fundingRequestFormValues?.amount} {fundingRequestFormValues?.currency} {t("deposit.in.acc")}
                    </Heading>
                    <ReadOnlyField value={fundingRequestFormValues?.additionalInfo!} label={t("unique.ref")} mb="4" />
                    <ReadOnlyField value={fundingRequestFormValues?.externalId!} label={t("sender.IBAN")} mb="4" />
                    <ReadOnlyField value={appIban} label={t("recipient.IBAN")} mb="4" />
                    <ReadOnlyField value="PROHASH SRL" label={t("recipient.name")} mb="4" />

                    <Notification
                        messageStyle={{ fontWeight: "normal" }}
                        message={
                            t("deposit.summary") +
                            `${fundingRequestFormValues?.currency && getFundingFeeLabel(fundingRequestFormValues?.currency, false,t("fee.msg"))}`
                        }
                        type={MessageType.Info}
                        bg="gray.700"
                        mb="10"
                    />

                    {fundingRequestFormValues?.currency &&
                        fundingRequestFormValues!.amount &&
                        getMinFundingFee(fundingRequestFormValues?.currency, false) >= fundingRequestFormValues!.amount && (
                            <Notification
                                messageStyle={{ fontWeight: "normal" }}
                                message={t("small.amount")}
                                type={MessageType.Error}
                                bg="gray.700"
                                mb="10"
                            />
                        )}

                    <Button
                        variant="primary"
                        isDisabled={
                            fundingRequestFormValues?.currency && fundingRequestFormValues!.amount
                                ? getMinFundingFee(fundingRequestFormValues?.currency, false) >= fundingRequestFormValues!.amount
                                : true
                        }
                        w="100%"
                        onClick={() => {
                            if (fundingRequestFormValues != null) {
                                // createFundingRequest({ accountId: account?.id!, isDebit: false, amount: amount });
                                createFundingRequest(fundingRequestFormValues!).then(() => loadProfile(user?.username!));
                            }
                            setShowSummary(false);
                        }}
                    >
                        {t("submit.request.bttn")}
                    </Button>
                </>
            )}
        </>
    );
});
