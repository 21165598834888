import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useState } from "react";
import {
    Flex,
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    Text,
    useBreakpointValue,
    IconButton,
    List,
    ListItem,
    Button,
    Switch,
    InputGroup,
    Input,
    InputRightElement,
    Spacer,
} from "@chakra-ui/react";
import Grid from "app/components/grid";
import { EmptyCheck } from "assets/icons/onboarding";
import { Remove } from "assets/icons/activity";
import { Account } from "assets/icons/navigation";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Profile, ProfileFilterParams } from "../../../app/models/profile";
import { useStore } from "../../../app/stores/store";
import { history } from "../../..";
import { getDocumentTitle } from "../../../app/models/document";
import { CurrencyLabel } from "app/models/rate";
import { getRecordNo, PagingParams } from "app/models/pagination";
import { Close } from "assets/icons/home";

export default observer(function UsersList() {
    const {
        adminStore: {
            loading,
            initialLoading,
            userPagination,
            setUserPagingParams,
            users,
            userFilterParams,
            setUserFilterParams,
            loadUsers,
            validateUser,
        },
        userStore: { isAuthorized },
    } = useStore();
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    const [target, setTarget] = useState("");
    const [loadingNext, setLoadingNext] = useState(false);
    const [kycValidated, setKycValidated] = useState<boolean>(false);
    const [userName, setUserName] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isAuthorized) {
            if (users.length < 1) {
                loadUsers();
            }
        }
        return () => {
            setUserFilterParams(new ProfileFilterParams());
        };
    }, [isAuthorized, loadUsers, setUserFilterParams]);

    function handleValidateUser(user: Profile, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        validateUser(user.email);
    }

    function handleEditUser(user: Profile, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        history.push(`/admin/profiles/${user.username}`);
    }

    // function getRecordNo() {
    //     return Math.min(userPagination!.currentPage * userPagination!.itemsPerPage, userPagination!.totalItems) + " of " + userPagination!.totalItems;
    // }

    function handleGetNext() {
        setLoadingNext(true);
        setUserPagingParams(new PagingParams(userPagination!.currentPage + 1));
        loadUsers().then(() => setLoadingNext(false));
    }

    function applyFilterHandler() {
        var newFP = userFilterParams;
        newFP.userName = userName;
        newFP.kycValidated = kycValidated;
        setUserFilterParams(newFP);
    }

    if (initialLoading) return <LoadingComponent />;

    return (
        <Grid mt={{ base: "6", lg: "10" }} pos="relative">
            <Heading mb="6" color="white" gridColumn="1/-1" size={headingSize}>
                Users
            </Heading>

            <Flex gridColumn={{ base: "1/-1" }} mb="3">
                <Flex align="center" justify="flex-start">
                    <InputGroup mr="3">
                        <Input variant="filled" placeholder="Search..." value={userName} onChange={(e) => setUserName(e.target.value)} />
                        <InputRightElement my="auto" bottom="0" cursor="pointer" onClick={() => setUserName("")}>
                            {userName ? <Close w="16px" h="16px" color="white" /> : null}
                        </InputRightElement>
                    </InputGroup>
                    <Switch colorScheme="yellow" isChecked={kycValidated} onChange={() => setKycValidated(!kycValidated)} />
                    <Text as="span" ml="3" color="white" size="paragraph_semibold" whiteSpace="nowrap" mr="3">
                        KYC Validated
                    </Text>
                </Flex>
                <Spacer />
                <Button
                    variant="primary"
                    onClick={() => {
                        applyFilterHandler();
                    }}
                >
                    Filter
                </Button>
            </Flex>
            <Flex
                gridColumn={{ base: "1/-1" }}
                mb={{ base: "6", lg: "0" }}
                direction="column"
                justify="center"
                py="3"
                bg="gray.800"
                borderRadius="0.75rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.700"
                overflowX="auto"
            >
                {users && users.length > 0 && (
                    <>
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr key="0">
                                    <Th color="gray.500">Actions</Th>
                                    <Th color="gray.500">Name</Th>
                                    <Th color="gray.500">Login</Th>
                                    <Th color="gray.500">Accounts</Th>
                                    <Th color="gray.500">Documents</Th>
                                </Tr>
                            </Thead>

                            <Tbody color="white" bg="gray.900">
                                {users.map((user, i) => (
                                    <Tr key={i} color={user.kycValidated ? "green.800" : "white"}>
                                        <Td>
                                            <Flex direction="row">
                                                <Tooltip label="Validate">
                                                    <IconButton
                                                        aria-label="Validate"
                                                        mr="2"
                                                        colorScheme="gray.800"
                                                        icon={<EmptyCheck w="20px" h="20px" color="green.800" />}
                                                        name={"validate" + user.email}
                                                        isDisabled={user.kycValidated}
                                                        isLoading={target === "validate" + user.email && loading}
                                                        onClick={(e) => handleValidateUser(user, e)}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Profile">
                                                    <IconButton
                                                        aria-label="Profile"
                                                        mr="2"
                                                        colorScheme="gray.800"
                                                        icon={<Account w="20px" h="20px" />}
                                                        name={"edit" + user.email}
                                                        isLoading={target === "edit" + user.email && loading}
                                                        onClick={(e) => handleEditUser(user, e)}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                        <Td>{user.displayName}</Td>
                                        <Td>
                                            {user.username}
                                            {user.kycValidated ? <EmptyCheck w="10px" h="10px" ml="1" /> : <Remove w="10px" h="10px" ml="1" />}
                                        </Td>
                                        <Td>
                                            <List>
                                                {user.fiatAccounts?.map((acc) => (
                                                    <ListItem key={acc.iban}>
                                                        {acc.iban} -{" "}
                                                        <b>
                                                            {CurrencyLabel[acc.currency]}
                                                            {acc.validated ? (
                                                                <EmptyCheck w="10px" h="10px" ml="1" />
                                                            ) : (
                                                                <Remove w="10px" h="10px" ml="1" />
                                                            )}
                                                        </b>
                                                    </ListItem>
                                                ))}
                                                {user.cryptoAccounts?.map((acc) => (
                                                    <ListItem key={acc.iban}>
                                                        {acc.iban} - <b>{CurrencyLabel[acc.currency]}</b>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Td>

                                        <Td>
                                            <List>
                                                {user.documents?.map((doc) => (
                                                    <ListItem key={doc.id}>
                                                        {doc.name} -{" "}
                                                        <b>
                                                            {getDocumentTitle(doc.type)}
                                                            {doc.validated ? (
                                                                <EmptyCheck w="10px" h="10px" ml="1" />
                                                            ) : (
                                                                <Remove w="10px" h="10px" ml="1" />
                                                            )}
                                                        </b>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        {userPagination && userPagination!.totalPages > 1 && (
                            <Flex align="center" direction="row" justify="end" mt="4">
                                <Text size="smallText_semibold" color="gray.300" mr="3">
                                    Displayed {getRecordNo(userPagination)} users
                                </Text>
                                <Button
                                    variant="primary"
                                    isLoading={loadingNext}
                                    isDisabled={!(!loadingNext && !!userPagination && userPagination.currentPage < userPagination.totalPages)}
                                    onClick={handleGetNext}
                                    mr="3"
                                >
                                    Display more
                                </Button>
                            </Flex>
                        )}
                    </>
                )}
            </Flex>
        </Grid>
    );
});
