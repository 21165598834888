import { Flex, Heading, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useBreakpointValue, IconButton, Button, Text } from "@chakra-ui/react";
import Grid from "app/components/grid";
import {
    getTransactionAmount,
    getTransactionStatusLabel,
    getTransactionTypeLabel,
    LedgerFilterParams,
    Transaction,
    TransactionStatus,
    TransactionType,
} from "app/models/transaction";
import { getRecordNo, PagingParams } from "app/models/pagination";
import { Bin } from "assets/icons/activity";
import { EmptyCheck } from "assets/icons/onboarding";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useState } from "react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";

export default observer(function FundingList() {
    const {
        adminStore: {
            loading,
            initialLoading,
            transactions,
            setTxFilterParams,
            txPagination,
            setTxPagingParams,
            loadFundingRequests,
            processFundingRequest,
            cancelFundingRequest,
        },
        userStore: { isAuthorized },
    } = useStore();
    const [target, setTarget] = useState("");
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    const [loadingNext, setLoadingNext] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isAuthorized) {
            if (transactions.length < 1) loadFundingRequests();
        }
        return () => {
            setTxFilterParams(new LedgerFilterParams());
        };
    }, [loadFundingRequests, setTxFilterParams]);

    function handleProcessRequest(req: Transaction, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        processFundingRequest(req.id);
    }

    function handleCancelRequest(req: Transaction, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        cancelFundingRequest(req.id);
    }

    function handleGetNext() {
        setLoadingNext(true);
        setTxPagingParams(new PagingParams(txPagination!.currentPage + 1));
        loadFundingRequests().then(() => setLoadingNext(false));
    }

    if (initialLoading) return <LoadingComponent />;

    return (
        <Grid mt={{ base: "6", lg: "10" }} pos="relative">
            <Heading mb="6" color="white" gridColumn="1/-1" size={headingSize}>
                Funding Requests
            </Heading>

            <Flex
                gridColumn={{ base: "1/-1" }}
                mb={{ base: "6", lg: "0" }}
                direction="column"
                justify="center"
                py="3"
                bg="gray.800"
                borderRadius="0.75rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.700"
                overflowX="auto"
            >
                {transactions && transactions.length > 0 && (
                    <>
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    <Th color="gray.500">Actions</Th>
                                    <Th color="gray.500">Creation Date</Th>
                                    <Th color="gray.500">Owner</Th>
                                    <Th color="gray.500">Status</Th>
                                    <Th color="gray.500">Type</Th>
                                    <Th color="gray.500">External reference</Th>
                                    <Th color="gray.500" isNumeric>
                                        Amount
                                    </Th>
                                </Tr>
                            </Thead>

                            <Tbody color="white" bg="gray.900">
                                {transactions.map((fundingRequest) => (
                                    <Tr
                                        key={fundingRequest.id}
                                        disabled={fundingRequest.status == TransactionStatus.Canceled}
                                        color={fundingRequest.status == TransactionStatus.Confirmed ? "green.800" : "white"}
                                    >
                                        <Td>
                                            <Flex direction="row">
                                                <Tooltip label="Approve request">
                                                    <IconButton
                                                        aria-label="Approve request"
                                                        mr="2"
                                                        colorScheme="gray.800"
                                                        icon={<EmptyCheck w="20px" h="20px" color="green.800" />}
                                                        name={"Approve" + fundingRequest.id}
                                                        isDisabled={!(fundingRequest.status == TransactionStatus.Pending)}
                                                        isLoading={target === "Approve" + fundingRequest.id && loading}
                                                        onClick={(e) => handleProcessRequest(fundingRequest, e)}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Cancel request">
                                                    <IconButton
                                                        aria-label="Cancel request"
                                                        colorScheme="gray.500"
                                                        icon={<Bin w="20px" h="20px" />}
                                                        isDisabled={!(fundingRequest.status == TransactionStatus.Pending)}
                                                        isLoading={target === fundingRequest.id && loading}
                                                        onClick={(e) => handleCancelRequest(fundingRequest, e)}
                                                        name={fundingRequest.id}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                        <Td>{format(fundingRequest.date, "dd MMM yyyy h:mm aa")}</Td>
                                        <Td>
                                            <a href={`/admin/profiles/${fundingRequest.ownerName}`} title="Go to Profile">
                                                {fundingRequest.ownerName}
                                            </a>
                                        </Td>
                                        <Td>{t(getTransactionStatusLabel(fundingRequest.status).toLowerCase())}</Td>
                                        <Td>{t(getTransactionTypeLabel(fundingRequest.type).toLowerCase())}</Td>
                                        <Td overflow="hidden" textOverflow="ellipsis" maxWidth="15em">
                                            {fundingRequest.details}
                                            {/* {fundingRequest.additionalInfo} */}
                                        </Td>
                                        <Td isNumeric>
                                            {getTransactionAmount(fundingRequest, fundingRequest.type == TransactionType.Deposit)}{" "}
                                            {/* {getTransactionCurrency(fundingRequest, fundingRequest.type == TransactionType.Deposit)} */}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        {txPagination && txPagination!.totalPages > 1 && (
                            <Flex align="center" direction="row" justify="end" mt="4">
                                <Text size="smallText_semibold" color="gray.300" mr="3">
                                    Displayed {getRecordNo(txPagination)} users
                                </Text>
                                <Button
                                    variant="primary"
                                    isLoading={loadingNext}
                                    isDisabled={!(!loadingNext && !!txPagination && txPagination.currentPage < txPagination.totalPages)}
                                    onClick={handleGetNext}
                                    mr="3"
                                >
                                    Display more
                                </Button>
                            </Flex>
                        )}
                    </>
                )}
            </Flex>
        </Grid>
    );
});
