import { createContext, useContext } from "react";
import AdminStore from "./adminStore";
import CommonStore from "./commonStore";
import ExchangeStore from "./exchangeStore";
import FundingRequestStore from "./fundingRequestStore";
import KrakenStore from "./krakenStore";
import LedgerStore from "./ledgerStore";
import ModalStore from "./modalStore";
import ProfileStore from "./profileStore";
import ReportsStore from "./reportsStore";
import UserStore from "./userStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    profileStore: ProfileStore;
    krakenStore: KrakenStore;
    fundingRequestStore: FundingRequestStore;
    exchangeStore: ExchangeStore;
    adminStore: AdminStore;
    ledgerStore: LedgerStore;
    reportsStore: ReportsStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    profileStore: new ProfileStore(),
    krakenStore: new KrakenStore(),
    fundingRequestStore: new FundingRequestStore(),
    exchangeStore: new ExchangeStore(),
    adminStore: new AdminStore(),
    ledgerStore: new LedgerStore(),
    reportsStore: new ReportsStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}