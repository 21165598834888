import * as React from "react";
import Grid from "app/components/grid";
import { useBreakpointValue, Text, Heading, Flex, FlexProps } from "@chakra-ui/react";
import { Star } from "assets/icons/landing";
import ResponsiveImage from "app/components/responsiveImage/ResponsiveImage";
import { useTranslation } from "react-i18next";

interface StepProps extends FlexProps {
    src: string;
    headerText: string;
    detailsText: string;
}

function Step({ src, headerText, detailsText, ...rest }: StepProps) {
    const smallHeaderSize = useBreakpointValue({
        base: "paragraph_bold",
        lg: "h4_bold",
    });
    return (
        <Flex
            justify="center"
            direction="column"
            align="center"
            p="6"
            borderRadius="0.75rem"
            backgroundImage="linear-gradient(180deg, var(--chakra-colors-gray-700) -177%, var(--chakra-colors-black) 100%)"
            {...rest}
        >
            <ResponsiveImage minW="100px" minH="100px" w="0" h="0" alt="" src={src} mb="6" />
            <Text size={smallHeaderSize} color="white" mb="4" textAlign="center">
                {headerText}
            </Text>
            <Text size="paragraph_normal" color="gray.500" mb="10" textAlign="center">
                {detailsText}
            </Text>
        </Flex>
    );
}

export default function Steps() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();
    return (
        <Grid pb={{ base: 10, md: 20, lg: 28 }}>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                color="white"
                gridColumn="1/-1"
                justify="center"
                mt={{ base: "10", md: "20", lg: "28" }}
                mb="10"
            >
                <Heading size={headerSize} textAlign={{ base: "center", md: "left" }}>
                    {t("landing.p3")}
                </Heading>
                <Star order={{ base: -1, md: 0 }} mb={{ base: 3, md: 0 }} w="40px" h="40px" ml={{ base: "0", md: "3", lg: "4" }} />
            </Flex>
            <Step
                src="landing/account"
                headerText={t("landing.p4")}
                detailsText={t("landing.p4.1")}
                gridColumn={{ base: "1/-1", md: "1 / span 4" }}
                mb={{ base: 6, sm: 10, md: 0 }}
            />
            <Step
                src="landing/bank"
                headerText={t("landing.p5")}
                detailsText={t("landing.p5.1")}
                gridColumn={{ base: "1/-1", md: "5 / span 4" }}
                mb={{ base: 6, sm: 10, md: 0 }}
            />
            <Step src="landing/check" headerText={t("landing.p6")} detailsText={t("landing.p6.1")} gridColumn={{ base: "1/-1", md: "9 / span 4" }} />
        </Grid>
    );
}
