import * as React from "react";
import { Flex, FlexProps, Heading, Button, Text, Divider, PinInput, PinInputField, Spinner } from "@chakra-ui/react";
import { useStore } from "app/stores/store";
import ReadOnlyField from "app/components/readOnlyField";
import QRCode from "qrcode.react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default observer(function ProfileTFAForm(props: FlexProps) {
    const {
        userStore: { user, loading, loadTFAKey, TFAConfirmSetup, disableTFA, TFAKey },
    } = useStore();
    const [TFACode, setTFACode] = React.useState("");
    const { t } = useTranslation();
    // const [TFAKey, setTFAKey] = React.useState("");

    // useEffect(() => {
    //     console.log("ue");
    //     if (!user!.tfaGoogleEnabled)
    //         loadTFAKey(); //.then((key) => {if (key != undefined) setTFAKey(key);});ESEJR6C66DNYJJYD
    // }, [user!.tfaGoogleEnabled]);

    return (
        <Flex color="white" direction="column" {...props}>
            <Flex justify="space-between" align="center" w="100%" mb="6">
                <Heading size="h4_bold">{t("2FA.header")}</Heading>
            </Flex>
            <Flex
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                py={{ base: "6", sm: "8" }}
                px={{ base: "4", sm: "8" }}
            >
                {!user!.tfaGoogleEnabled ? (
                    <Flex direction="column" w="100%">
                        <Text size="smallText_normal" mb="6">
                            {t("2FA.p1")}
                        </Text>
                        <Text size="smallText_normal" mb="6">
                            {t("2FA.p2")}
                        </Text>
                        <Flex
                            borderRadius="0.75rem"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="gray.700"
                            bg="gray.800"
                            py={{ base: "6", sm: "8" }}
                            px={{ base: "4", sm: "8" }}
                            direction="column"
                        >
                            {loading ? (
                                <Spinner alignContent={"center"} color="yellow.500" size="xl" />
                            ) : (
                                TFAKey && (
                                    <>
                                        <Flex justify="center" mb="4" w="100%">
                                            <QRCode
                                                value={`otpauth://totp/Prohash:${user!.username}?secret=${TFAKey}&issuer=Prohash`}
                                                includeMargin={true}
                                                size={200}
                                            />
                                        </Flex>
                                        <ReadOnlyField label="Key" value={TFAKey} />
                                    </>
                                )
                            )}
                        </Flex>
                        <Divider borderColor="gray.700" my="6" />
                        <Text size="smallText_normal" mb="6">
                            {t("2FA.p3")}
                        </Text>
                        <Flex
                            borderRadius="0.75rem"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="gray.700"
                            bg="gray.800"
                            py={{ base: "6", sm: "8" }}
                            px={{ base: "4", sm: "8" }}
                            direction="column"
                            mb="10"
                        >
                            <Flex sx={{ gap: "8px" }}>
                                <PinInput otp placeholder="•" onChange={(value) => setTFACode(value)}>
                                    <PinInputField w="100%" />
                                    <PinInputField w="100%" />
                                    <PinInputField w="100%" />
                                    <PinInputField w="100%" />
                                    <PinInputField w="100%" />
                                    <PinInputField w="100%" />
                                </PinInput>
                            </Flex>
                        </Flex>

                        <Button
                            w="100%"
                            variant="primary"
                            isDisabled={TFACode.length != 6 || TFAKey === null}
                            onClick={() =>
                                TFAConfirmSetup({
                                    Key: TFAKey!,
                                    Code: TFACode,
                                })
                            }
                        >
                            {t("enable.bttn")}
                        </Button>
                    </Flex>
                ) : (
                    <Flex direction="column" w="100%">
                        <Text size="paragraph_normal" mb="6">
                            {t("disable.info")}
                        </Text>
                        <Button w="100%" variant="primary" onClick={() => disableTFA()}>
                            {t("disable.bttn")}
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
});
