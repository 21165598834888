import { Box, BoxProps, Flex, Menu, MenuButton, Text, Divider, Image, useBreakpointValue, MenuList, MenuItem } from "@chakra-ui/react";
import { Dots } from "assets/icons/home";
import { Asset } from "app/models/asset";
import { CurrencyLabel } from "app/models/rate";
import { history } from "../..";
import { useStore } from "app/stores/store";
import { FundingType } from "app/models/fundingRequest";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
    asset: Asset;
}

export default function Account({ asset, ...rest }: Props) {
    const currencySize = useBreakpointValue({
        base: "smallText_semibold",
        sm: "paragraph_semibold",
    });
    const symbolSize = useBreakpointValue({
        base: "caption_normal",
        sm: "smallText_normal",
    });

    const amountSize = useBreakpointValue({
        base: "paragraph_bold",
        sm: "h4_bold",
    });
    const {
        fundingRequestStore: { setFundingType, setAsset },
    } = useStore();

    const { t } = useTranslation();
    return (
        <Box border="1px solid gray.700" borderRadius="0.75rem" bg="gray.800" p="6" color="white" {...rest}>
            <Flex justify="space-between" align="flex-start">
                <Flex align="center">
                    <Image
                        src={"/coins/" + asset.currency.toLowerCase() + ".svg"}
                        alt={asset.currency}
                        w={{ base: "32px", sm: "40px" }}
                        h={{ base: "32px", sm: "40px" }}
                    />
                    <Flex direction="column" ml={{ base: "2", sm: "4" }} minW="0">
                        <Text size={currencySize} mb="1" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {CurrencyLabel[asset.currency]}
                        </Text>
                        <Text size={symbolSize} color="gray.500" textTransform="uppercase">
                            {asset.currency}
                        </Text>
                    </Flex>
                </Flex>
                <Menu>
                    <MenuButton p="3" m="-3" bg="transparent" _hover={{ bg: "transparent" }} _focus={{ bg: "transparent" }}>
                        <Dots w="16px" h="16px" />
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => history.push(`/exchange`)}>{t("trade")}</MenuItem>
                        <MenuItem
                            onClick={() => {
                                setFundingType(FundingType.Deposit);
                                setAsset(asset);
                                history.push(`/funding`);
                            }}
                        >
                            {t("deposit")}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setFundingType(FundingType.Withdraw);
                                setAsset(asset);
                                history.push(`/funding`);
                            }}
                        >
                            {t("widthdraw")}
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
            <Divider borderColor="gray.700" my={{ base: "3", sm: "4" }} />
            <Flex align="center" color={asset.balance > 0 ? "white" : "gray.500"}>
                {/* <Text size={amountSize}>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(asset.balance)}</Text> */}
                <Text size={amountSize}>{asset.balance}</Text>
                <Text size="paragraph_normal" ml="2" textTransform="uppercase">
                    {asset.currency}
                </Text>
            </Flex>
        </Box>
    );
}
