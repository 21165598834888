import { List, ListItem, Text } from '@chakra-ui/react';


interface Props {
    errors: any;
}

export default function ValidationErrors({errors}: Props) {
    return (
        <Text>
            {errors && (
                <List>
                    {errors.map((err: any, i: any) => (
                        <ListItem key={i}>{err}</ListItem>
                    ))}
                </List>
            )}
        </Text>
    )
}