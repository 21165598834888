import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
    getTransactionAmount,
    getTransactionFee,
    getTransactionRate,
    getTransactionStatusLabel,
    getTransactionTypeLabel,
    LedgerFilterParams,
    Transaction,
} from "../models/transaction";
import { Pagination, PagingParams } from "../models/pagination";
import { format } from "date-fns";
import { BalanceDto, BalanceFilterParams } from "app/models/balanceDto";

export default class ReportsStore {
    // transactionRegistry = new Map<string, Transaction>();
    loading = false;
    transactions: Transaction[] = [];
    pagination: Pagination | undefined = undefined;
    pagingParams = new PagingParams();
    filterParams = new LedgerFilterParams();
    feeBalances: BalanceDto[] = [];
    feeBalanceFilterParams = new BalanceFilterParams();
    customerBalances: BalanceDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    };

    setFilterParams = (filterParams: LedgerFilterParams, reLoad: boolean = true) => {
        this.filterParams = filterParams;
        this.pagingParams = new PagingParams(1, this.pagingParams.pageSize);
        this.transactions = [];
        // this.clearTransactions();
        if (reLoad) this.loadTransactions();
    };

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    };

    // getFilterLabel = () => {
    //     var label = "";
    //     if (this.filterParams) {
    //         // if (this.filterParams.iban) label += "IBAN = " + this.filterParams.iban + "; ";
    //         if (this.filterParams.type) {
    //             label += "Transaction Type = ";
    //             this.filterParams.type.forEach((item) => (label += " " + getTransactionTypeLabel(Number.parseInt(item))));
    //             label += "; ";
    //         }
    //         if (this.filterParams.startDate) label += "Start Date = " + format(this.filterParams.startDate, "dd MMM yyyy h:mm aa") + "; ";
    //         if (this.filterParams.endDate) {
    //             var myDate = new Date(this.filterParams.endDate);
    //             myDate.setDate(myDate.getDate() + 1);
    //             label += "End Date = " + format(myDate, "dd MMM yyyy h:mm aa") + "; ";
    //         }
    //         if (this.filterParams.currency) label += " Currency = " + this.filterParams.currency + "; ";
    //         if (this.filterParams.status) {
    //             label += " Status =";
    //             this.filterParams.status.forEach((item) => (label += " " + getTransactionStatusLabel(Number.parseInt(item))));
    //         }
    //     }
    //     return label;
    // };

    loadTransactions = async () => {
        this.loading = true;
        try {
            var params = new URLSearchParams();
            params.append("pageNumber", "0");
            // params.append("pageSize", this.pagingParams.pageSize.toString());

            if (this.filterParams) {
                if (this.filterParams.iban) params.append("IBAN", this.filterParams.iban);
                if (this.filterParams.startDate) params.append("startDate", this.filterParams.startDate.toISOString());
                if (this.filterParams.endDate) {
                    var myDate = new Date(this.filterParams.endDate);
                    myDate.setDate(myDate.getDate() + 1);
                    params.append("endDate", myDate.toISOString());
                }
                if (this.filterParams.type) {
                    this.filterParams.type.forEach((item) => params.append("type", item));
                }
                if (this.filterParams.currency) this.filterParams.currency.forEach((item) => params.append("currency", item));
                if (this.filterParams.status) this.filterParams.status.forEach((item) => params.append("status", item));
            }
            const result = await agent.Transactions.listAll(params);
            runInAction(() => {
                result.data.forEach((tx) => {
                    tx.date = new Date(tx.date + "Z");
                });
                this.transactions = result.data;
                this.setPagination(result.pagination);
                //console.log(result);
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    getTxsCSV = (delimiter: string = ",") => {
        let csv =
            ["Creation Date", "Owner", "Type", "Status", "Positive Amount", "Currency", "Negative Amount","Currency", "Fee", "Currency", "Rate", "External reference"].join(delimiter) +
            "\r\n";
        if (this.transactions.length < 1) csv += "no data";
        else {
            var data = this.transactions.map((tx) =>
                [
                    format(tx.date, "dd MMM yyyy h:mm aa"),
                    tx.ownerName,
                    getTransactionTypeLabel(tx.type),
                    getTransactionStatusLabel(tx.status),
                    getTransactionAmount(tx, true).split(" ").join(delimiter),
                    getTransactionAmount(tx, false).split(" ").join(delimiter),
                    getTransactionFee(tx, false).toString().split(" ").join(delimiter),
                    getTransactionRate(tx),
                    tx.details,
                ].join(delimiter)
            );
            csv += data.join("\r\n");
        }

        const blob = new Blob([csv], {
            type: "text/plain;charset=utf-8",
        });
        return blob;
    };

    setFeeBalanceFilterParams = (filterParams: BalanceFilterParams, reLoad: boolean = true) => {
        this.feeBalanceFilterParams = filterParams;
        this.feeBalances = [];
        // this.clearTransactions();
        if (reLoad) this.loadFeeBalances();
    };

    loadFeeBalances = async () => {
        this.loading = true;
        try {
            var params = new URLSearchParams();
            if (this.feeBalanceFilterParams) {
                if (this.feeBalanceFilterParams.startDate) params.append("startDate", this.feeBalanceFilterParams.startDate.toISOString());
                if (this.feeBalanceFilterParams.endDate) {
                    var myDate = new Date(this.feeBalanceFilterParams.endDate);
                    myDate.setDate(myDate.getDate() + 1);
                    params.append("endDate", myDate.toISOString());
                }
            }
            //console.log(params.toString());
            const result = await agent.Reports.feeTotalBalances(params);
            runInAction(() => {
                this.feeBalances = result.sort((a, b) => a.currency.localeCompare(b.currency));
            });
        } catch (error) {
            //console.log(error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    loadCustomerBalances = async () => {
        this.loading = true;
        try {
            const result = await agent.Reports.customerTotalBalances();
            runInAction(() => {
                this.customerBalances = result.sort((a, b) => a.currency.localeCompare(b.currency));
            });
        } catch (error) {
            //console.log(error);
        } finally {
            runInAction(() => (this.loading = false));
        }
    };

    clearBalances = () => {
        this.customerBalances = [];
        // this.feeBalances = [];
    };
}
