import { useField } from "formik";
import React from "react";
import { FormControl, FormLabel, SelectProps, Select, FormErrorMessage, FormControlProps } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";

interface Props extends FormControlProps {
    placeholder?: string;
    name: string;
    options: { text: string; value: string }[];
    label: string;
    required?: boolean;
    // disabled?: boolean;
    hasNone?: boolean;
    autoFocus?: boolean;
    inputProps?: SelectProps;
}

export default function MySelectInput({ options, name, label, placeholder, inputProps, hasNone, ...rest }: Props) {
    const [field, meta, helpers] = useField(name);
    return (
        <FormControl mb="4" {...rest} isInvalid={meta.touched && !!meta.error}>
            {label && <FormLabel>{label}</FormLabel>}
            {/* <label>{props.label}</label>
            <Select 
                clearable
                options={props.options}
                fluid
                value={field.value || null || false}
                onChange={(e, d) => helpers.setValue(d.value)}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null} */}
            <Select
                {...field}
                {...inputProps}
                // fullWidth
                value={field.value || null || false}
                onChange={(e) => helpers.setValue(e.target.value)}
                onBlur={() => helpers.setTouched(true)}
                // SelectProps={{
                //     native: true,
                // }}
                // helperText={meta.touched && meta.error}
                // error={meta.touched && !!meta.error}
                variant="flushed"
        //         bg="black" size="lg"
        //         borderRadius= "0.5rem"
        // borderStyle= "solid"
        // borderWidth= "1px"
        // borderColor= "black"
                // color="white"
                icon={<ArrowDown my="auto" width="16px" h="16px" color="white.100" ml="2"/>}
            >
                {!!!hasNone && <option key="none" aria-label="None" value="" />}
                {options.map((option) => (
                    <option key={option.text} value={option.value}>
                        {option.text}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>{meta.touched && !!meta.error ? meta.error : ""}</FormErrorMessage>
        </FormControl>
    );
}
