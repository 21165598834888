import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Clock = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="m11.423 10.54-3.09-2.833V4.333a.667.667 0 1 0-1.333 0V8a.667.667 0 0 0 .216.492l3.306 3.03a.673.673 0 0 0 .942-.04.667.667 0 0 0-.041-.942z"
      fill="currentColor"
    />
  </Icon>
);

export const Close = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M1.834 13.033a.8.8 0 0 0 1.132 1.131l4.94-4.938a.133.133 0 0 1 .188 0l4.94 4.94a.798.798 0 0 0 1.304-.26.8.8 0 0 0-.173-.872l-4.939-4.94a.133.133 0 0 1 0-.19l4.94-4.939a.8.8 0 0 0-1.133-1.13L8.094 6.771a.133.133 0 0 1-.189 0l-4.94-4.938a.8.8 0 0 0-1.13 1.131l4.938 4.94a.133.133 0 0 1 0 .188l-4.939 4.94z"
      fill="currentColor"
    />
  </Icon>
);

export const Search = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="m19.487 18.016-5.441-5.44a7.736 7.736 0 1 0-1.473 1.473l5.44 5.44a1.055 1.055 0 0 0 1.474 0 1.043 1.043 0 0 0 0-1.473zM2.292 7.916a5.625 5.625 0 1 1 5.625 5.626 5.632 5.632 0 0 1-5.625-5.625z"
      fill="currentColor"
    />
  </Icon>
);

export const Dots = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M7.498 3.33a1.665 1.665 0 1 0 0-3.33 1.665 1.665 0 0 0 0 3.33zM7.498 9.66a1.665 1.665 0 1 0 0-3.33 1.665 1.665 0 0 0 0 3.33zM7.498 15.99a1.665 1.665 0 1 0 0-3.33 1.665 1.665 0 0 0 0 3.33z"
      fill="#9EA1A9"
    />
  </Icon>
);

export const Copy = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M14.375 16.458H5.208a.416.416 0 0 1-.416-.417V5.208a.833.833 0 1 0-1.667 0v11.25a1.667 1.667 0 0 0 1.667 1.667h9.583a.834.834 0 0 0 0-1.667z"
      fill="currentColor"
    />
    <path
      d="m16.658 4.423-2.37-2.333a.736.736 0 0 0-.521-.217H6.78a.738.738 0 0 0-.738.738v11.764a.738.738 0 0 0 .738.738h9.357a.738.738 0 0 0 .738-.738v-9.43a.738.738 0 0 0-.217-.522z"
      fill="currentColor"
    />
  </Icon>
);

export const Loader = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M10 5.417a1.25 1.25 0 0 0 1.25-1.25v-2.5a1.25 1.25 0 0 0-2.5 0v2.5A1.25 1.25 0 0 0 10 5.417zM6.893 5.089 5.124 3.32a1.283 1.283 0 0 0-1.767 0 1.25 1.25 0 0 0 0 1.768l1.768 1.767a1.242 1.242 0 0 0 .884.365 1.25 1.25 0 0 0 .884-2.132zM.417 10a1.25 1.25 0 0 0 1.25 1.25h2.5a1.25 1.25 0 0 0 0-2.5h-2.5A1.25 1.25 0 0 0 .417 10zM5.798 12.937a1.24 1.24 0 0 0-.884.365L3.146 15.07a1.25 1.25 0 0 0 1.77 1.764l1.77-1.769a1.25 1.25 0 0 0-.885-2.132l-.003.004zM8.75 18.334a1.25 1.25 0 0 0 2.5 0v-2.5a1.25 1.25 0 1 0-2.5 0v2.5zM13.374 13.339a1.25 1.25 0 0 0 0 1.767l1.769 1.768a1.253 1.253 0 1 0 1.774-1.77l-1.773-1.77a1.25 1.25 0 0 0-1.77.005zM19.583 10a1.25 1.25 0 0 0-1.25-1.25h-2.5a1.25 1.25 0 0 0 0 2.5h2.5a1.25 1.25 0 0 0 1.25-1.25zM15.87 2.842a1.24 1.24 0 0 0-.883.365L13.22 4.974a1.25 1.25 0 0 0 .884 2.135c.331 0 .65-.132.884-.366l1.767-1.767a1.25 1.25 0 0 0-.885-2.134z"
      fill="currentColor"
    />
  </Icon>
);
