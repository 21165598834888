import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import * as Yup from "yup";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { FundingFee } from "../../../app/models/fundingFee";
import { getCurrencyOptions } from "../../../app/models/rate";
import { FundingType } from "../../../app/models/fundingRequest";
import { Regx } from "../../../app/common/util/regx";
import MySwitch from "../../../app/common/form/MySwitch";

interface Props {
    setAddFundingFeeMode: (editMode: boolean) => void;
    selectedFee: FundingFee | undefined;
}

export default observer(function FundingFeeForm({ setAddFundingFeeMode, selectedFee }: Props) {
    const {
        adminStore: { createFundingFee, updateFundingFee },
    } = useStore();
    const isNew = selectedFee == null;
    const fundingFee = selectedFee == null ? new FundingFee() : selectedFee;
    // const [fundingFee, setFundingFee] = useState<FundingFee>(selectedFee == null ? new FundingFee() : selectedFee);

    const validationSchema = Yup.object({
        currency: Yup.string().required("Currency is required"),
        // isDebit: Yup.boolean().required('Type is required'),
        value: Yup.number()
            .test("is-decimal", "Invalid number", (number) => Regx.Decimal2.test(number + ""))
            .required("Value is required"),
        minValue: Yup.number()
            .nullable()
            // .test(
            //     'is-decimal',
            //     'Invalid number',
            //     (number) => (Regx.Decimal8).test(number + "")
            // )
            .min(0, "Must be a positive number"),
    });

    function getTypeOptions() {
        return [
            { text: FundingType.Deposit, value: "false" },
            { text: FundingType.Withdraw, value: "true" },
        ];
    }

    function handleFormSubmit(fundingFee: FundingFee) {
        if (isNew) {
            createFundingFee(fundingFee).then(() => {
                setAddFundingFeeMode(false);
            });
        } else {
            updateFundingFee(fundingFee).then(() => {
                setAddFundingFeeMode(false);
            });
        }
    }

    return (
        <Formik
            initialValues={fundingFee}
            onSubmit={(values) => {
                handleFormSubmit(values);
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <MySelectInput options={getCurrencyOptions()} label="Currency" name="currency" isDisabled={!isNew} isRequired />
                    <MySwitch 
                        frontlabel={FundingType.Deposit.toString()}
                        backlabel={FundingType.Withdraw.toString()}
                        isChecked={fundingFee.isDebit}
                        name="isDebit"
                    />
                    <MyTextInput label="Percentage Value" name="value" isRequired />
                    <MyTextInput label="Minimum Value" name="minValue" mb="6"/>
                    <Button variant="primary" type="submit" isLoading={isSubmitting} isDisabled={!isValid || !dirty} isFullWidth>
                        Save
                    </Button>
                </Form>
            )}
        </Formik>
    );
});
