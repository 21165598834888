import { makeAutoObservable, runInAction } from "mobx";
import { MessageType } from "app/models/message";
import { showToast } from "app/components/toast/Toast";
import agent from "../api/agent";
import { GenericAccount } from "../models/fiatAccount";
import { Document, DocumentFilterParams } from "../../app/models/document";
import { Profile, ProfileFilterParams } from "../models/profile";
import { FundingFee } from "../models/fundingFee";
import { Currency, Rate } from "../models/rate";
import { Pagination, PagingParams } from "app/models/pagination";
import { LedgerFilterParams, Transaction, TransactionStatus, TransactionType } from "app/models/transaction";

export default class AdminStore {
    accounts: GenericAccount[] = [];
    fundingFees: FundingFee[] = [];
    rateFees: Rate[] = [];
    loading = false;
    initialLoading = false;
    users: Profile[] = [];
    userPagination: Pagination | undefined = undefined;
    userPagingParams = new PagingParams();
    userFilterParams = new ProfileFilterParams();
    documents: Document[] = [];
    docPagination: Pagination | undefined = undefined;
    docPagingParams = new PagingParams();
    docFilterParams = new DocumentFilterParams();
    transactions: Transaction[] = [];
    txPagination: Pagination | undefined = undefined;
    txPagingParams = new PagingParams();
    txFilterParams = new LedgerFilterParams();

    constructor() {
        makeAutoObservable(this);
    }

    loadAccounts = async () => {
        this.initialLoading = true;
        try {
            var result = await agent.AppAccounts.list();
            runInAction(() => {
                this.accounts = result.data;
                this.initialLoading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.initialLoading = true));
        }
    };

    loadDocuments = async () => {
        this.initialLoading = true;
        //console.log("loadDocuments");
        try {
            var params = new URLSearchParams();
            params.append("pageNumber", this.docPagingParams.pageNumber.toString());
            params.append("pageSize", this.docPagingParams.pageSize.toString());
            if (this.userFilterParams) {
                if (this.docFilterParams.name) params.append("name", this.docFilterParams.name);
                params.append("validated", this.docFilterParams.validated ? "true" : "false");
            }
            var result = await agent.Documents.listAll(params);
            const dox = result.data;
            runInAction(() => {
                dox.forEach((doc) => {
                    doc.creationDate = new Date(doc.creationDate + "Z");
                });
                this.documents.push(...dox);
                this.setDocPagination(result.pagination);
                this.initialLoading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.initialLoading = false));
        }
    };

    setDocPagination = (pagination: Pagination) => {
        this.docPagination = pagination;
    };

    setDocPagingParams = (pagingParams: PagingParams) => {
        this.docPagingParams = pagingParams;
    };

    setDocFilterParams = (filterParams: DocumentFilterParams) => {
        this.docFilterParams = filterParams;
        this.docPagingParams = new PagingParams();
        this.documents = [];
        this.loadDocuments();
    };

    deleteDocument = async (document: Document) => {
        this.loading = true;
        try {
            await agent.Documents.delete(document.id);
            runInAction(() => {
                this.documents = this.documents.filter((p) => p.id !== document.id);
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            //console.log(error);
        }
    };

    validateDocument = async (document: Document) => {
        this.loading = true;
        try {
            await agent.Documents.validate(document.id);
            runInAction(() => {
                var doc = this.documents.find((p) => p.id == document.id);
                if (doc) {
                    doc.validated = true;
                    showToast("validated", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            //console.log(error);
        }
    };

    loadFundingFees = async () => {
        this.initialLoading = true;
        try {
            var result = await agent.FundingFees.list();
            runInAction(() => {
                this.fundingFees = result;
                this.initialLoading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.initialLoading = true));
        }
    };

    createFundingFee = async (fundingFee: FundingFee) => {
        this.loading = true;
        try {
            const response = await agent.FundingFees.create(fundingFee);
            runInAction(() => {
                if (this.fundingFees) {
                    this.fundingFees?.unshift(fundingFee);
                    showToast("Funding Fee created.", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    updateFundingFee = async (fundingFee: FundingFee) => {
        this.loading = true;
        try {
            const response = await agent.FundingFees.update(fundingFee);
            runInAction(() => {
                if (this.fundingFees) {
                    //a bit ugly
                    this.fundingFees = this.fundingFees.filter((f) => !(f.currency === fundingFee.currency && f.isDebit === fundingFee.isDebit));
                    this.fundingFees?.unshift(fundingFee);
                    showToast("Funding Fee updated.", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    deleteFundingFee = async (fundingFee: FundingFee) => {
        this.loading = true;
        try {
            await agent.FundingFees.delete(fundingFee.currency, fundingFee.isDebit!);
            runInAction(() => {
                if (this.fundingFees) {
                    this.fundingFees = this.fundingFees.filter((f) => !(f.currency === fundingFee.currency && f.isDebit === fundingFee.isDebit));
                    showToast("Funding Fee deleted.", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    loadRateFees = async () => {
        this.initialLoading = true;
        try {
            var result = await agent.RateFees.list();
            runInAction(() => {
                this.rateFees = result;
                this.initialLoading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.initialLoading = true));
        }
    };

    createRateFee = async (rateFee: Rate) => {
        this.loading = true;
        try {
            const response = await agent.RateFees.create(rateFee);
            runInAction(() => {
                if (this.fundingFees) {
                    this.rateFees?.unshift(rateFee);
                    showToast("Funding Fee created.", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    updateRateFee = async (rateFee: Rate) => {
        this.loading = true;
        try {
            const response = await agent.RateFees.update(rateFee);
            runInAction(() => {
                if (this.fundingFees) {
                    //a bit ugly
                    this.rateFees = this.rateFees.filter((f) => !(f.fromCurrency === rateFee.fromCurrency && f.toCurrency === rateFee.toCurrency));
                    this.rateFees?.unshift(rateFee);
                    showToast("Funding Fee updated.", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    deleteRateFee = async (rateFee: Rate) => {
        this.loading = true;
        try {
            await agent.RateFees.delete(rateFee.fromCurrency, rateFee.toCurrency);
            runInAction(() => {
                if (this.fundingFees) {
                    this.rateFees = this.rateFees.filter((f) => !(f.fromCurrency === rateFee.fromCurrency && f.toCurrency === rateFee.toCurrency));
                    showToast("Funding Fee deleted.", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    loadFundingRequests = async () => {
        this.initialLoading = true;
        //console.log("loadFundingRequests");
        try {
            var params = new URLSearchParams();
            params.append("pageNumber", this.txPagingParams.pageNumber.toString());
            params.append("pageSize", this.txPagingParams.pageSize.toString());
            params.append("status", TransactionStatus.Pending.toString());
            params.append("type", TransactionType.Deposit.toString());
            params.append("type", TransactionType.Withdrawal.toString());


            // if (this.txFilterParams) {
            //     if (this.txFilterParams.iban) params.append("IBAN", this.txFilterParams.iban);
            //     if (this.txFilterParams.startDate) params.append("startDate", this.txFilterParams.startDate.toISOString());
            //     if (this.txFilterParams.endDate) {
            //         var myDate = new Date(this.txFilterParams.endDate);
            //         myDate.setDate(myDate.getDate() + 1);
            //         params.append("endDate", myDate.toISOString());
            //     }
            //     if (this.txFilterParams.type) {
            //         // params.append("Type", this.txFilterParams.type.toString());
            //         this.txFilterParams.type.forEach((item) => params.append("type", item));
            //     }
            //     if (this.txFilterParams.currency) this.txFilterParams.currency.forEach((item) => params.append("currency", item));
            //     if (this.txFilterParams.status) this.txFilterParams.status.forEach((item) => params.append("status", item));
            // }
            const result = await agent.Transactions.listAll(params);
            
            const requests = result.data;
            runInAction(() => {
                requests.forEach((req) => {
                    req.date = new Date(req.date + "Z");
                });
                this.transactions.push(...requests);
                this.setTxPagination(result.pagination);
                this.initialLoading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.initialLoading = false));
        }
    };

    setTxPagination = (pagination: Pagination) => {
        this.txPagination = pagination;
    };

    setTxPagingParams = (pagingParams: PagingParams) => {
        this.txPagingParams = pagingParams;
    };

    setTxFilterParams = (filterParams: LedgerFilterParams) => {
        this.txFilterParams = filterParams;
        this.txPagingParams = new PagingParams();
        this.transactions = [];
        this.loadFundingRequests();
    };

    processFundingRequest = async (txId: string) => {
        this.loading = true;
        try {
            const result = await agent.Transactions.process(txId);
            runInAction(() => {
                var request = this.transactions?.find((p) => p.id == txId);
                if (request) {
                    request.status = TransactionStatus.Confirmed;
                    showToast("Request processed", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    cancelFundingRequest = async (txId: string) => {
        this.loading = true;
        try {
            const result = await agent.Transactions.cancel(txId);
            runInAction(() => {
                var request = this.transactions?.find((p) => p.id == txId);
                if (request) {
                    request.status = TransactionStatus.Canceled;
                    showToast("Request canceled", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    loadUsers = async () => {
        this.initialLoading = true;
        //console.log("loadUsers");
        try {
            var params = new URLSearchParams();
            params.append("pageNumber", this.userPagingParams.pageNumber.toString());
            params.append("pageSize", this.userPagingParams.pageSize.toString());
            if (this.userFilterParams) {
                if (this.userFilterParams.userName) params.append("userName", this.userFilterParams.userName);
                // if (this.userFilterParams.kycValidated) 
                params.append("kycValidated", this.userFilterParams.kycValidated ? "true" : "false");
            }
            // params.append("kycValidated", "false");
            // params.append("userName", "bob");
            var result = await agent.Profiles.listAll(params);
            runInAction(() => {
                this.users.push(...result.data);
                this.setUserPagination(result.pagination);
                this.initialLoading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.initialLoading = true));
        }
    };

    setUserPagination = (pagination: Pagination) => {
        this.userPagination = pagination;
    };

    setUserPagingParams = (pagingParams: PagingParams) => {
        this.userPagingParams = pagingParams;
    };

    setUserFilterParams = (filterParams: ProfileFilterParams) => {
        this.userFilterParams = filterParams;
        this.userPagingParams = new PagingParams();
        this.users = [];
        this.loadUsers();
    };

    validateUser = async (email: string) => {
        this.loading = true;
        try {
            await agent.Account.validate(email);
            runInAction(() => {
                var user = this.users.find((p) => p.email == email);
                if (user) {
                    user.kycValidated = true;
                    showToast("User validated", MessageType.Success);
                }
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => (this.loading = false));
            //console.log(error);
        }
    };

    getFundingFeeLabel = (currency: Currency, isDebit: boolean, msgFee: string) => {
        var fee = ""; //Fees may be applied!
        if (currency != null) {
            var fundFee = this.fundingFees.find((x) => x.currency == currency && x.isDebit == isDebit);
            if (fundFee == undefined) return "";
            if (fundFee.value != undefined) fee += fundFee.value + msgFee;
            if (fundFee.minValue && fundFee.minValue > 0) fee += " Minimum " + fundFee.minValue + " " + currency + ".";
        }
        return fee;
    };

    getMinFundingFee = (currency: Currency, isDebit: boolean) => {
        if (currency != null) {
            var fundFee = this.fundingFees.find((x) => x.currency == currency && x.isDebit == isDebit);
            if (fundFee == undefined) return 0;
            if (fundFee.minValue && fundFee.minValue > 0) return fundFee.minValue;
        }
        return 0;
    };
}
