import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Text, HStack, Checkbox } from "@chakra-ui/react";
import MyTextInput from "app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Regx } from "app/common/util/regx";
import { MessageType } from "app/models/message";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default observer(function RegisterForm() {
    const { t } = useTranslation();
    const { userStore } = useStore();
    const [agreement, setAgreement] = useState(false);
    const validationSchema = Yup.object({
        email: Yup.string().required("Required").email("Invalid format"),
        password: Yup.string()
            .required(t("passwrd.criteria.p3"))
            .matches(Regx.Pwd8, t("passwrd.criteria.p1")),
        passwordConfirmation: Yup.string()
            .required(t("passwrd.criteria.p3"))
            .oneOf([Yup.ref("password")], t("passwrd.criteria.p2")),
    });
    
    return (
        <Formik
            initialValues={{
                passwordConfirmation: "",
                email: "",
                password: "",
                error: null,
            }}
            onSubmit={(values, { setErrors }) =>
                userStore
                    .register({
                        email: values.email,
                        password: values.password,
                    })
                    .catch((error) => setErrors({ error }))
            }
            validationSchema={validationSchema}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <AuthenticationTemplate
                    notification={
                        errors.error
                            ? {
                                  message: errors.error ? errors.error : "",
                                  type: MessageType.Error,
                              }
                            : undefined
                    }
                    headingText={t("sign.up.p1")}
                >
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name="email" placeholder="Email" isRequired />
                        <MyTextInput name="password" placeholder={t("password")} type="password" isRequired />
                        <MyTextInput name="passwordConfirmation" placeholder={t("passwrd.confirmation")} type="password" isRequired />
                        <Checkbox colorScheme="yellow" onChange={() => setAgreement(!agreement)} isChecked={agreement} mb="10">
                            {t("sign.up.mess")}
                        </Checkbox>

                        <Button
                            disabled={!isValid || !dirty || isSubmitting || !agreement}
                            variant="primary"
                            mb="6"
                            isLoading={isSubmitting}
                            loadingText={t("landing.intro.p6")}
                            type="submit"
                            isFullWidth
                        >
                            {t("landing.intro.p6")}
                        </Button>
                    </Form>
                </AuthenticationTemplate>
            )}
        </Formik>
    );
});
