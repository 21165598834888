import * as React from "react";
import { Menu, MenuButton, Text, MenuProps, MenuList, Divider, Flex, Button, Checkbox, useDisclosure } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import Calendar from "app/components/calendar";
import { useTranslation } from "react-i18next";

interface Props extends MenuProps {
    initialStartDate?: Date;
    initialEndDate?: Date;
    children: React.ReactNode;
    applyHandler: (startDate?: Date, endDate?: Date) => void;
    buttonText: string;
}

export default observer(function DateFilterPopover({ initialStartDate, initialEndDate, applyHandler, children, buttonText, ...rest }: Props) {
    const [startDate, setStartDate] = useState<Date | undefined>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);
    const { isOpen, onOpen, onClose } = useDisclosure();

    function handleClearFilters() {
        setStartDate(undefined);
        setEndDate(undefined);
    }

    const { t } = useTranslation();

    return (
        <Menu
            {...rest}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={() => {
                setStartDate(initialStartDate);
                setEndDate(initialEndDate);
                onClose();
            }}
            
        >
            <MenuButton
                sx={{
                    "> span": {
                        display: "flex",
                        alignItems: "center",
                    },
                }}
            >
                <Text as="span" size="smallText_semibold" color="white" whiteSpace="nowrap">
                    {buttonText}
                </Text>
                <ArrowDown ml="1" color="gray.500" />
            </MenuButton>

            <MenuList p="6" d="flex" flexDir="column" color="white" minW={{base: "280px", sm: "350px" }} maxW="80%" 
            >
                <Calendar startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}  />
                <Divider my="6" borderColor="gray.700" />
                <Flex w="100%" align="center" justify="space-between">
                    <Button variant="overlineLink" onClick={handleClearFilters}>
                        {/* onClick={() => resetForm({ values: filterParams })} */}
                        {t("claer.bttn")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            applyHandler(startDate, endDate);
                            onClose();
                        }}
                    >
                        {t("apply.bttn")}
                    </Button>
                </Flex>
            </MenuList>
        </Menu>
    );
});
