import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useState } from "react";
import { Flex, Heading, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useBreakpointValue, IconButton, Button } from "@chakra-ui/react";
import { Bin } from "assets/icons/activity";
import { Copy } from "assets/icons/home";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { FundingFee } from "../../../app/models/fundingFee";
import { getFundingTypeLabel } from "../../../app/models/fundingRequest";
import { useStore } from "../../../app/stores/store";
import FundingFeeForm from "./FundingFeeForm";
import { CurrencyLabel } from "app/models/rate";

export default observer(function FundingFees() {
    const {
        adminStore: { loading, initialLoading, fundingFees, loadFundingFees, deleteFundingFee },
        userStore: { isAuthorized },
    } = useStore();
    const headingSize = useBreakpointValue({ base: "h4_bold", sm: "h4_bold" });
    const [target, setTarget] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [fundingFee, setFundingFee] = useState<FundingFee>();

    useEffect(() => {
        if (isAuthorized) {
            loadFundingFees();
        }
    }, [isAuthorized, loadFundingFees]);

    function handleDeleteFee(fee: FundingFee, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        deleteFundingFee(fee);
    }

    function handleEditFee(fee: FundingFee, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        setFundingFee(fee);
        setEditMode(true);
    }

    if (initialLoading) return <LoadingComponent />;

    return (
        <Flex gridColumn={{ base: "1/-1" }} mb={{ base: "0", lg: "0" }} direction="column">
            <Flex justify="space-between" mb={{ base: "2", sm: "4" }}>
                <Heading my="2" color="white" gridColumn="1/-1" size={headingSize}>
                    Funding Fees
                </Heading>

                <Button
                    variant="overlineLink"
                    onClick={() => {
                        setEditMode(!editMode);
                        setFundingFee(undefined);
                    }}
                >
                    {editMode ? "Cancel" : "Add Fee"}
                </Button>
            </Flex>

            <Flex
                gridColumn={{ base: "1/-1" }}
                mb={{ base: "6", lg: "0" }}
                direction="column"
                justify="center"
                py="3"
                bg="gray.800"
                borderRadius="0.75rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.700"
                overflowX="auto"
            >
                {editMode ? (
                    <Flex direction="column" justify="center" px="6" py="6">
                        <FundingFeeForm setAddFundingFeeMode={setEditMode} selectedFee={fundingFee} />
                    </Flex>
                ) : (
                    fundingFees &&
                    fundingFees.length > 0 && (
                        <Table variant="striped" colorScheme="blackAlpha">
                            <Thead>
                                <Tr>
                                    <Th color="gray.500">Actions</Th>
                                    <Th color="gray.500">Currency</Th>
                                    <Th color="gray.500">Type</Th>
                                    <Th color="gray.500" isNumeric>
                                        Percentage Value
                                    </Th>
                                    <Th color="gray.500" isNumeric>
                                        Minimum Value
                                    </Th>
                                </Tr>
                            </Thead>

                            <Tbody color="white" bg="gray.900">
                                {fundingFees.map((fundingFee) => (
                                    <Tr key={fundingFee.currency + fundingFee.isDebit}>
                                        <Td>
                                            <Flex direction="row">
                                                <Tooltip label="Edit">
                                                    <IconButton
                                                        aria-label="Edit"
                                                        mr="2"
                                                        colorScheme="gray.800"
                                                        icon={<Copy w="20px" h="20px" />}
                                                        name={"Edit" + fundingFee.currency + fundingFee.isDebit}
                                                        isLoading={target === "Edit" + fundingFee.currency + fundingFee.isDebit && loading}
                                                        onClick={(e) => handleEditFee(fundingFee, e)}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Delete">
                                                    <IconButton
                                                        aria-label="Delete"
                                                        colorScheme="gray.500"
                                                        icon={<Bin w="20px" h="20px" />}
                                                        name={"Delete" + fundingFee.currency + fundingFee.isDebit}
                                                        isLoading={target === "Delete" + fundingFee.currency + fundingFee.isDebit && loading}
                                                        onClick={(e) => handleDeleteFee(fundingFee, e)}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Td>
                                        <Td>{CurrencyLabel[fundingFee.currency]}</Td>

                                        <Td>{getFundingTypeLabel(fundingFee.isDebit!)}</Td>
                                        <Td isNumeric>{fundingFee.value}%</Td>
                                        <Td isNumeric>
                                            {fundingFee.minValue} {fundingFee.currency}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )
                )}
            </Flex>
        </Flex>
    );
});
