import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import MyTextInput from "app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import * as Yup from "yup";
import { Button, Flex, Text } from "@chakra-ui/react";
import { history } from "../..";
import TFAVerification from "./TFAVerification";
import { Regx } from "app/common/util/regx";
import { MessageType } from "app/models/message";
import { useTranslation } from "react-i18next";

export default observer(function LoginForm() {
    const { userStore, modalStore } = useStore();
   

    const validationSchema = Yup.object({
        email: Yup.string().required("Required").email("Invalid format"),
        password: Yup.string()
            .required("Required")
            .matches(
                Regx.Pwd8,
                "Must contain at least 8 Characters, one Uppercase, one Number and one Special Case Character"
            ),
    });
    const { t } = useTranslation();

    return (
        <>
            {/* <UnauthenticatedNavigation variant="register" /> */}
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    TFAGoogleCode: "",
                    error: null,
                }}
                onSubmit={(values, { setErrors }) => {
                    // toast(<Toast icon={<Clock w="20px" h="20px" color="yellow.800" />}
                    // message={`copied to clipboard`} />);
                    // setToast("tttt", MessageType.Info);

                    userStore.isTFAEnabled(values.email).then((TFAEnabled) => {
                        if (TFAEnabled) {
                            modalStore.openModal(
                                <TFAVerification
                                    callback={userStore.login}
                                    params={values}
                                />
                            );
                        } else
                            userStore.login(values).catch((error) =>
                                setErrors({
                                    error: t("invalid.mail.mess"),
                                })
                            );
                    });
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                    <AuthenticationTemplate
                        notification={
                            errors.error
                                ? {
                                      message: errors.error ? errors.error : "",
                                      type: MessageType.Error,
                                  }
                                : undefined
                        }
                        headingText={t("log.in")}
                    >
                        <Form
                            // className="ui form"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                        >
                            {/* <MyTextInput name="email" placeholder="Email" type="email" label="Email" required/> */}
                            {/* <MyTextInput
                                name="email"
                                placeholder="Email"
                                type="email"
                                required
                            /> */}
                            <MyTextInput
                                name="email"
                                placeholder="Email"
                                type="email"
                                isRequired
                                mb="4"
                            />
                            <MyTextInput
                                name="password"
                                placeholder={t("password")}
                                type="password"
                                isRequired
                                mb="10"
                            />

                            {/* <MyTextInput name="TFAGoogleCode" label="2FA Code" /> */}

                            <Button
                                variant="primary"
                                mb="6"
                                isLoading={isSubmitting && userStore.loading}
                                disabled={!isValid || !dirty}
                                loadingText="Log in"
                                type="submit"
                                isFullWidth
                            >
                                {t("log.in.bttn")}
                            </Button>
                            <Flex
                                justify="center"
                                align="center"
                                direction={{ base: "column", md: "row" }}
                            >
                                <Button
                                    variant="overlineLink"
                                    mb={{ base: "1", md: "0" }}
                                    onClick={() =>
                                        history.push("/security/forgotPwd")
                                    }
                                >
                                    {t("forgot.passwrd")}
                                </Button>
                                <Text
                                    mx="2"
                                    color="white"
                                    size="overline"
                                    display={{ base: "none", md: "flex" }}
                                >
                                    •
                                </Text>
                                <Button
                                    variant="overlineLink"
                                    onClick={() =>
                                        history.push("/security/register")
                                    }
                                >
                                    {t("have.no.account")}
                                </Button>
                            </Flex>
                        </Form>
                    </AuthenticationTemplate>
                )}
            </Formik>
        </>
    );
});
