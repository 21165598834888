import { Flex, FlexProps, Text, Heading, Button } from "@chakra-ui/react";
import { EmptyCheck } from "assets/icons/onboarding";
import { Loader } from "assets/icons/home";

interface Props extends FlexProps {
  step: number;
  buttonText: string;
  variant: "none" | "progress" | "done";
  handleClick: () => void;
}

export default function Document({
  step,
  buttonText,
  variant,
  handleClick,
  ...rest
}: Props) {
  return (
    <Flex
      bg="gray.800"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="gray.700"
      borderRadius="0.75rem"
      px="6"
      pt="6"
      direction="column"
      justify="space-between"
      overflow="hidden"
      {...rest}
    >
      <Flex align="center" mb="10">
        <Flex
          minW="64px"
          minH="64px"
          w="0"
          h="0"
          bg="black"
          borderRadius="full"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.700"
          align="center"
          justify="center"
          mr="4"
        >
          <Heading as="span" size="h4_bold" color="white">
            {step}
          </Heading>
        </Flex>
        <Flex direction="column">
          <Text color="white" size="paragraph_semibold">
            Complete your profile
          </Text>
          <Text color="gray.500" size="smallText_normal">
            Lorem ipsum dolor sit amet.
          </Text>
        </Flex>
      </Flex>
      {variant === "none" ? (
        <Button
          variant="primary"
          w="min-content"
          ml={{ base: "0", lg: "80px" }}
          onClick={handleClick}
          mb="6"
        >
          {buttonText}
        </Button>
      ) : (
        <Flex px="6" py="3" mx="-6" bg="gray.700" align="center">
          <Flex
            minW="44px"
            minH="44px"
            w="0"
            h="0"
            bg="black"
            borderRadius="full"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.700"
            align="center"
            justify="center"
            mr="4"
          >
            {variant !== "progress" ? (
              <EmptyCheck color="green.500" w="20px" h="20px" />
            ) : (
              <Loader color="yellow.800" w="20px" h="20px" />
            )}
          </Flex>
          <Text color="white" size="smallText_normal">
            {variant !== "progress"
              ? "Your profile is approved"
              : "We’re verifying your profile"}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
