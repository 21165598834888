import axios, { AxiosError, AxiosResponse } from "axios";
import { history } from "../..";
import { AccountOperation, Transaction } from "../models/transaction";
import { CryptoAccount, WhitelistedAddress, WhitelistedAddressFormValues, WhitelistedAddressVerifyValues } from "../models/cryptoAccount";
import { Exchange, ExchangeFormValues } from "../models/exchange";
import { FIATAccount, FIATAccountFormValues, GenericAccount } from "../models/fiatAccount";
import { FundingRequestFormValues } from "../models/fundingRequest";
import { PaginatedResult } from "../models/pagination";
import { LoginInfo, Photo, Profile } from "../models/profile";
import { Document } from "../../app/models/document";
import { Rate } from "../models/rate";
import {
    TFAFormValues,
    User,
    UserFormValues,
    UserPwdFormValues,
} from "../models/user";
import { store } from "../stores/store";
import { FundingFee } from "../models/fundingFee";
import { showToast } from "app/components/toast";
import { MessageType } from "app/models/message";
import { request } from "http";
import { BalanceDto } from "app/models/balanceDto";
// import { useTranslation } from "react-i18next";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;//"http://localhost:5000/api";

axios.interceptors.request.use((config) => {
    const token = store.commonStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(
    async (response) => {
        // if (process.env.NODE_ENV === 'development') await sleep(500);
        const pagination = response.headers['pagination'];
        if (pagination) {
            response.data = new PaginatedResult(response.data, JSON.parse(pagination));
            return response as AxiosResponse<PaginatedResult<any>>
        }
        return response;
    },
    (error: AxiosError) => {
        // const { t } = useTranslation();
        const { data, status, config, headers} = error.response!;
        switch (status) {
            case 400:
                if (
                    config.method === "get" &&
                    data.errors.hasOwnProperty("id")
                ) {
                    history.push("/not-found");
                }
                if (data.errors) {
                    const modalStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key]);
                        }
                    }
                    throw modalStateErrors.flat();
                } else {
                    showToast(data, MessageType.Error);
                }
                break;
            case 401:
                if (status === 401 && headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')) {
                    store.userStore.logout();
                    showToast("session.expired", MessageType.Info);
                }
                break;
            case 404:
                history.push("/not-found");
                break;
            case 500:
                store.commonStore.setServerError(data);
                history.push("/server-error");
                break;
        }
        return Promise.reject(error);
    }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) =>
        axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) =>
        axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
    current: () => requests.get<User>("/account"),
    login: (user: UserFormValues) =>
        requests.post<User>("/account/login", user),
    register: (user: UserFormValues) =>
        requests.post<User>("/account/register", user),
    refreshToken: () => requests.post<User>('/account/refreshToken', {}),
    getTFAKey: () => requests.get<string>("/account/TFAKey"),
    verifyTFA: (code: string) => requests.post<boolean>(`/account/verifyTFA`, {code}),
    isTFAEnabled: (email: string) => requests.post<boolean>(`/account/isTFAEnabled?email=${email}`, {}),
    disableTFA: () => requests.post<void>("/account/TFADisable", {}),
    TFAConfirmSetup: (params: TFAFormValues) =>
        requests.post(
            `/account/TFAConfirmSetup`,
            params
        ),
    verifyEmail: (token: string, email: string) =>
        requests.post<void>(
            `/account/verifyEmail?token=${token}&email=${email}`,
            {}
        ),
    resendEmailConfirm: (email: string) =>
        requests.post(`/account/resendEmailConfirmationLink?email=${email}`, {}),
    sendPasswordResetEmail: (email: string) =>
        requests.post(`/account/sendPasswordResetEmail?email=${email}`, {}),
    resetPassword: (params: UserPwdFormValues) =>
        requests.post("/account/resetPassword", params),
    changePassword: (params: UserPwdFormValues) =>
        requests.post("/account/changePassword", params),
    validate: (email: string) => requests.post(`/account/${email}/validate`, {}),
};

const Profiles = {
    get: (username: string) => requests.get<Profile>(`/profiles/${username}`),
    uploadPhoto: (file: Blob) => {
        let formData = new FormData();
        formData.append("File", file);
        return axios.post<Photo>("photos", formData, {
            headers: { "Content-type": "multipart/form-data" },
        });
    },
    setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
    deletePhoto: (id: string) => requests.del(`/photos/${id}`),
    readPhoto: (id: string) => requests.get<Blob>(`/photos/${id}`),
    updateProfile: (profile: Partial<Profile>) =>
        requests.put(`/profiles`, profile),
    startIdentification: () => requests.post<string>(`/profiles/startIdentification`, {}),
    //listAll: () => requests.get<Profile[]>("/profiles/all"),
    listAll: (params: URLSearchParams) => axios.get<PaginatedResult<Profile[]>>("/profiles/all", {params}).then(responseBody),
    listLoginInfo: (params: URLSearchParams) => axios.get<PaginatedResult<LoginInfo[]>>("/profiles/loginInfo", {params}).then(responseBody),
    listLoginInfoAsAdmin: (params: URLSearchParams) => axios.get<PaginatedResult<LoginInfo[]>>("/profiles/loginInfoAsAdmin", {params}).then(responseBody),
};

const Documents = {
    list: () => requests.get<Document[]>("/documents"),
    //listAll: () => requests.get<Document[]>("/documents/all"),
    listAll: (params: URLSearchParams) => axios.get<PaginatedResult<Document[]>>("/documents/all", {params}).then(responseBody),
    create: (type: string, comment: string, file: Blob) => {
        let formData = new FormData();
        formData.append("File", file);
        formData.append("type", type);
        formData.append("comment", comment);
        return axios.post<Document>("/documents", formData, {
            headers: { "Content-type": "multipart/form-data" },
        });
    },
    delete: (id: string) => requests.del(`/documents/${id}`),
    download: (id: string) => requests.get<Blob>(`/documents/${id}`),
    adminDownload: (id: string) => requests.get<Blob>(`/documents/admin/${id}`),
    validate: (id: string) => requests.post(`/documents/${id}/validate`, {}),
    invalidate: (id: string) => requests.post(`/documents/${id}/invalidate`, {})
};

const RateFees = {
    list: () => requests.get<Rate[]>("/ratefees"),
    create: (rateFee: Rate) => requests.post<FundingFee>("/ratefees", rateFee),
    update: (rateFee: Rate) => requests.put<FundingFee>("/ratefees", rateFee),
    delete: (fromCurrency: string, toCurrency: string) => requests.del(`/ratefees/${fromCurrency}/${toCurrency}`),
}

const FundingFees = {
    list: () => requests.get<FundingFee[]>("/fundingfees"),
    create: (fundingFee: FundingFee) => requests.post<FundingFee>("/fundingfees", fundingFee),
    update: (fundingFee: FundingFee) => requests.put<FundingFee>("/fundingfees", fundingFee),
    delete: (currency: string, isDebit: boolean) => requests.del(`/fundingfees/${isDebit}/${currency}`),
}

const FiatAccounts = {
    create: (fiatAccount: FIATAccountFormValues) =>
        requests.post<FIATAccount>("/accounts/fiataccounts", fiatAccount),
    list: () => requests.get<FIATAccount[]>("/accounts/fiataccounts"),
    listAll: () => requests.get<FIATAccount[]>("/accounts/fiataccounts/all"),
    // update: (fiatAccount: FIATAccountFormValues) =>
    //     requests.put<void>(`/accounts/fiataccounts/${fiatAccount.id}`, fiatAccount),
    delete: (id: string) => requests.del<void>(`/accounts/fiataccounts/${id}`),
    validate: (id: string) => requests.post<void>(`/accounts/fiataccounts/${id}/validate`, {}),
    invalidate: (id: string) => requests.post<void>(`/accounts/fiataccounts/${id}/invalidate`, {}),
};

const CryptoAccounts = {
    create: (currency: string) => 
        requests.post<CryptoAccount>("/accounts/cryptoaccounts", {currency: currency}),
}


const AccountOperations = {
    list: (params: URLSearchParams) => axios.get<AccountOperation[]>("/accounts/accountoperations", {params}).then(responseBody),
    listAll: (params: URLSearchParams) => axios.get<AccountOperation[]>("/accounts/accountoperations/all", {params}).then(responseBody),
};

const Transactions = {
    create: (fundingRequest: FundingRequestFormValues) =>
        requests.post<Transaction>("/accounts/transactions", fundingRequest),
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Transaction[]>>("/accounts/transactions", {params}).then(responseBody),
    cancel: (id: string) => requests.del<void>(`/accounts/transactions/${id}`),
    process: (id: string) => requests.post<void>(`/accounts/transactions/${id}/process`, {}),
    listAll: (params: URLSearchParams) => axios.get<PaginatedResult<Transaction[]>>("/accounts/transactions/all", {params}).then(responseBody),
};

const WhitelistedAddresses = {
    create: (whitelistedAddress: WhitelistedAddressFormValues) =>
        requests.post<WhitelistedAddress>("/accounts/whitelistedaddresses", whitelistedAddress),
    verify: (whitelistedAddress: WhitelistedAddressVerifyValues) =>
        requests.post<void>("/accounts/whitelistedaddresses/verify", whitelistedAddress),
    delete: (id: string) => requests.del(`/accounts/whitelistedaddresses/${id}`),
};

const Exchanges = {
    create: (exchange: ExchangeFormValues) =>
        requests.post<Exchange>("/accounts/Exchange", exchange),
    // getRate: (fromCurrency: string, toCurrency: string) =>
    //     requests.get<Rate>(`/accounts/exchange/rate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`),
};

const AppAccounts = {
    list: () => axios.get<GenericAccount[]>("/accounts/appaccounts"),
    readIban: (currency: string) => axios.get<string>(`/accounts/appaccounts/${currency}`),
};

const Reports = {
    feeTotalBalances: (params: URLSearchParams) => axios.get<BalanceDto[]>("/reports/feeTotalBalances", {params}).then(responseBody),
    customerTotalBalances: () => requests.get<BalanceDto[]>("/reports/customerTotalBalances"),
};


const agent = {
    Account,
    Profiles,
    Documents,
    RateFees,
    FundingFees,
    FiatAccounts,
    CryptoAccounts,
    AccountOperations,
    WhitelistedAddresses,
    Exchanges,
    AppAccounts,
    Transactions,
    Reports,
};

export default agent;
