import { Flex, FlexProps, Text, Input, useDisclosure } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import PopoverMenu from "../popoverMenu";
import AssetList from "../assetsList";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useEffect, useState } from "react";
import { Asset } from "app/models/asset";
import * as Yup from "yup";
import { isFiat } from "app/models/rate";
import { Regx } from "app/common/util/regx";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
    variant: "to" | "from" | "none";
    hasError?: boolean;
    setError?: (error: boolean) => void;
    hasInput?: boolean;
    isDebit?: boolean;
    isInputDisabled?: boolean;
    setAmount?: (amount: number) => void;
    selectedAmount?: number;
    asset: Asset;
    assets: Asset[] | undefined;
    setAsset: (asset: Asset) => void;
}

export default observer(function Balance({
    variant,
    hasError = false,
    setError,
    hasInput = false,
    isDebit,
    isInputDisabled = false,
    setAmount,
    selectedAmount = 0,
    asset,
    assets,
    setAsset,
    ...rest
}: Props) {
    const [invalid, setInvalid] = useState(false);
    const [value, setValue] = useState(selectedAmount.toString()); //needed?
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        setValue(selectedAmount.toString());
    }, [selectedAmount, asset]);

    useEffect(() => {
        setInvalid(hasError);
    }, [hasError]);

    useEffect(() => {
        //set default asset
        setAsset(assets?.find((x) => x.currency === asset.currency) || asset);
    }, []);

    function selectAsset(asset: Asset) {
        setAsset(asset);
        setAmount && setAmount!(0);
        setValue("0");
        setInvalid(false);
        onClose();
    }

    function handleInputChanged(e: SyntheticEvent<HTMLInputElement>) {
        var schema = Yup.number()
            .test("is-decimal", "invalid number", (number) => (isFiat(asset.currency) ? Regx.Decimal2 : Regx.Decimal8).test(number + ""))
            .required("The Amount is required");
        if (isDebit)
            schema = schema.max(asset.balance);

        var value = e.currentTarget.value;
        schema.isValid(value).then((isValid) => {
            setInvalid(!isValid);
            setError && setError(!isValid);
            if (isValid) setAmount && setAmount(parseFloat(value));
            else setAmount && setAmount(0);
            setValue(value);
        });
    }
    const { t } = useTranslation();
    return (
        <Flex borderRadius="0.5rem" borderWidth="1px" borderStyle="solid" borderColor="gray.700" bg="black" w="100%" overflow="hidden" {...rest}>
            {variant === "none" ? null : (
                <Flex
                    bg="gray.900"
                    flex="0 0 auto"
                    p="4"
                    align="center"
                    justify="center"
                    minW="62px"
                    w="0"
                    borderRightWidth="1px"
                    borderStyle="solid"
                    borderColor="gray.700"
                >
                    <Text size="caption_semibold">{variant === "to" ? "To" : "From"}</Text>
                </Flex>
            )}
            <Flex py="6" pr="6" pl={variant === "none" ? "6" : "4"} justify="space-between" align="center" flex="1 1 auto">
                <Flex direction="column" flex="0 0 50%">
                    <PopoverMenu
                        isOpen={isOpen}
                        onOpen={onOpen}
                        onClose={onClose}
                        triggerContent={
                            <Text mb="2" cursor="pointer">
                                {asset.currency}
                                <ArrowDown my="auto" width="16px" h="16px" color="gray.500" ml="2" />
                            </Text>
                        }
                    >
                        <AssetList setAsset={selectAsset} assets={assets} />
                    </PopoverMenu>
                    <Text size="caption_normal" color={"gray.300"}>
                       {t("balance")} {asset.balance}
                    </Text>
                </Flex>
                {hasInput && (
                    <Flex direction="column" flex="auto" alignSelf="stretch">
                        <Input
                            variant="flushed"
                            p="0"
                            textAlign="right"
                            value={value}
                            isDisabled={isInputDisabled || asset.accountId == undefined}
                            mb="2"
                            sx={{ fontVariantNumeric: "tabular-nums" }}
                            onClick={(e) => {
                                if (e.currentTarget.value == "0") e.currentTarget.value = "";
                            }}
                            onChange={handleInputChanged}
                        />
                        {invalid ? (
                            <Text size="caption_normal" color="red.500" textAlign="right">
                                {t("invalid.amount")}
                            </Text>
                        ) : null}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
});
