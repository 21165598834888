import * as React from "react";
import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { CalendarIcon } from "assets/icons/activity";
import { useTranslation } from "react-i18next";

export default function ProfileNotice(props: FlexProps) {
  const { t } = useTranslation();
  return (
    <Flex
      p="6"
      bg="yellow.800"
      borderRadius="0.75rem"
      direction="column"
      align="flex-start"
      pos="relative"
      overflow="hidden"
      {...props}
    >
      <Flex
        align="center"
        justify="center"
        p="5"
        bg="yellow.500"
        borderRadius="50%"
        mb="4"
        zIndex="1"
      >
        <CalendarIcon minW="32px" minH="32px" w="0" h="0" />
      </Flex>
      <Text size="h4_bold" color="black" mb="3" zIndex="1">
          {t("profile.notice1")}
      </Text>
      <Text color="black" size="paragraph_normal" zIndex="1">
          {t("profile.notice2")}
      </Text>
      <Flex
        zIndex="0"
        opacity="0.1"
        w="300px"
        h="300px"
        bg="white"
        pos="absolute"
        top="0"
        right="0"
        borderRadius="50%"
        transform="translate(50%, -30%)"
      />
    </Flex>
  );
}
