import {
    Redirect,
    Route,
    RouteComponentProps,
    RouteProps,
} from "react-router-dom";
import { useStore } from "../stores/store";

interface Props extends RouteProps {
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
    roles?: string[];
}

export default function PrivateRoute({
    component: Component,
    roles,
    ...rest
}: Props) {
    const {
        userStore: { user, isLoggedIn },
    } = useStore();
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    );
                }

                if (roles && !roles.some(r => user!.roles.includes(r))) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                return <Component {...props} />;
            }}
        />
    );
}
