import { showToast } from "app/components/toast";
import { MessageType } from "app/models/message";
import { Currency } from "app/models/rate";
import { format } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
    getTransactionStatusLabel,
    getTransactionTypeLabel,
    LedgerFilterParams,
    Transaction,
    TransactionStatus,
    TransactionType,
} from "../models/transaction";
import { Pagination, PagingParams } from "../models/pagination";

export default class LedgerStore {
    transactionRegistry = new Map<string, Transaction>();
    loading = false;
    selectedTx: Transaction | undefined = undefined;
    //transactions: Transaction[] = [];
    pagination: Pagination | undefined = undefined;
    pagingParams = new PagingParams();
    filterParams = new LedgerFilterParams();

    constructor() {
        makeAutoObservable(this);

        // reaction(
        //     () => this.filterParams,
        //     () => {
        //         // this.pagingParams = new PagingParams();
        //         // this.transactionRegistry.clear();
        //         this.clearTransactions()
        //         // this.loadTransactions();
        //     }
        // )
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    };

    setSelectedTx = (selectedTx: Transaction | undefined) => {
        this.selectedTx = selectedTx;
    };

    setFilterParams = (filterParams: LedgerFilterParams, reLoad: boolean = true) => {
        this.filterParams = filterParams;
        this.clearTransactions();
        
        if (reLoad) this.loadTransactions();
    };

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    };

    getFilterLabel = () => {
        var label = "";
        if (this.filterParams) {
            // if (this.filterParams.iban) label += "IBAN = " + this.filterParams.iban + "; ";
            if (this.filterParams.type) {
                label += "Transaction Type = ";
                this.filterParams.type.forEach((item) => (label += " " + getTransactionTypeLabel(Number.parseInt(item))));
                label += "; ";
            }
            if (this.filterParams.startDate) label += "Start Date = " + format(this.filterParams.startDate, "dd MMM yyyy h:mm aa") + "; ";
            if (this.filterParams.endDate) {
                var myDate = new Date(this.filterParams.endDate);
                myDate.setDate(myDate.getDate() + 1);
                label += "End Date = " + format(myDate, "dd MMM yyyy h:mm aa") + "; ";
            }
            if (this.filterParams.currency) label += " Currency = " + this.filterParams.currency + "; ";
            if (this.filterParams.status) {
                label += " Status =";
                this.filterParams.status.forEach((item) => (label += " " + getTransactionStatusLabel(Number.parseInt(item))));
            }
        }
        return label;
    };

    cancelSelectedTransaction = async () => {
        if (this.selectedTx && this.selectedTx.status == TransactionStatus.Pending) {
            this.loading = true;
            try {
                const result = await agent.Transactions.cancel(this.selectedTx.id);
                runInAction(() => {
                    this.selectedTx!.status = TransactionStatus.Canceled;
                    this.transactionRegistry.set(this.selectedTx!.id, this.selectedTx!);
                    showToast("cancel.tx.ok", MessageType.Success);
                    this.loading = false;
                });
            } catch (error) {
                //console.log(error);
                runInAction(() => {
                    this.loading = false;
                    showToast("cancel.tx.error", MessageType.Error);
                });
            }
        }
    };

    loadTransactions = async () => {
        this.loading = true;
        try {
            var params = new URLSearchParams();
            params.append("pageNumber", this.pagingParams.pageNumber.toString());
            params.append("pageSize", this.pagingParams.pageSize.toString());

            if (this.filterParams) {
                if (this.filterParams.iban) params.append("IBAN", this.filterParams.iban);
                if (this.filterParams.startDate) params.append("startDate", this.filterParams.startDate.toISOString());
                if (this.filterParams.endDate) {
                    var myDate = new Date(this.filterParams.endDate);
                    myDate.setDate(myDate.getDate() + 1);
                    params.append("endDate", myDate.toISOString());
                }
                if (this.filterParams.type) {
                    // params.append("Type", this.filterParams.type.toString());
                    this.filterParams.type.forEach((item) => params.append("type", item));
                }
                if (this.filterParams.currency) this.filterParams.currency.forEach((item) => params.append("currency", item));
                if (this.filterParams.status) this.filterParams.status.forEach((item) => params.append("status", item));

                // params.append("currency", Currency.RON);
                // params.append("currency", Currency.LTC);
            }
            // params.append("IsDebit", isDebit.toString());
            // //console.log(params);
            const result = await agent.Transactions.list(params);
            runInAction(() => {
                // this.setSelectedTx(undefined);
                result.data.forEach((tx) => {
                    tx.date = new Date(tx.date + "Z");
                    this.transactionRegistry.set(tx.id, tx);
                    !this.selectedTx && this.setSelectedTx(tx);
                });
                // this.transactions = result.data;
                this.setPagination(result.pagination);
                //console.log(result);
                //console.log(this.transactionRegistry);
                this.loading = false;
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    clearTransactions = () => {
        this.transactionRegistry.clear();
        this.setSelectedTx(undefined);
        this.pagingParams = new PagingParams(1, this.pagingParams.pageSize);
    };

    // get transationsByDate() {
    //     return Array.from(this.transactionRegistry.values()).sort((a, b) => b.date!.getTime() - a.date!.getTime());
    // }

    // get groupedByDateTransations() {
    //     return Object.entries(
    //         Array.from(this.transactionRegistry.values()).reduce((transactions, transaction) => {
    //             const date = format(transaction.date!, "dd MMMM yyyy");
    //             transactions[date] = transactions[date] ? [...transactions[date], transaction] : [transaction];
    //             return transactions;
    //         }, {} as { [key: string]: Transaction[] })
    //     );
    // }

    get groupedTransations() {
        var txs = Object.entries(
            Array.from(this.transactionRegistry.values())
                .filter((t) => t.status != TransactionStatus.Pending)
                .reduce((transactions, transaction) => {
                    const date = format(transaction.date!, "dd MMMM yyyy");
                    transactions[date] = transactions[date] ? [...transactions[date], transaction] : [transaction];
                    return transactions;
                }, {} as { [key: string]: Transaction[] })
        );
        var pendingTxs = Array.from(this.transactionRegistry.values()).filter((t) => t.status == TransactionStatus.Pending);
        if (pendingTxs.length > 0) txs.unshift(["Pending Transactions", pendingTxs]);
        return txs;
    }

    // get nonPendingTxs() {
    //     return Array.from(this.transactionRegistry.values()).filter((t) => t.status != TransactionStatus.Pending);
    // }

    // get groupedByStatusTransations() {
    //     return Object.entries(
    //         Array.from(this.transactionRegistry.values()).reduce((transactions, transaction) => {
    //             const type = transaction.type;
    //             transactions[type] = transactions[type] ? [...transactions[type], transaction] : [transaction];
    //             return transactions;
    //         }, {} as { [key: string]: Transaction[] })
    //     );
    // }
}
