import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Button, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import { DocumentType } from "../../app/models/document";
import { useStore } from "app/stores/store";
import { useEffect, useState } from "react";
import DocumentUploadForm from "features/documents/DocumentUploadForm";
import ProfileIdentificationCard from "features/profiles/ProfileIdentificationCard";
import React from "react";

export default observer(function Four() {
    const hideLogo = useBreakpointValue({ base: true, sm: false });
    const {
        profileStore,
        userStore: { user },
    } = useStore();
    const [firstTime, setFirstTime] = React.useState(true);

    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);

    function handleStartIdentification() {
        setFirstTime(false);
        profileStore.startIdentification().then((url) => {
            window.open(url!);
            history.push("/onboarding/six")
            // window.location.href = url!;
        });
    }

    return (
        <>
            <OnboardingSteps steps={5} currentStep={4} />
            <AuthenticationTemplate
                headingText="Verify you identity (1 of 2)"
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                        <Text size="paragraph_normal" color="white" mb="6">
                            Please make sure you prepare the following documents: a government ID, a selfie and a proof of residence.
                        </Text>
                        <Text size="paragraph_normal" color="white">
                            Identity verification can take up to 3 days to be complete. We’ll let you know when the verification is complete.
                        </Text>
                    </>
                }
            >
                <Text size="paragraph_normal" color="white" mb="6">
                    Please make sure you prepare a government ID. A sefie will be taken to validate the ID.
                </Text>
                <Text size="paragraph_normal" color="white" mb="6">
                    You'll be redirected to a third party for this step ....
                </Text>

                    <Button variant="primary" onClick={handleStartIdentification} isLoading={profileStore.loading}>
                        Start Identification
                    </Button>

                {/* <DocumentUploadForm documentType={DocumentType.ID} submitButtonText="Continue" saveCallback={() => history.push("/onboarding/five") }/> */}
            </AuthenticationTemplate>
        </>
    );
});
