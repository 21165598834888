import { Center, Spinner, Text } from "@chakra-ui/react";

interface Props {
    inverted?: boolean;
    content?: string;
}

export default function LoadingComponent({ content = "Loading..." }: Props) {
    return (
        <Center height="50vh">
            <Spinner alignContent={"center"} color="yellow.500" size="xl" />
            <Text as="span" color="yellow.500" size="paragraph_bold" ml="4">
                {content}
            </Text>
        </Center>
    );
}
