import * as React from "react";
import { Menu, BoxProps, MenuButton, MenuList, MenuItem, Text } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useState } from "react";

export default function LanguageNav(props: BoxProps) {
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(i18n.language);

    const changeLanguage = (event: SyntheticEvent, data: string) => {
        setSelectedLang(data);
        i18n.changeLanguage(data);
    };

    return (
        <Menu size="md" {...props} >
            <MenuButton ml="auto" my="3" d="flex">
                <Text size="smallText_bold" as="span">
                    {selectedLang.toUpperCase()}
                </Text>
                <ArrowDown ml="2" w="16px" h="16px" d={{ base: "none", lg: "inline" }}/>
            </MenuButton>
            <MenuList zIndex="2">
                <MenuItem isDisabled={selectedLang === "en"} onClick={(e) => changeLanguage(e, "en")}>
                    EN
                </MenuItem>
                <MenuItem isDisabled={selectedLang === "ro"} onClick={(e) => changeLanguage(e, "ro")}>
                    RO
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
