import * as React from "react";
import { HStack, Grid as CGrid, useDisclosure } from "@chakra-ui/react";
import { Home, Activity, DepositWithdraw, Trade, Hamburger, Logo } from "assets/icons/navigation";
import Grid from "app/components/grid";
import NavigationItem from "./NavigationItem";
import ProfileNav from "./ProfileNav";
import LanguageNav from "./LanguageNav";
import { useLocation } from "react-router-dom";
import { history } from "../../..";
import { FundingType } from "app/models/fundingRequest";
import { useTranslation } from "react-i18next";
import { useStore } from "app/stores/store";
import AdminNav from "./AdminNav";
import MobileNav from "./MobileNav";

export default function Navigation() {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const {
        userStore: { isAuthorized },
    } = useStore();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Grid
                as="nav"
                minH="4.75rem"
                bg="gray.800"
                pos="relative"
                _after={{
                    pos: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "2px",
                    bg: "gray.700",
                    content: "''",
                    zIndex: "0",
                }}
            >
                <CGrid gridColumn="1/-1" alignItems="center" templateColumns="1fr auto 1fr">
                    <Logo w="123px" h="20px" color="gray.800" />
                    <HStack as="ul" listStyleType="none" spacing="10" d={{ base: "none", lg: "flex" }}>
                        <NavigationItem
                            icon={<Home w="20px" h="20px" />}
                            isActive={pathname === "/home"}
                            onClick={() => history.push("/home")}
                        >
                            {t("navigation.home")}
                        </NavigationItem>
                        
                        <NavigationItem
                            icon={<Trade w="20px" h="20px" />}
                            isActive={pathname === "/exchange"}
                            onClick={() => history.push("/exchange")}
                        >
                            {t("navigation.trade")}
                        </NavigationItem>
                        {/* <NavigationItem
                            icon={<DepositWithdraw w="20px" h="20px" />}
                            isActive={pathname === `/funding/${FundingType.Deposit}`}
                            onClick={() => history.push(`/funding/${FundingType.Deposit}`)}
                        >
                           d
                        </NavigationItem>
                        <NavigationItem
                            icon={<DepositWithdraw w="20px" h="20px" />}
                            isActive={pathname === `/funding/${FundingType.Withdraw}`}
                            onClick={() => history.push(`/funding/${FundingType.Withdraw}`)}
                        >
                            w
                        </NavigationItem> */}
                        <NavigationItem
                            icon={<DepositWithdraw w="20px" h="20px" />}
                            isActive={pathname === `/funding`}
                            onClick={() => history.push(`/funding`)}
                        >
                             {t("navigation.funding")}
                        </NavigationItem>
                        <NavigationItem
                            icon={<Activity w="20px" h="20px" />}
                            isActive={pathname === "/activity"}
                            onClick={() => history.push("/activity")}
                        >
                            {t("navigation.activity")}
                        </NavigationItem>
                        {isAuthorized && <AdminNav />}
                    </HStack>
                    <HStack spacing="3" d={{ base: "none", lg: "flex" }}>
                        <ProfileNav />
                        <LanguageNav />
                    </HStack>
                    <Hamburger cursor="pointer" onClick={onOpen} w="20px" h="20px" d={{ base: "flex", lg: "none" }} gridColumn="2/4" ml="auto" />
                </CGrid>
            </Grid>
            <MobileNav isOpen={isOpen} handleClose={onClose} />
        </>
    );
}
