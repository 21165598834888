import * as React from "react";
import { Flex, FlexProps, Heading, Text } from "@chakra-ui/react";
import ProfileDocumentCard from "./ProfileDocumentCard";
import { DocumentType } from "app/models/document";
import DocumentUploadForm from "features/documents/DocumentUploadForm";
import IconButton from "app/components/iconButton";
import { ArrowBack } from "assets/icons/onboarding";
import { useTranslation } from "react-i18next";
import ProfileIdentificationCard from "./ProfileIdentificationCard";

export default function ProfileDocuments(props: FlexProps) {
    const [showDocuments, setShowDocuments] = React.useState(true);
    const [selectedDocumentType, setSelectedDocumentType] = React.useState<DocumentType>();
    const { t } = useTranslation();
    return (
        <Flex direction="column" color="white" {...props}>
            <Flex align="center" mb="6">
                {!showDocuments && (
                    <IconButton aria-label="go back" mr="4" icon={<ArrowBack w="20px" h="20px" />} onClick={() => setShowDocuments(true)} />
                )}
                <Heading mb="4" size="h4_bold">
                    {t("profile.documents.p1")}
                </Heading>
            </Flex>

            {showDocuments ? (
                <>
                    <Text mb="6" size="paragraph_normal">
                        {t("profile.documents.p2")}
                    </Text>
                    <ProfileIdentificationCard mb="4"/>
                    {/* <ProfileDocumentCard
                        documentType={DocumentType.ID}
                        uploadCallback={() => {
                            setSelectedDocumentType(DocumentType.ID);
                            setShowDocuments(false);
                        }}
                        mb="4"
                    />
                    <ProfileDocumentCard
                        documentType={DocumentType.Selfie}
                        uploadCallback={() => {
                            setSelectedDocumentType(DocumentType.Selfie);
                            setShowDocuments(false);
                        }}
                        mb="4"
                    /> */}
                    <ProfileDocumentCard
                        documentType={DocumentType.POR}
                        uploadCallback={() => {
                            setSelectedDocumentType(DocumentType.POR);
                            setShowDocuments(false);
                        }}
                    />
                </>
            ) : (
                <Flex
                    py={{ base: "6", sm: "8" }}
                    px={{ base: "4", sm: "8" }}
                    borderRadius="0.75rem"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="gray.700"
                    bg="gray.800"
                    direction="column"
                >
                    <DocumentUploadForm
                        documentType={selectedDocumentType!}
                        submitButtonText="Continue"
                        saveCallback={() => setShowDocuments(true)}
                    />
                </Flex>
            )}
        </Flex>
    );
}
