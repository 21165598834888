import AuthenticationTemplate from "app/components/basicPageTemplate";
import React from "react";
import { MessageType } from "app/models/message";
import { showToast } from "app/components/toast/Toast";
import { Button, Text, Image } from "@chakra-ui/react";
import agent from "../../app/api/agent";
import useQuery from "../../app/common/util/hooks";
import CreatedImage from "assets/images/account/created.webp";
import { Alert, Info } from "assets/icons/unauthenticated";
import { useTranslation } from "react-i18next";


export default function RegisterSuccess() {
    const email = useQuery().get("email") as string;
    const [notification, setNotification] = React.useState<any>();
    const { t } = useTranslation();


    function handleConfirmEmailResend() {
        agent.Account.resendEmailConfirm(email)
            .then(() => {
                showToast(
                    "verification.email.resent", MessageType.Success
                );
                setNotification({
                    message:
                        "verification.email.resent",
                    icon: <Info />,
                })
            })
            .catch((error) => {
                setNotification({
                    message:
                        "verification.email.error",
                    icon: <Alert />,
                })
                //console.log(error);
            });
    }

    return (
        <AuthenticationTemplate notification={notification} headingText={t("account.created")}>
            <Image mx="auto" w="172px" h="173px" mb="10" src={CreatedImage} />
            <Text
                mb="10"
                size="paragraph_semibold"
                color="gray.400"
                textAlign="center"
            >
                {t("check.email")}
            </Text>
            {email && (
                <Button variant="primary" onClick={handleConfirmEmailResend}>
                    {t("resend.email")}
                </Button>
            )}
        </AuthenticationTemplate>
    );
}
