import * as React from "react";
import { Flex, Heading, Button } from "@chakra-ui/react";
import { ArrowBack } from "assets/icons/onboarding";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import IconButton from "app/components/iconButton";
import WhitelistedAddressForm from "./WhitelistedAddressForm";
import { ProfileWhitelistedAddressCard } from "./ProfileWhitelistedAddressCard";
import { useTranslation } from "react-i18next";

interface Props {
    newAddress?: boolean;
}
export default observer(function ProfileWhitelistedAddresses({ newAddress, ...rest }: Props) {
    const {
        profileStore: { profile, isCurrentUser },
    } = useStore();
    const [addAddress, setAddAddress] = React.useState(newAddress);
    const { t } = useTranslation();
    return (
        <Flex color="white" direction="column">
            <Flex align="center" justify="space-between" mb="6">
                <Flex align="center">
                    {addAddress && (
                        <IconButton aria-label="go back" mr="4" icon={<ArrowBack w="20px" h="20px" />} onClick={() => setAddAddress(false)} />
                    )}
                    <Heading size="h4_bold">{addAddress ? t("profile.crypto.acc.add.header") : t("profile.crypto.acc.header")}</Heading>
                </Flex>
                {!addAddress && isCurrentUser && (
                    <Button
                        variant="overlineLink"
                        onClick={() => {
                            setAddAddress(true);
                            
                        }}
                    >
                        {t("add.address.bttn")}
                    </Button>
                )}
            </Flex>
            {addAddress ? (
                <Flex
                    py={{ base: "6", sm: "8" }}
                    px={{ base: "4", sm: "8" }}
                    borderRadius="0.75rem"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="gray.700"
                    bg="gray.800"
                    direction="column"
                >
                    <WhitelistedAddressForm setAddAddressMode={() => setAddAddress(false)} />
                </Flex>
            ) : (
                <>
                    {profile?.cryptoAccounts?.map(
                        (cryptoAccount) =>
                            cryptoAccount.whitelistedAddresses &&
                            cryptoAccount.whitelistedAddresses.length > 0 &&
                            cryptoAccount.whitelistedAddresses.map((whitelistedAddress) => (
                                <ProfileWhitelistedAddressCard
                                    key={whitelistedAddress.id}
                                    address={whitelistedAddress}
                                    currency={cryptoAccount.currency}
                                    mb="4"
                                />
                            ))
                    )}
                </>
            )}
        </Flex>
    );
});
