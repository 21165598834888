import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ArrowForward = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M13.75 9.985c0-.17-.034-.339-.101-.494a1.196 1.196 0 0 0-.288-.406l-5.565-5.11a.896.896 0 0 0-1.284.102.988.988 0 0 0 .067 1.342l4.866 4.468a.129.129 0 0 1 .043.098.134.134 0 0 1-.043.098L6.58 14.551a.994.994 0 0 0-.1 1.372c.082.097.18.175.291.23a.892.892 0 0 0 1.026-.158l5.563-5.108a1.2 1.2 0 0 0 .288-.407c.068-.155.103-.324.103-.495z"
      fill="#E5E7EA"
    />
  </Icon>
);

export const ArrowBack = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M6.25 10.015c0 .17.034.339.102.494.067.155.165.294.287.406l5.565 5.11a.896.896 0 0 0 1.284-.102.988.988 0 0 0-.067-1.342l-4.866-4.468a.13.13 0 0 1-.043-.098.135.135 0 0 1 .043-.098l4.866-4.468a.956.956 0 0 0 .227-.298.994.994 0 0 0-.128-1.074.927.927 0 0 0-.29-.23.891.891 0 0 0-1.026.158L6.64 9.113a1.201 1.201 0 0 0-.288.407 1.248 1.248 0 0 0-.103.495z"
      fill="#E5E7EA"
    />
  </Icon>
);

export const Upload = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="#1D1F25">
      <path d="M0 16.326a3.366 3.366 0 0 0 3.362 3.362h13.275A3.366 3.366 0 0 0 20 16.326v-1.562a1.021 1.021 0 1 0-2.04 0v1.562a1.323 1.323 0 0 1-1.323 1.322H3.362a1.324 1.324 0 0 1-1.321-1.322v-1.562a1.021 1.021 0 1 0-2.041 0v1.562z" />
      <path d="M10 16.102a1.632 1.632 0 0 0 1.632-1.632V6.834a.208.208 0 0 1 .209-.208h2.242a.816.816 0 0 0 .616-1.352L10.616.584a.842.842 0 0 0-1.232 0L5.301 5.278a.816.816 0 0 0 .615 1.348H8.16a.208.208 0 0 1 .208.209v7.635A1.633 1.633 0 0 0 10 16.102z" />
    </g>
  </Icon>
);

export const EmptyCheck = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M17.405 5.392 15.55 3.437a.331.331 0 0 0-.235-.103.318.318 0 0 0-.235.103l-7.833 8.24a.332.332 0 0 1-.235.102.318.318 0 0 1-.235-.102L4.922 9.72a.331.331 0 0 0-.235-.102.318.318 0 0 0-.235.102l-1.855 1.956a.359.359 0 0 0-.097.246c0 .093.035.182.097.247l4.18 4.395a.33.33 0 0 0 .235.102.318.318 0 0 0 .235-.102L17.405 5.88a.359.359 0 0 0 0-.49z"
      fill="currentColor"
    />
  </Icon>
);
