import { FlexProps, Flex, Text } from "@chakra-ui/react";
import ActivityIcon from "../../app/components/activityIcon";
import {
    getTransactionAmount,
    getTransactionCurrency,
    getTransactionTypeLabel,
    Transaction,
    TransactionStatus,
    TransactionType,
} from "app/models/transaction";
import { format } from "date-fns";
import { getTransactionIcon } from "app/models/fundingRequest";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
    isSelected?: boolean;
    transaction: Transaction;
}
export default observer(function TransactionCard({ isSelected = false, transaction, ...rest }: Props) {
    const { t } = useTranslation();
    return (
        <Flex
            bg="gray.800"
            borderWidth="1px"
            borderRadius="0.75rem"
            borderStyle="solid"
            borderColor="gray.700"
            pos="relative"
            justify="space-between"
            align="flex-start"
            py="4"
            px={{ base: "3", sm: "6" }}
            {...rest}
        >
            {isSelected ? (
                <Flex
                    position="absolute"
                    w="6px"
                    bg="yellow.800"
                    left="0"
                    top="25%"
                    bottom="25%"
                    borderTopRightRadius="0.75rem"
                    borderBottomRightRadius="0.75rem"
                />
            ) : null}
            <Flex align="center">
                <ActivityIcon
                    size="small"
                    color="white"
                    icon={getTransactionIcon(transaction.type)}
                    isPending={transaction.status == TransactionStatus.Pending}
                    mr="4"
                />
                <Flex direction="column">
                    <Text color="white" mb="1" size="paragraph_semibold">
                        {t(getTransactionTypeLabel(transaction.type).toLowerCase())} {getTransactionCurrency(transaction, transaction.type == TransactionType.Deposit)}
                        {transaction.type == TransactionType.Exchange && " -> " + getTransactionCurrency(transaction, true)}
                    </Text>
                    <Text color="gray.500" size="smallText_normal">
                        {format(transaction.date, "dd MMM yyyy h:mm aa")}
                    </Text>
                </Flex>
            </Flex>
            <Flex direction="column">
                <Text
                    size={transaction.status == TransactionStatus.Canceled ? "paragraph_strike" : "paragraph_normal"}
                    color="white"
                    mb="1"
                    textAlign="right"
                >
                    {getTransactionAmount(transaction, transaction.type == TransactionType.Deposit)}
                </Text>
                <Text size="smallText_normal" color="gray.500" textAlign="right">
                    {getTransactionAmount(transaction, transaction.type != TransactionType.Deposit)}
                </Text>
            </Flex>
        </Flex>
    );
});
