import { format } from "date-fns";
import { BoxProps, Text, Divider, Grid, Box } from "@chakra-ui/react";
import ActivityIcon from "../activityIcon";
import {
    getTransactionAmount,
    getTransactionCurrency,
    getTransactionTypeLabel,
    Transaction,
    TransactionStatus,
    TransactionType,
} from "app/models/transaction";
import { getTransactionIcon } from "app/models/fundingRequest";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
    transaction: Transaction;
    hasBreak?: boolean;
}

export default function TransactionSmallCard({ transaction, hasBreak = false, ...rest }: Props) {
    const { t } = useTranslation();
    return (
        <Grid templateColumns="40px 1fr" mb={hasBreak ? "4" : "0"} align="center" gap="4" maxW="100%" {...rest}>
            <ActivityIcon size="small" isPending={transaction.status == TransactionStatus.Pending} icon={getTransactionIcon(transaction.type)} />
            <Grid color="white" templateColumns="minmax(0,1fr) auto" gap="2">
                <Box>
                    <Text size="paragraph_semibold" mb="1" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" textAlign="start">
                        {t(getTransactionTypeLabel(transaction.type).toLowerCase())} {getTransactionCurrency(transaction, transaction.type == TransactionType.Deposit)}
                        {transaction.type == TransactionType.Exchange && " to " + getTransactionCurrency(transaction, true)}
                    </Text>
                    <Text size="smallText_normal" color="gray.500" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" textAlign="start">
                        {format(transaction.date, "dd MMM yyyy h:mm aa")}
                    </Text>
                </Box>
                <Box>
                    <Text size={transaction.status == TransactionStatus.Canceled ? "paragraph_strike" : "paragraph_normal"} mb="1" textAlign="right">
                        {getTransactionAmount(transaction, transaction.type == TransactionType.Deposit)}
                    </Text>
                    <Text size="smallText_normal" color="gray.500" textAlign="right">
                        {getTransactionAmount(transaction, transaction.type != TransactionType.Deposit)}
                    </Text>
                </Box>
            </Grid>
            {hasBreak ? <Divider flexBasis="100%" gridColumn="2/3" borderColor="gray.700" /> : null}
        </Grid>
    );
}
