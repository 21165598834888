import { Switch, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { useField } from "formik";

interface Props {
    placeholder?: string;
    name: string;
    isChecked?: boolean;
    type?: string;
    frontlabel: string;
    backlabel?: string;
    required?: boolean;
    disabled?: boolean;
}

export default function MySwitch({ name, isChecked, frontlabel, backlabel, ...rest }: Props) {
    const [field, meta, helpers] = useField(name);
    return (
        <FormControl mb="4" {...rest} isInvalid={meta.touched && !!meta.error}>
            <Stack align="center" direction="row">
                {frontlabel && <FormLabel>{frontlabel}</FormLabel>}
                <Switch {...field} colorScheme="yellow" isChecked={isChecked}/>
                {backlabel && <FormLabel>{backlabel}</FormLabel>}
            </Stack>
        </FormControl>
    );
}
