import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const IdCard = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M20 5.208a1.667 1.667 0 0 0-1.667-1.666h-5a.417.417 0 0 0-.416.416v1.25a.416.416 0 1 1-.834 0V3.125a2.5 2.5 0 0 0-5 0v2.083a.417.417 0 1 1-.833 0v-1.25a.417.417 0 0 0-.417-.416H1.667A1.667 1.667 0 0 0 0 5.208v12.5a1.666 1.666 0 0 0 1.667 1.667h16.666A1.666 1.666 0 0 0 20 17.708v-12.5zM8.75 2.917a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0zM2.083 17.708a.417.417 0 0 1-.416-.416v-8.75a.417.417 0 0 1 .416-.417h15.834a.417.417 0 0 1 .416.417v8.75a.417.417 0 0 1-.416.416H2.083z"
      fill="currentColor"
    />
    <path
      d="M16.042 11.25h-3.959a.625.625 0 1 0 0 1.25h3.959a.625.625 0 1 0 0-1.25zM15 13.75h-2.917a.625.625 0 1 0 0 1.25H15a.624.624 0 1 0 0-1.25zM6.663 13.125a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333zM6.667 13.958a3 3 0 0 0-2.75 1.93.417.417 0 0 0 .392.57h4.708a.415.415 0 0 0 .392-.57 3 3 0 0 0-2.742-1.93z"
      fill="currentColor"
    />
  </Icon>
);

export const Residence = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="currentColor">
      <path d="M13.007 18.069a.417.417 0 0 0-.455-.138 8.28 8.28 0 0 1-6.185-.442.208.208 0 0 1-.117-.188v-.958a2.083 2.083 0 0 1 .743-1.594 3.75 3.75 0 0 0-2.41-6.625h-2.43a.209.209 0 0 1-.2-.262 8.318 8.318 0 0 1 12.121-5.128.208.208 0 0 1-.102.39h-2.097a2.292 2.292 0 0 0 0 4.583c.28.001.557.058.815.167a.417.417 0 0 0 .375-.028 5.39 5.39 0 0 1 6.018.328.417.417 0 0 0 .658-.426A10.001 10.001 0 0 0 .639 6.473a10 10 0 0 0 5.478 12.742l.133.065v-.017a9.94 9.94 0 0 0 7.138.142.418.418 0 0 0 .174-.663 24.12 24.12 0 0 1-.555-.673z" />
      <path d="M15.833 8.332a4.172 4.172 0 0 0-4.167 4.167c0 2.642 3.715 6.387 3.873 6.545a.417.417 0 0 0 .59 0c.157-.158 3.87-3.903 3.87-6.545a4.172 4.172 0 0 0-4.166-4.167zm0 5.625a1.458 1.458 0 1 1 0-2.917 1.458 1.458 0 0 1 0 2.917z" />
    </g>
  </Icon>
);

export const Selfie = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="currentColor">
      <path d="M19.817 14.94a.626.626 0 0 0 0-.885l-.244-.244a1.377 1.377 0 0 1-.406-.977v-1.667a2.618 2.618 0 0 0-.772-1.864l-1.25-1.25a.207.207 0 0 1-.061-.148V3.334a2.5 2.5 0 0 0-2.5-2.5h-7.5a2.5 2.5 0 0 0-2.5 2.5V17.5a2.5 2.5 0 0 0 2.5 2.5h7.5a2.493 2.493 0 0 0 1.854-.825.208.208 0 0 1 .302-.008l.649.65a.625.625 0 0 0 .884-.884l-1.494-1.494a1.817 1.817 0 0 1-.529-1.272V14.6a.621.621 0 0 0-.183-.442l-1.847-1.845a.625.625 0 1 1 .884-.884l1.171 1.171a.625.625 0 0 0 .885-.883l-.015-.015a.207.207 0 0 1-.061-.147v-1.292a.208.208 0 0 1 .356-.148l.071.072a1.375 1.375 0 0 1 .406.98v1.667a2.618 2.618 0 0 0 .773 1.865c.31.306.691.676 1.127.24zm-5.233 3.394h-7.5a.833.833 0 0 1-.834-.834v-1.458a.209.209 0 0 1 .209-.208h8.333a.209.209 0 0 1 .208.208v.125c.001.501.125.994.361 1.436a.21.21 0 0 1 .014.167.833.833 0 0 1-.791.564zm-1.25-7.792a1.863 1.863 0 0 0-.541 1.167.208.208 0 0 1-.28.178l-.197-.072c-.47-.175-.393-1.405-.185-1.634a3.477 3.477 0 0 0 .904-2.694A2.27 2.27 0 0 0 10.834 5 2.27 2.27 0 0 0 8.63 7.488a3.477 3.477 0 0 0 .904 2.694c.208.23.286 1.458-.185 1.633a19.73 19.73 0 0 0-2.784 1.15.21.21 0 0 1-.286-.074.208.208 0 0 1-.029-.105V3.334a.833.833 0 0 1 .834-.834h7.5a.833.833 0 0 1 .833.834v6.525a.208.208 0 0 1-.17.205.21.21 0 0 1-.094-.003 1.878 1.878 0 0 0-1.82.481zM3.81 1.671l-.221-.031A1.256 1.256 0 0 1 2.528.579L2.5.358a.417.417 0 0 0-.825 0l-.035.22A1.256 1.256 0 0 1 .578 1.64l-.22.031a.417.417 0 0 0 0 .825l.22.032a1.256 1.256 0 0 1 1.062 1.06l.031.222a.417.417 0 0 0 .825 0l.032-.221A1.256 1.256 0 0 1 3.59 2.528l.22-.028a.417.417 0 0 0 0-.825v-.004z" />
    </g>
  </Icon>
);

export const EmptyX = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M2.293 16.292a1 1 0 1 0 1.414 1.414l6.174-6.174a.167.167 0 0 1 .236 0l6.175 6.175a1 1 0 0 0 1.414-1.414l-6.173-6.176a.166.166 0 0 1 0-.236l6.175-6.174a1 1 0 1 0-1.416-1.413l-6.175 6.172a.167.167 0 0 1-.236 0L3.707 2.294a1 1 0 0 0-1.414 1.413l6.173 6.174a.167.167 0 0 1 0 .236l-6.173 6.175z"
      fill="#E5E7EA"
    />
  </Icon>
);

export const KeyCode = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="currentColor">
      <path d="M0 5.417v5.833a1.65 1.65 0 0 0 1.63 1.667h7.174a.833.833 0 1 0 0-1.667h-6.72a.417.417 0 0 1-.417-.417l-.034-5a.417.417 0 0 1 .417-.416h15.867a.417.417 0 0 1 .416.416V8.75a.833.833 0 0 0 1.667 0V5.417a1.65 1.65 0 0 0-1.63-1.667H1.63A1.652 1.652 0 0 0 0 5.417z" />
      <path d="M5.417 9.583a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5zM10.417 9.583a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5zM18.235 12.93a.208.208 0 0 1-.178-.207v-.848a2.917 2.917 0 1 0-5.834 0v.848a.207.207 0 0 1-.178.206 1.25 1.25 0 0 0-1.072 1.238v4.583a1.25 1.25 0 0 0 1.25 1.25h5.834a1.25 1.25 0 0 0 1.25-1.25v-4.583a1.25 1.25 0 0 0-1.072-1.238zm-3.095 4.778a.833.833 0 1 1 0-1.666.833.833 0 0 1 0 1.666zm1.25-5a.208.208 0 0 1-.208.209h-2.084a.208.208 0 0 1-.208-.209v-.833a1.25 1.25 0 0 1 2.5 0v.833z" />
    </g>
  </Icon>
);
