import * as React from "react";
import Grid from "app/components/grid";
import { useBreakpointValue, Text, Heading, Divider } from "@chakra-ui/react";
import ResponsiveImage from "app/components/responsiveImage/ResponsiveImage";
import { useTranslation } from "react-i18next";

export default function Security() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });

    const { t } = useTranslation();
    return (
        <Grid bg="gray.900" color="white" overflow="hidden">
            <Text size="overline" color="yellow.800" gridColumn="1/-1" mb="3" textAlign="center">
                {t("landing.security.p1")}
            </Text>
            <Heading size={headerSize} gridColumn="1/-1" textAlign="center" mb="4">
                {t("landing.security.p2")}
            </Heading>
            <Text size="paragraph_normal" color="gray.500" textAlign="center" gridColumn="1/-1" maxW="60ch" mx="auto" mb="10">
                {t("landing.security.p3")}
            </Text>
            <Text size="paragraph_normal" color="gray.500" textAlign="center" gridColumn="1/-1" maxW="60ch" mx="auto" mb="10">
                {t("landing.security.p4")}
            </Text>
            <ResponsiveImage alt="platform" src="landing/platform" gridColumn="1/-1" minW={{ base: "200%", sm: "100%" }} />
            <Divider mt={{ base: "6", sm: "10", md: "20" }} mb={{ base: "10", md: "20" }} borderColor="gray.700" gridColumn="1/-1" />
        </Grid>
    );
}
