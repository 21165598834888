import * as React from "react";
import {
    Heading,
    Flex,
    Switch,
    Text,
    Input,
    InputGroup,
    InputLeftElement,
    Grid as CGrid,
    useBreakpointValue,
    InputRightElement,
} from "@chakra-ui/react";
import Grid from "app/components/grid";
import PortofolioBalance from "./PortofolioBalance";
import RecentActivity from "app/components/recentActivity";
import { Search, Close } from "assets/icons/home";
import Account from "./Account";
import FirstDeposit from "./FirstDeposit";
import WelcomingDocuments from "./WelcomingDocuments";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import { CurrencyLabel } from "app/models/rate";
import { history } from "../..";
import { FundingType } from "app/models/fundingRequest";
import { useTranslation } from "react-i18next";

export default observer(function Home() {
    const { t } = useTranslation();
    const {
        userStore: { user },
        fundingRequestStore: { setFundingType },
        profileStore: { getAssets, loadProfile, profile, loadingProfile, hasFunds },
        krakenStore: {  createHubConnection, clearRates, loadingInitial },
    } = useStore();
    // const [hasFunds, setHasFunds] = React.useState(false);
    const [isWelcome, setIsWelcome] = React.useState(true);
    const sortFontSize = useBreakpointValue({
        base: "smallText_normal",
        sm: "smallText_semibold",
    });
    const [search, setSearch] = React.useState("");
    const [hasBalance, setHasBalance] = React.useState(false);

    useEffect(() => {
        if (user) {
            // if (profile == null || profile.username != user.username) {
            loadProfile(user.username);
            // }
        }
    }, [loadProfile, user]);

    useEffect(() => {
        // if (profile?.kycValidated)
            createHubConnection();

        return () => {
            clearRates();
        };
    }, [createHubConnection, clearRates]);

    if (loadingProfile || loadingInitial) return <LoadingComponent />;

    return (
        <>
            {!profile?.kycValidated && <WelcomingDocuments handler={() => setIsWelcome(false)} /> }
            <Grid as="main" mt={{ base: "6", lg: "10" }}>
                {profile?.kycValidated && (
                    <>
                        <PortofolioBalance gridColumn={{ base: "1/-1", lg: "1 / span 7" }} hasFunds={hasFunds} />
                        {hasFunds ? (
                            <RecentActivity
                                mt={{ base: "0", sm: "4", lg: "0" }}
                                mb={{ base: "10", lg: "0" }}
                                gridColumn={{ base: "1/-1", lg: "5 span / 13" }}
                                order={{ base: 1, lg: 0 }}
                            />
                        ) : (
                            <FirstDeposit
                                handleClick={() => {
                                    setFundingType(FundingType.Deposit);
                                    history.push(`/funding`);
                                }}
                                mt={{ base: "0", sm: "4", lg: "0" }}
                                mb={{ base: "4", lg: "0" }}
                                gridColumn={{ base: "1/-1", lg: "5 span / 13" }}
                                order={{ base: -1, lg: 0 }}
                            />
                        )}
                    </>
                )}
                <Heading mt={{ base: "6", sm: "10" }} color="white" gridColumn="1/-1" size="h4_bold">
                    {t("your.portofolio")}
                </Heading>
                <CGrid gridColumn="1/-1" mt={{ base: "4", sm: "6" }} templateColumns={{ base: "1fr", md: "auto 1fr" }} gap="4">
                    <Flex align="center">
                        <InputGroup>
                            <InputLeftElement my="auto" bottom="0">
                                <Search w="20px" h="20px" color="gray.300" />
                            </InputLeftElement>
                            <Input variant="filled" placeholder={t("search.portofolio")} value={search} onChange={(e) => setSearch(e.target.value)} />
                            <InputRightElement my="auto" bottom="0" cursor="pointer" onClick={() => setSearch("")}>
                                {search ? <Close w="16px" h="16px" color="white" /> : null}
                            </InputRightElement>
                        </InputGroup>
                        {/* <Menu>
                            <MenuButton
                                ml={{ base: "2", sm: "4" }}
                                sx={{
                                    "> span": {
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            >
                                <Text as="span" size={sortFontSize} color={{ base: "white", sm: "gray.500" }} whiteSpace="nowrap">
                                    Sort by:
                                </Text>
                                <Text
                                    as="span"
                                    size="smallText_semibold"
                                    color="white"
                                    ml="1"
                                    whiteSpace="nowrap"
                                    display={{ base: "none", sm: "flex" }}
                                >
                                    Activity
                                </Text>
                                <ArrowDown ml="1" color="gray.500" />
                            </MenuButton>
                            <MenuList>
                                <MenuItem>Lorem</MenuItem>
                                <MenuItem>Ipsum</MenuItem>
                            </MenuList>
                        </Menu> */}
                    </Flex>
                    <Flex align="center" justify={{ base: "flex-start", md: "flex-end" }} w="100%">
                        <Switch colorScheme="yellow" onChange={() => setHasBalance(!hasBalance)} />
                        <Text as="span" ml="3" color="white" size="paragraph_semibold" whiteSpace="nowrap">
                            {t("hide.empty.acc")}
                        </Text>
                    </Flex>
                </CGrid>
                <Grid
                    spacerStyles={{ display: "none" }}
                    gridColumn="1/-1"
                    mt={{ base: "4", sm: "6" }}
                    gridProps={{ rowGap: { base: "0.5rem", sm: "1.5rem" } }}
                    mb={{ base: "4", lg: "20" }}
                >
                    {getAssets(undefined, hasBalance)
                        .filter(
                            (e) => e.currency.toLowerCase().indexOf(search) !== -1 || CurrencyLabel[e.currency].toLowerCase().indexOf(search) !== -1
                        )
                        // .sort((a) => isFiat(a.currency)? 0:1)
                        .map((asset) => (
                            <Account
                                key={asset.currency}
                                asset={asset}
                                gridColumn={{
                                    base: "span 2",
                                    md: "span 6",
                                    lg: "span 4",
                                    xl: "span 3",
                                }}
                            />
                        ))}
                </Grid>
            </Grid>
        </>
    );
});
