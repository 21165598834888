import { observer } from "mobx-react-lite";
import { NavLink, Route, Switch } from "react-router-dom";
import { Flex, Heading, useBreakpointValue, Grid as ChakraGrid, GridItem, Menu, MenuItem } from "@chakra-ui/react";
import Grid from "app/components/grid";
import PrivateRoute from "../../../app/layout/PrivateRoute";
import NotFound from "../../errors/NotFound";
import FundingFees from "./FundingFees";
import RateFees from "./RateFees";

export default observer(function FeesPage() {
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    return (
        <Grid mt={{ base: "6", lg: "10" }} pos="relative">
            <Heading mb="6" color="white" gridColumn="1/-1" size={headingSize}>
                Fees
            </Heading>
            <ChakraGrid templateColumns="repeat(5, 1fr)" gridColumn="1/-1" gap={6}>
                <GridItem colSpan={1}>
                    <Flex direction="column" cursor="pointer" mb="6">
                        <Menu>
                            <MenuItem
                                name="Funding Fees"
                                as={NavLink}
                                to={`/admin/fees/FundingFees`}
                                backgroundColor="gray.800"
                                //    onClick={handleItemClick}
                            >
                                Funding Fees
                            </MenuItem>
                            <MenuItem name="Exchange Rate Fees" as={NavLink} to={`/admin/fees/ratefees`} backgroundColor="gray.800">
                                Exchange Rate Fees
                            </MenuItem>
                        </Menu>
                    </Flex>
                </GridItem>
                <GridItem colSpan={4}>
                    <Route
                        path={"/admin/fees/(.+)"}
                        render={() => (
                            <Switch>
                                <PrivateRoute exact path="/admin/fees/ratefees" component={RateFees} />
                                <PrivateRoute exact path="/admin/fees/FundingFees" component={FundingFees} />
                                <Route component={NotFound} />
                            </Switch>
                        )}
                    ></Route>
                </GridItem>
            </ChakraGrid>
        </Grid>
    );
});
