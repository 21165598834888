import { Box, BoxProps, Flex, Menu, MenuButton, Text, Divider, Image, useBreakpointValue, MenuList, MenuItem } from "@chakra-ui/react";
import { CurrencyLabel } from "app/models/rate";
import { useTranslation } from "react-i18next";
import { BalanceDto } from "app/models/balanceDto";

interface Props extends BoxProps {
    balance: BalanceDto;
}

export default function BalanceCard({ balance, ...rest }: Props) {
    const currencySize = useBreakpointValue({
        base: "smallText_semibold",
        sm: "paragraph_semibold",
    });
    const symbolSize = useBreakpointValue({
        base: "caption_normal",
        sm: "smallText_normal",
    });

    const amountSize = useBreakpointValue({
        base: "paragraph_bold",
        sm: "h4_bold",
    });
    // const {
    //     fundingRequestStore: { setFundingType, setbalance },
    // } = useStore();

    const { t } = useTranslation();
    return (
        <Box border="1px solid gray.700" borderRadius="0.75rem" bg="gray.800" p="6" color="white" {...rest}>
            <Flex justify="space-between" align="flex-start">
                <Flex align="center">
                    <Image
                        src={"/coins/" + balance.currency.toLowerCase() + ".svg"}
                        alt={balance.currency}
                        w={{ base: "32px", sm: "40px" }}
                        h={{ base: "32px", sm: "40px" }}
                    />
                    <Flex direction="column" ml={{ base: "2", sm: "4" }} minW="0">
                        <Text size={currencySize} mb="1" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {CurrencyLabel[balance.currency]}
                        </Text>
                        <Text size={symbolSize} color="gray.500" textTransform="uppercase">
                            {balance.currency}
                        </Text>
                    </Flex>
                </Flex>
                
            </Flex>
            <Divider borderColor="gray.700" my={{ base: "3", sm: "4" }} />
            <Flex align="center" color={balance.balance > 0 ? "white" : "gray.500"}>
                {/* <Text size={amountSize}>{new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(balance.balance)}</Text> */}
                <Text size={amountSize}>{balance.balance}</Text>
                <Text size="paragraph_normal" ml="2" textTransform="uppercase">
                    {balance.currency}
                </Text>
            </Flex>
        </Box>
    );
}
