import * as React from "react";
import { Flex, Text } from "@chakra-ui/react";
import Grid from "app/components/grid";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();
    return (
        <Grid bg="black" py="6" color="white" mt={{ base: "5em", sm: "10em" }} bottom="0">
            <Flex gridColumn="1/-1" justify="space-between" align={{ base: "flex-start", sm: "center" }} direction={{ base: "column", sm: "row" }}>
                <Flex mb={{ base: "2", sm: "0" }}>
                    <Text size="paragraph_normal">© 2022 Prohash</Text>
                    {/* <Text size="paragraph_normal" ml="3">
            All rights reserved
          </Text> */}
                </Flex>
                <Flex sx={{ gap: "12px" }} direction={{ base: "column", sm: "row" }}>
                    <a href="https://coinatmradar.com/operator/985/prohash-bitcoin-atm-operator/" target="_blank">
                        <Text as="span" size="paragraph_normal">
                            {t("footer.ATMs")}
                        </Text>
                    </a>
                    <Link to="/faq">
                        <Text as="span" size="paragraph_normal">
                            {t("footer.FAQ")}
                        </Text>
                    </Link>
                    <Link to="/">
                        <Text as="span" size="paragraph_normal">
                            {t("footer.Contact")}
                        </Text>
                    </Link>
                    <Link to="/privacypolicy">
                        <Text as="span" size="paragraph_normal">
                        {t("footer.PP")}
                        </Text>
                    </Link>
                    <Link to="/aml">
                        <Text as="span" size="paragraph_normal">
                            {t("footer.AML")}
                        </Text>
                    </Link>
                    <Link to="/terms">
                        <Text as="span" size="paragraph_normal">
                            {t("footer.T&C")}
                        </Text>
                    </Link>
                </Flex>
            </Flex>
        </Grid>
    );
}
