import * as React from "react";
import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  Box,
  PopoverProps,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";

interface Props extends PopoverProps {
  triggerContent: React.ReactNode;
  children: React.ReactNode;
}

export default function PopoverMenu({
  triggerContent,
  children,
  isOpen,
  onOpen,
  onClose,
  ...rest
}: Props) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMaximumSM] = useMediaQuery("(max-width: 47.5em)");
  return (
    <Popover
      placement="bottom-start"
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Box onClick={onOpen}>{triggerContent}</Box>
      </PopoverTrigger>
      <Portal>
        {isOpen ? (
          <Box
            d={{ base: "flex", md: "none" }}
            pos="fixed"
            inset="0"
            bg="rgba(9, 9, 11, 0.5)"
            zIndex="0"
          />
        ) : null}
        <Box
          __css={{
            ".chakra-popover__popper": {
              minW: "unset!important",
              position: {
                base: "fixed!important",
                md: "absolute!important",
              },
              height: {
                base: "unset!important",
                md: "min-content!important",
              },
              inset: {
                base: "0!important",
              },
              insetInlineStart: {
                base: "0!important",
                md: "auto!important",
              },
              insetInlineEnd: {
                base: "0!important",
                md: "auto!important",
              },
              insetBlockStart: {
                base: "30vh!important",
                md: "0!important",
              },
              transform: {
                base: isMaximumSM ? "unset!important" : "unset",
                md: "unset",
              },
            },
          }}
        >
          <PopoverContent
            outline="none"
            _focus={{ boxShadow: "none" }}
            minW={{ base: "0", md: "550px" }}
            height="100%"
            maxHeight={{ base: "auto", md: "30vh" }}
          >
            <Flex direction="column" maxHeight="100%" minH="0">
              {children}
            </Flex>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
}
