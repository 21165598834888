import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { MessageType } from "app/models/message";
import { showToast } from "app/components/toast/Toast";
import * as Yup from "yup";
import agent from "../../app/api/agent";
import MyTextInput from "app/common/form/MyTextInput";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Button, Text } from "@chakra-ui/react";
import { NotificationProps } from "app/components/notification";
import { useTranslation } from "react-i18next";

export default observer(function ForgotPwd() {
    const { t } = useTranslation();
    const [notification, setNotification] = React.useState<NotificationProps>( {
        message:
            t("reset.passwrd.notification"),
        type: MessageType.Info,
    });

    const validationSchema = Yup.object({
        email: Yup.string().required("Required").email("Invalid format"),
    });
   
    return (
        <Formik
            initialValues={{
                email: "",
                error: null,
            }}
            onSubmit={(values, { setErrors }) =>
                agent.Account.sendPasswordResetEmail(values.email)
                    .then(() => {
                        showToast(
                            "forgot.password.email", MessageType.Success
                        );
                    })
                    // .catch(() => {
                    //     setErrors({ error: "Invalid email" });
                    // })
                    .finally(() => {
                        setNotification({
                            message:
                                "forgot.password.info",
                            type: MessageType.Info,
                        });
                    })
            }
            validationSchema={validationSchema}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <AuthenticationTemplate
                    notification={notification}
                    headingText={t("forgot.passwrd")}
                >
                    <Text mb="4" size="paragraph_semibold" color="gray.400">
                        {t("reset.passwrd.mess")}
                    </Text>
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput
                            name="email"
                            placeholder="Email"
                            isRequired
                        />

                        <Button
                            variant="primary"
                            mb="6"
                            mt="6"
                            isLoading={isSubmitting}
                            loadingText="Reset Password"
                            disabled={!isValid || !dirty || isSubmitting}
                            type="submit"
                            isFullWidth
                        >
                            {t("reset.passwrd.bttn")}
                        </Button>
                    </Form>
                </AuthenticationTemplate>
            )}
        </Formik>
    );
});
