import * as React from "react";
import { Flex, FlexProps, Text, Heading, Menu, MenuButton, MenuList, MenuItem, Divider, useBreakpointValue, HStack, Box } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import Tag from "./Tag";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { Currency, CurrencyColor, CurrencyLabel, isFiat } from "app/models/rate";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
    hasFunds: boolean;
}

export default observer(function PortofolioBalance({ hasFunds, ...rest }: Props) {
    const {
        profileStore: { getAssets },
        krakenStore: { getRate },
    } = useStore();
    const decimalSize = useBreakpointValue({ base: "h3_bold", sm: "h1_bold" });
    const fractionalSize = useBreakpointValue({ base: "h4_bold", sm: "h2_bold" });
    const currencySize = useBreakpointValue({
        base: "paragrah_normal",
        sm: "h3_normal",
    });
    const EurToRon = 5;
    const [selectedCurrency, setSelectedCurrency] = useState(Currency.RON);
    const { t } = useTranslation();

    function getAssetsUsingCurrency(refCurrency: Currency) {
        return getAssets(undefined, true)
            .map((asset) => {
                asset.balance = asset.currency == refCurrency ? asset.balance : getToAmount(asset.balance, asset.currency, refCurrency);
                return asset;
            })
            .sort((a, b) => b.balance - a.balance);
    }

    function getTotalBalance(refCurrency: Currency) {
        var decimals = isFiat(refCurrency) ? 2 : 8;
        return parseFloat(
            getAssetsUsingCurrency(refCurrency)
                .reduce((acc, x) => {
                    acc.balance += x.balance;
                    return acc;
                })
                .balance.toFixed(decimals)
        );
    }

    function getAssetsPercentageUsingCurrency(refCurrency: Currency) {
        var total = getTotalBalance(refCurrency);
        return getAssetsUsingCurrency(refCurrency).map((asset) => {
            asset.balance = parseFloat(((asset.balance * 100) / total).toFixed(0));
            return asset;
        });
    }

    function getToAmount(amount: number, fromCurrency: Currency, toCurrency: Currency) {
        var decimals = isFiat(toCurrency) ? 2 : 8;
        var retValue = !!amount ? parseFloat((amount * getAproxRate(fromCurrency, toCurrency)).toFixed(decimals)) : 0;
        return retValue;
    }

    function getEurRonRate() {
        var rate = getRate(Currency.RON, Currency.EUR);
        if (rate.value == 0) {
            return EurToRon;
        } else {
            return rate.value;
        }
    }

    //ugly funct
    function getAproxRate(fromCurrency: Currency, toCurrency: Currency) {
        if (!isFiat(fromCurrency) && !isFiat(toCurrency))
            return getRate(fromCurrency, Currency.EUR)!.value * getRate(Currency.EUR, toCurrency)!.value;
        if (isFiat(fromCurrency) && isFiat(toCurrency)) {
            var EuroRonValue = getEurRonRate();
            return toCurrency == Currency.RON ? EuroRonValue : fromCurrency == Currency.RON ? 1 / EuroRonValue : 1;
        }

        return getRate(fromCurrency, toCurrency)!.value;
    }
    return (
        <Flex
            pos="relative"
            bg="yellow.800"
            borderRadius="0.75rem"
            direction="column"
            maxW="100%"
            p={{ base: "6", sm: "10" }}
            overflow="hidden"
            {...rest}
        >
            <Text size="overline" mb="4">
                {t("portofolio.Balance")}
            </Text>
            <Flex align="center" mb={hasFunds ? "6" : "0"}>
                {hasFunds ? (
                    <Heading size={decimalSize} as="h1" sx={{ fontVariantNumeric: "tabular-nums" }}>
                        {getTotalBalance(selectedCurrency)}
                        {/* <Heading as="span" size={fractionalSize}>
                            .89
                        </Heading> */}
                    </Heading>
                ) : (
                    <Heading size={decimalSize} as="h1" sx={{ fontVariantNumeric: "tabular-nums" }}>
                        0.00
                    </Heading>
                )}
                {hasFunds ? (
                    <Menu>
                        <MenuButton
                            bg="transparent"
                            ml="3"
                            p="0"
                            zIndex="1"
                            _hover={{ bg: "transparent" }}
                            _focus={{ bg: "transparent" }}
                            sx={{
                                span: {
                                    display: "flex",
                                    alignItems: "center",
                                },
                            }}
                        >
                            <Text size={currencySize} as="span" color="black">
                                {selectedCurrency}
                            </Text>
                            <ArrowDown ml="3" w="16px" h="16px" my="auto" color="black" />
                        </MenuButton>
                        <MenuList zIndex="2">
                            <Flex direction="column" overflowY="auto" maxH="90px">
                                {getAssets(undefined, false).map((asset, i) => (
                                    <MenuItem key={asset.currency} onClick={() => setSelectedCurrency(asset.currency)}>
                                        {CurrencyLabel[asset.currency]}
                                    </MenuItem>
                                ))}
                            </Flex>
                        </MenuList>
                    </Menu>
                ) : (
                    <Text size={currencySize} as="span" color="black" ml="3">
                        {selectedCurrency}
                    </Text>
                )}
            </Flex>
            {hasFunds ? (
                <>
                    <Divider mb="8" mx={{ base: "-6", sm: "-10" }} w="auto" />
                    <HStack mb="-3" pb="3" px="6" mx="-6" spacing="3" overflowY="auto" zIndex="1">
                        {getAssetsPercentageUsingCurrency(Currency.EUR).map((asset) => (
                            <Tag key={asset.currency} color={CurrencyColor[asset.currency]} text={asset.currency} percentage={asset.balance} />
                        ))}
                    </HStack>
                </>
            ) : null}
            <Box pos="absolute" top="0" left="0" right="0" bottom="0" sx={{ mask: 'url("/mask.svg") center center / cover' }} bg="white" zIndex="0" />
        </Flex>
    );
});
