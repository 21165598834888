import * as React from "react";
import Grid from "app/components/grid";
import { Heading, Flex, BoxProps, useBreakpointValue } from "@chakra-ui/react";
import Notification, { NotificationProps } from "app/components/notification";
import { store } from "app/stores/store";

interface Props extends BoxProps {
  children: React.ReactNode;
  headingText: string;
  notification?: NotificationProps;
  rightChild?: React.ReactNode;
  hasTop? : boolean;
}

export default function BasicPageTemplate({
  children,
  headingText,
  notification = store.commonStore.notification,
  rightChild,
  hasTop = false,
  ...rest
}: Props) {
  const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
  return (
    <Grid mb="10"  pos="relative" {...rest} mt={hasTop ? "5em" : "0"} height="90%">
      {notification ? (
        <Flex
          gridColumn="1/-1"
          justify="center"
          order={{ base: 1, md: 0 }}
          pos={{ base: "static", md: "absolute" }}
          mt="6"
          top="0"
          left="0"
          right="0"
        >
          <Notification {...notification} />
        </Flex>
      ) : null}
      <Heading
        size={headingSize}
        gridColumn={{ base: "1/-1", lg: "3 / span 8", xl: "4 / span 6" }}
        textAlign="center"
        color="white"
        mb="10"
        mt={{ base: "10", md: "32" }}
      >
        {headingText}
      </Heading>
      <Flex
        direction="column"
        justify="center"
        gridColumn={{ base: "1/-1", lg: "3 / span 8", xl: "4 / span 6" }}
        px="6"
        py="10"
        bg="gray.800"
        borderRadius="0.75rem"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray.700"
      >
        {children}
      </Flex>
      {rightChild ? (
        <Flex
          d={{ base: "none", xl: "flex" }}
          gridColumn="10 / span 3"
          align="start"
        >
          <Flex
            direction="column"
            justify="center"
            p="6"
            bg="gray.800"
            borderRadius="0.75rem"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="gray.700"
          >
            {rightChild}
          </Flex>
        </Flex>
      ) : null}
    </Grid>
  );
}
