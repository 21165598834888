import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Text, useBreakpointValue } from "@chakra-ui/react";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import { DocumentType } from "../../app/models/document";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import DocumentUploadForm from "features/documents/DocumentUploadForm";

export default observer(function Five() {
    const hideLogo = useBreakpointValue({ base: true, sm: false });
    const { profileStore, userStore: { user } } = useStore();

    
    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user]);
    return (
        <>
            <OnboardingSteps steps={5} currentStep={4} />
            <AuthenticationTemplate
                headingText="Verify you identity (2 of 3)"
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                        <Text size="paragraph_normal" color="white" mb="6">
                            Identity verification can take up to 3 days to be complete. We’ll let you know when the verification is complete.
                        </Text>
                        <Text size="paragraph_normal" color="white">
                            Don’t worry, you’ll still be able to access our platform in the meantime.
                        </Text>
                    </>
                }
            >
                <DocumentUploadForm documentType={DocumentType.Selfie} submitButtonText="Continue"  saveCallback={() => history.push("/onboarding/six") }/>
                
            </AuthenticationTemplate>
        </>
    );
});
