import * as React from "react";
import { Button, Flex, Grid as CGrid, Text, Heading, useBreakpointValue, Box } from "@chakra-ui/react";
import { Star } from "assets/icons/landing";
import { history } from "../..";
import { useTranslation } from "react-i18next";


export default function Call() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();
    return (
        <CGrid placeItems="center" bg="yellow.800" py={{ base: "10", md: "20" }} px="4" pos="relative">
            <Flex direction={{ base: "column", md: "row" }} align="center" color="black" gridColumn="1/-1" justify="center" mb="10" zIndex="1">
                <Star order={{ base: -1, md: 0 }} mb={{ base: 3, md: 0 }} w="40px" h="40px" mr={{ base: "0", md: "3", lg: "4" }} />
                <Heading size={headerSize} textAlign="center">
                    {t("landing.call.p1")}
                </Heading>
            </Flex>
            <Text textAlign="center" maxW="60ch" mb="10" zIndex="1">
                {t("landing.call.p2")}
            </Text>
            <Button variant="secondary" onClick={() => history.push("/security/register")} zIndex="1">
                {t("landing.call.button")}
            </Button>
            <Box
                pos="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                sx={{ mask: 'url("/mask2.svg") center center / cover' }}
                bg="white"
                zIndex="0"
            />
        </CGrid>
    );
}
