import IconButton from "app/components/iconButton";
import { Heading, Flex, useBreakpointValue } from "@chakra-ui/react";
import { ArrowBack } from "assets/icons/onboarding";
import Grid from "app/components/grid";
import RecentActivity from "app/components/recentActivity";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "app/layout/LoadingComponent";
import Notification from "app/components/notification";
import { MessageType } from "app/models/message";
import FundingCard from "./FundingCard";
import { useTranslation } from "react-i18next";

export default observer(function DepositWithdraw() {
    const { t } = useTranslation();
    const {
        userStore: { user },
        profileStore: { isProfileValidated, loadProfile, loadingProfile },
        fundingRequestStore: { showSummary, setShowSummary },
    } = useStore();
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    

    useEffect(() => {
        if (user) {
            // if (profile == null || profile.username != user.username) {
                loadProfile(user.username);
            // }
        }
    }, [loadProfile,  user]);

    
    if (loadingProfile) return <LoadingComponent />;

    // This show summary is always false, but when you connect it to the AddRemoveCurrency component, based on the state of it, it should show.
    // It's used to get back from a summary of a transaction in case you changed your mind

    return (
        <>
            <Grid mt={{ base: "6", lg: "10" }}>
                {!isProfileValidated && (
                    <Notification
                        gridColumn="1/-1"
                        type={MessageType.Error}
                        message={t("complete.acc.message")}
                        my="6"
                        messageStyle={{ fontWeight: "semibold" }}
                    />
                )}
                {showSummary ? (
                    <Flex gridColumn="1/-1" mb="10" align="center">
                        <IconButton
                            aria-label="go back"
                            icon={<ArrowBack />}
                            onClick={() => {
                                // setAmount(0);
                                setShowSummary(false);
                            }}
                        />
                        <Heading ml="8" color="white" size={headingSize}>
                            {t("deposit/withdraw")}
                        </Heading>
                    </Flex>
                ) : (
                    <Heading mb="10" color="white" gridColumn="1/-1" size={headingSize}>
                        {t("deposit/withdraw")}
                    </Heading>
                )}

                <FundingCard gridColumn={{ base: "1/-1", lg: "1 / span 6", xl: "1 / span 7" }} mb={{ base: "6", lg: "0" }} />
                <RecentActivity applyFilter={true} gridColumn={{ base: "1/-1", lg: "7 / span 6", xl: "8 / span 5" }} mb={{ base: "20", lg: "0" }} height="min-content" />
            </Grid>
        </>
    );
});
