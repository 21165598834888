import { WhitelistedAddress } from "app/models/cryptoAccount";
import { Currency } from "app/models/rate";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { AccountOperation } from "../models/transaction";
import { FundingRequestFormValues, FundingType } from "../models/fundingRequest";
import { MessageType } from "app/models/message";
import { showToast } from "app/components/toast/Toast";
import { Asset } from "app/models/asset";
import { useTranslation } from "react-i18next";

export default class FundingRequestStore {
    loading = false;
    fundingRequestFormValues: FundingRequestFormValues | null = null;
    accountOperations: AccountOperation[] | null = null; //TODO: remove
    fundingType: FundingType = FundingType.Deposit;
    showSummary: boolean = false;
    amount: number = 0;
    asset: Asset = new Asset({ currency: Currency.EUR, balance: 0 });
    
    selectedAddress: WhitelistedAddress | undefined;

    constructor() {
        makeAutoObservable(this);

        // reaction(
        //     () => this.filterParams,
        //     () => {
        //         // this.pagingParams = new PagingParams();
        //         // this.transactionRegistry.clear();
        //         this.clearTransactions()
        //         // this.loadTransactions();
        //     }
        // )
    }

    setFundingType = (fundingType: FundingType) => {
        this.fundingType = fundingType;
    };

    setAsset = (asset: Asset) => {
        this.asset = asset;
    };

    setSelectedAddress = (selectedAddress: WhitelistedAddress | undefined) => {
        this.selectedAddress = selectedAddress;
    };
    setFundingRequestFormValues =(fundingRequestFormValues: FundingRequestFormValues | null) => {
        this.fundingRequestFormValues = fundingRequestFormValues;
    }
    

    setShowSummary = (show: boolean) => {
        this.showSummary = show;
    };

    createFundingRequest = async (fundingRequest: FundingRequestFormValues) => {
        this.loading = true;
        try {
            // const response = await agent.FundingRequests.create(fundingRequest);
            const response = await agent.Transactions.create(fundingRequest);
            
            //console.log(response);
            runInAction(() => {
                // response.date = new Date(response.date);
                // this.fundingRequests?.unshift(response);
                this.fundingRequestFormValues = null;
                this.selectedAddress = undefined;
                this.loading = false;
                showToast("request.created", MessageType.Success);
            });
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    // loadFundingRequests = async (accountId: string, isDebit: boolean, topRows?: number) => {
    //     this.loading = true;
    //     try {
    //         var params = new URLSearchParams();
    //         params.append("AccountId", accountId);
    //         params.append("IsDebit", isDebit.toString());
    //         topRows && params.append("TopRows", topRows.toString());
    //         const result = await agent.FundingRequests.list(params);
    //         runInAction(() => {
    //             result.forEach((req) => {
    //                 req.date = new Date(req.date + "Z");
    //             });
    //             this.fundingRequests = result;
    //             //console.log(result);
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };

    // processFundingRequest = async (requestId: string) => {
    //     this.loading = true;
    //     try {
    //         const result = await agent.FundingRequests.process(requestId);
    //         runInAction(() => {
    //             var request = this.fundingRequests?.find((p) => p.id == requestId);
    //             if (request) {
    //                 request.status = FundingRequestStatus.Approved;
    //                 toast.success("Request processed");
    //             }
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };
    // cancelFundingRequest = async (requestId: string) => {
    //     this.loading = true;
    //     try {
    //         const result = await agent.Transactions.cancel(requestId);
    //         runInAction(() => {
    //             var request = this.fundingRequests?.find((p) => p.id == requestId);
    //             if (request) {
    //                 request.status = FundingRequestStatus.Canceled;
    //                 toast.success("Request canceled");
    //             }
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };

    //not realy used - transaction history
    //TODO: remove
    // loadAccountOperations = async (accountId: string, admin?: boolean) => {
    //     this.loading = true;
    //     try {
    //         var params = new URLSearchParams();
    //         params.append("AccountId", accountId);
    //         // params.append("IsDebit", isDebit.toString());
    //         var result: AccountOperation[];
    //         if (admin) {
    //             result = await agent.AccountOperations.listAll(params);
    //         } else {
    //             result = await agent.AccountOperations.list(params);
    //         }
    //         runInAction(() => {
    //             result.forEach((req) => {
    //                 req.date = new Date(req.date + "Z");
    //             });
    //             this.accountOperations = result;
    //             // //console.log(result);
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         this.accountOperations = null;
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };

    getAppAccountIban = async (currency: string) => {
        this.loading = true;
        try {
            var result = await agent.AppAccounts.readIban(currency);
            runInAction(() => (this.loading = false));
            return result.data;
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
        }
    };

    // loadFiatAccount = async (id: string) => {
    //     this.loading = true;
    //     try {
    //         const fiatAccount = await agent.FiatAccounts.get(id);
    //         runInAction(() => {
    //             this.fiatAccount = fiatAccount;
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };

    // createFIATAccount = async (fiatAccount: FIATAccountFormValues) => {
    //     this.loading = true;
    //     try {
    //         const response = await agent.FiatAccounts.create(fiatAccount);
    //         runInAction(() => {
    //             if (this.profile) {
    //                 this.profile.fiatAccounts?.push(response);
    //             }
    //             this.loading = false;
    //         });
    //     } catch (error) {
    //         //console.log(error);
    //         runInAction(() => (this.loading = false));
    //     }
    // };

    // validateFIATAccount = async (fiatAccountId: string) => {
    //     this.loading = true;
    //     try {
    //         await agent.FiatAccounts.validate(fiatAccountId);
    //         runInAction(() => {
    //             if (this.profile) {
    //                 var account = this.profile.fiatAccounts?.find(
    //                     (p) => p.id == fiatAccountId
    //                 );
    //                 //console.log(account);
    //                 if (account) {
    //                     account.validated = true;
    //                     //console.log(account?.document.id);
    //                     var doc = this.profile.documents?.find(
    //                         (p) => p.id == account?.document.id
    //                     );
    //                     if (doc) doc.validated = true;
    //                     toast.success("Account validated");
    //                 }
    //                 this.loading = false;
    //             }
    //         });
    //     } catch (error) {
    //         runInAction(() => (this.loading = false));
    //         //console.log(error);
    //     }
    // };

    // deleteFIATAccount = async (fiatAccountId: string) => {
    //     this.loading = true;
    //     try {
    //         // await agent.FiatAccounts.delete(fiatAccountId);
    //         runInAction(() => {
    //             if (this.profile) {
    //                 this.profile.fiatAccounts = this.profile.fiatAccounts?.filter(
    //                     (p) => p.id !== fiatAccountId
    //                 );
    //                 this.loading = false;
    //             }
    //         });
    //     } catch (error) {
    //         runInAction(() => (this.loading = false));
    //         //console.log(error);
    //     }
    // };
}
