import * as React from "react";
import Grid from "app/components/grid";
import { useBreakpointValue, Grid as CGrid, Text, Heading, Divider, Flex } from "@chakra-ui/react";
import ResponsiveImage from "app/components/responsiveImage/ResponsiveImage";
import { Star, CryptoTrade, Scan } from "assets/icons/landing";
import { Add } from "assets/icons/activity";
import { useTranslation } from "react-i18next";

interface Props {
    color: string;
    imageSrc: string;
    headerText: string;
    order: "normal" | "reverse";
    showDivider?: boolean;
}

export default function Action({ imageSrc, showDivider = false, order, color, headerText }: Props) {
    const { t } = useTranslation();
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const smallHeaderSize = useBreakpointValue({
        base: "paragraph_bold",
        lg: "h4_bold",
    });
    return (
        <Grid bg="gray.900" pb={!showDivider ? "28" : "0"}>
            <CGrid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gridColumn="1/-1" gap="6">
                <Flex direction="column" color="white">
                    <Flex mb="10" direction={{ base: "column", md: "row" }} align="center">
                        <Heading size={headerSize} textAlign={{ base: "center", md: "left" }}>
                            {headerText}
                        </Heading>
                        <Star order={{ base: -1, md: 0 }} mb={{ base: 3, md: 0 }} w="40px" h="40px" ml={{ base: "0", md: "3", lg: "4" }} />
                    </Flex>
                    {[
                        {
                            title: (order === "reverse") ? t("landing.p10"): t("landing.p5"),
                            icon: <Add w="20px" h="20px" color={color} />,
                            details: (order === "reverse") ? t("landing.p10.1"): t("landing.p5.2"),
                        },
                        {
                            title: (order === "reverse") ? t("landing.p11"): t("landing.p7"),
                            icon: <Add w="20px" h="20px" color={color} />,
                            details: (order === "reverse") ? t("landing.p11.1"): t("landing.p7.1"),
                        },
                        {
                            title: (order === "reverse") ? t("landing.p12"): t("landing.p8"),
                            icon: <Add w="20px" h="20px" color={color} />,
                            details: (order === "reverse") ? t("landing.p12.1"): t("landing.p8.1"),
                        },
                    ].map(({ title, icon, details }) => (
                        <Flex key={title} mb="6">
                            <CGrid
                                minW="44px"
                                minH="44px"
                                w="0"
                                h="0"
                                placeItems="center"
                                bg="black"
                                borderStyle="solid"
                                borderWidth="1px"
                                borderColor="gray.700"
                                borderRadius="50%"
                                mr="4"
                            >
                                {icon}
                            </CGrid>
                            <Flex d="column">
                                <Text size={smallHeaderSize} mb="3">
                                    {title}
                                </Text>
                                <Text color="gray.500" size="paragraph_normal" maxW="40ch">
                                    {details}
                                </Text>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
                <ResponsiveImage alt="" order={{ base: 0, md: order === "reverse" ? -1 : 0 }} src={imageSrc} />
            </CGrid>
            {showDivider ? (
                <Divider mt={{ base: "6", sm: "10", md: "20" }} mb={{ base: "10", md: "20" }} borderColor="gray.700" gridColumn="1/-1" />
            ) : null}
        </Grid>
    );
}
