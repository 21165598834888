import * as React from "react";
import { Menu, MenuButton, Text, MenuProps, MenuList, Divider, Flex, Button, Checkbox, useDisclosure } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props extends MenuProps {
    initialValues: string[];
    items: {text: string, value: string}[];
    children: React.ReactNode;
    applyHandler: (values: string[]) => void;
    buttonText: string;
    formReset?: () => void;
}

export default observer(function FilterPopover({ initialValues, items, applyHandler, children, buttonText, formReset, ...rest }: Props) {
    const { t } = useTranslation();
    // const [values, setValues] = useState<string[]>(filterParams.currency ? filterParams.currency : []);
    const [values, setValues] = useState<string[]>(initialValues);
    const { isOpen, onOpen, onClose } = useDisclosure();
    



    function handleClearFilters() {
        // var newFilterParams = new LedgerFilterParams();
        // newFilterParams.iban = filterParams.iban;
        // setFilterParams(newFilterParams);
        setValues([]);
    }

    function handleCheckboxChanged(event: React.ChangeEvent<HTMLInputElement>) {
        
        var selectedValue = event.target.value;
        event.stopPropagation();
        const index = values.indexOf(selectedValue);
        

        if (index > -1) {
            setValues([...values.slice(0, index), ...values.slice(index + 1)]);
        } else {
            setValues([...values, selectedValue]);
        }
    }

    const applySelection = () => {};

    return (
        <Menu {...rest} isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {setValues(initialValues); onClose()}}>
            <MenuButton
                sx={{
                    "> span": {
                        display: "flex",
                        alignItems: "center",
                    },
                }}
            >
                <Text as="span" size="smallText_semibold" color="white" whiteSpace="nowrap">
                    {buttonText}
                </Text>
                <ArrowDown ml="1" color="gray.500" />
            </MenuButton>

            <MenuList p="6" d="flex" flexDir="column" color="white" minW={{base: "280px", sm:"350px"}} >
                
                {items.map((option) => (
                    <Checkbox
                        name={option.value}
                        key={option.value}
                        colorScheme="yellow"
                        value={option.value}
                        onChange={handleCheckboxChanged}
                        isChecked={values?.includes(option.value)}
                        mb="6"
                    >
                        {t(option.text.toLowerCase())}
                    </Checkbox>
                ))}
                <Divider my="6" borderColor="gray.700" />
                <Flex w="100%" align="center" justify="space-between">
                    <Button variant="overlineLink" onClick={handleClearFilters}>
                        {/* onClick={() => resetForm({ values: filterParams })} */}
                        {t("claer.bttn")}
                    </Button>
                    <Button variant="primary" onClick={() => {applyHandler(values); onClose()}}>
                        {t("apply.bttn")}
                    </Button>
                </Flex>
            </MenuList>
        </Menu>
    );
});
