import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { Text, Flex, Button, Box } from "@chakra-ui/react";
import { FIATAccount } from "app/models/fiatAccount";
import { useState } from "react";
import { CurrencyLabel } from "app/models/rate";
import Row from "app/components/row";
import { Loader } from "assets/icons/home";
import { EmptyCheck } from "assets/icons/onboarding";
import agent from "app/api/agent";
import { createDownloadLink, Document } from "app/models/document";
import { Download } from "assets/icons/activity";
import { useTranslation } from "react-i18next";

interface Props {
    account: FIATAccount;
}

export default observer(function ProfileFiatAccountOverview({ account }: Props) {
    const { t } = useTranslation();
    const {
        profileStore: { isCurrentUser, validateFIATAccount },
        userStore: { isAuthorized },
    } = useStore();
    const [files, setFiles] = useState<any>([]);

    function handleDownloadDocument(doc: Document) {
        if (isAuthorized)
            agent.Documents.adminDownload(doc.id).then((response) => {
                createDownloadLink(doc.name, response);
            });
        else if (isCurrentUser)
            agent.Documents.download(doc.id).then((response) => {
                createDownloadLink(doc.name, response);
            });
    }

    return (
        // <Flex justify="space-between" align={{ base: "flex-end", sm: "center" }} w="100%" sx={{ gap: "12px" }}>
        <>
            <Row label="Status" mb="2">
                <Flex align="center">
                    {account.validated ? (
                        <>
                            <EmptyCheck w="20px" h="20px" color="green.500" />
                            <Text ml="3" size="caption_bold">
                                {t("validated")}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Loader w="20px" h="20px" color="yellow.800" />
                            <Text ml="3" size="caption_bold">
                                {t("verifying")}
                            </Text>
                        </>
                    )}
                </Flex>
            </Row>
            <Row label="Bank Name" mb="2">
                <Text color="white" size="paragraph_semibold">
                    {account.bank}
                </Text>
            </Row>
            <Row label="IBAN" mb="2">
                <Text color="white" size="paragraph_semibold">
                    {account.iban}
                </Text>
            </Row>
            <Row label="SWIFT/BIC" mb="2">
                <Text color="white" size="paragraph_semibold">
                    {account.swift}
                </Text>
            </Row>
            <Row label="Currency" mb="2">
                <Text color="white" size="paragraph_semibold">
                    {CurrencyLabel[account.currency]}
                </Text>
            </Row>
            <Row label="Label" mb="2">
                <Text color="white" size="paragraph_semibold">
                    {account.description}
                </Text>
            </Row>
            <Row label="Creation Date" mb="2">
                <Text color="white" size="paragraph_semibold">
                    {format(account.date, "dd MMM yyyy h:mm aa")}
                </Text>
            </Row>
            <Row label="Document" mb="2">
                <Text
                    color="white"
                    size="paragraph_semibold"
                    cursor="pointer"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    textAlign="right"
                    onClick={() => handleDownloadDocument(account.document)}
                >
                    {account.document.name}
                    <Download w="20px" h="20px" ml="2" />
                </Text>

                {/* <Button
                    color="gray.300"
                    variant="overlineLink"
                    onClick={() => handleDownloadDocument(account.document)}
                    rightIcon={<Download w="20px" h="20px" />}
                    
                >
                    {account.document.name}
                </Button> */}
            </Row>
            {isAuthorized && !account.validated && (
                <Button variant="primary" isFullWidth onClick={() => validateFIATAccount(account.id)} mt="6">
                    {t("validate")}
                </Button>
            )}
        </>

        //</Flex>
    );
});
