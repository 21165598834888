import { Flex, Heading, useBreakpointValue } from "@chakra-ui/react";
import { Search } from "assets/icons/home";
import Grid from "app/components/grid";

export default function NotFound() {
    const headingSize = useBreakpointValue({ base: "h4_semibold", sm: "h4_bold" });
    return (
        <Grid mt={{ base: "16", lg: "20" }}>
            <Flex direction="column" align="center" gridColumn="1/-1" my="10">
                <Search w="50px" h="50px" color="white" mb="6" />
                <Heading color="white" gridColumn="1/-1" size={headingSize} mb="6">
                    Oops - we've looked everywhere and could not find this.
                </Heading>
            </Flex>
        </Grid>
    );
}
