import { Currency } from "./rate";

export interface Exchange {
    fromCurrency: Currency;
    toCurrency: Currency;
    amountFrom: number;
    amountTo?: number;
    rateValue?: number;
}

export class Exchange implements Exchange {
    constructor(init?: ExchangeFormValues) {
        Object.assign(this, init);
      }
}
export class ExchangeFormValues {
    fromCurrency: Currency | '' = '';
    toCurrency: Currency | '' = '';
    amountFrom?: number;
}