import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, Center, Flex, Heading } from '@chakra-ui/react';
import { useStore } from '../../app/stores/store';

export default observer(function ServerError() {
    const {commonStore} = useStore();
    return (
        <Center>
            <Flex direction="column">
            <Heading size='h2_bold' color="white"> Server Error</Heading>
            <Heading size='h3_normal' color='red' >{commonStore.error?.message}</Heading>
            {commonStore.error?.details &&
                <Flex direction="column">
                    <Heading size='h3_normal' color='teal' >Stack trace</Heading>

                    <code style={{marginTop: '10px', color:"white"}}>{commonStore.error.details}</code>
                </Flex>
                
            }
            </Flex>
        </Center>
    )
})