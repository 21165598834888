import * as React from "react";
import { Button, Flex, Grid as CGrid, Text, Heading, Input, useBreakpointValue } from "@chakra-ui/react";
import Grid from "app/components/grid";
import ResponsiveImage from "app/components/responsiveImage/ResponsiveImage";
import { Star } from "assets/icons/landing";
import { history } from "../..";
import { useTranslation } from "react-i18next";

export default function Intro() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
        xl: "h1_bold",
    });

    const { t } = useTranslation();

    return (
        <>
            <Grid bg="gray.900" pos="relative" overflowX="hidden">
                <CGrid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gridColumn="1/-1" w="100%">
                    <ResponsiveImage
                        alt=""
                        src="landing/rectangle"
                        pos="absolute"
                        left="0"
                        right="0"
                        w={{ base: "100%", lg: "50%" }}
                        zIndex="0"
                        height="100%"
                    />
                    <Flex position="relative" zIndex="1" direction="column" justify="center" color="white" p="6" pl="0">
                        <Text size="overline" mb="4">
                            {t("landing.intro.p1")}
                        </Text>
                        <Heading size={headerSize} mb={{ base: "1", xl: "2" }}>
                            {t("landing.intro.p2")}
                            <Star ml="4" minW="32px" minH="32px" w="0" h="0" color="teal.500" transform="rotate(45deg)" />
                        </Heading>
                        <Heading size={headerSize} mb={{ base: "1", xl: "2" }}>
                            {t("landing.intro.p3")}
                        </Heading>
                        <Heading size={headerSize} mb="4" whiteSpace="nowrap">
                            {t("landing.intro.p4")}
                        </Heading>
                        <Text size="paragraph_regular" color="gray.500" mb={{ base: "6", md: "10" }}>
                            {t("landing.intro.p5")}
                        </Text>
                        <CGrid templateColumns="auto auto" gap="3" mb="6">
                            <Input variant="flushed" type="email" placeholder="Email" />
                            <Button variant="primary" w="min-content" onClick={() => history.push("/security/register")}>
                                {t("landing.intro.p6")}
                            </Button>
                        </CGrid>
                        {/* <Flex mb="10">
                            <Text size="overline">
                                {t("landing.intro.p7")}
                                <Text ml="2" as="span" size="overline" color="gray.600">
                                {t("landing.intro.p8")}
                                </Text>
                            </Text>
                            <Text size="overline" mx={{ base: "4", sm: "8" }}>
                                |
                            </Text>
                            <Text size="overline">
                                {t("landing.intro.p9")}
                                <Text ml="2" as="span" size="overline" color="gray.600">
                                    {t("landing.intro.p10")}
                                </Text>
                            </Text>
                        </Flex> */}
                    </Flex>
                    <Flex height="100%" width="100%" d={{ base: "none", md: "flex" }} overflowY="clip">
                        <ResponsiveImage
                            alt="phone hand"
                            src="landing/hand"
                            w="100%"
                            h="100%"
                            transform={{
                                base: "none",
                                md: "rotate(-20deg) translateX(10%) translateY(15%)",
                                lg: "none",
                            }}
                            overflowY="clip"
                            bg={{ base: "gray.100", md: "transparent", lg: "gray.900" }}
                            objectFit="contain"
                            zIndex="1"
                            pl={{ base: "0", lg: "6" }}
                        />
                    </Flex>
                </CGrid>
            </Grid>
            <CGrid templateColumns="1fr auto 1fr" mb={{ base: "3", md: "0" }} bg={{ base: "black", md: "gray.900" }}>
                <Flex h="1px" backgroundImage="linear-gradient(90deg, rgba(52, 56, 65, 0) 0%, var(--chakra-colors-gray-700) 100%)" />
                <Star px="2" color="teal.500" boxSizing="content-box" w="24px" h="24px" transform="translateY(-50%)" zIndex="2" />
                <Flex h="1px" backgroundImage="linear-gradient(-90deg, rgba(52, 56, 65, 0) 0%, var(--chakra-colors-gray-700) 100%)" />
            </CGrid>
            <Flex w="100%" overflow="hidden">
                <Flex d={{ base: "flex", md: "none" }} overflowX="clip" bg="gray.900" w="100%" transform="translateY(10%)">
                    <ResponsiveImage
                        alt="phone hand"
                        src="landing/hand"
                        bg="transparent"
                        w="100%"
                        h="100%"
                        transform="rotate(-15deg) translateX(20%) translateY(-10%)"
                        objectFit="contain"
                    />
                </Flex>
            </Flex>
        </>
    );
}
