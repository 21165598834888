import * as React from "react";
import Notification from "app/components/notification";
import Grid from "app/components/grid";
import { Heading, Tabs, Tab, TabList, TabPanels, TabPanel, Flex, FlexProps, Text, VStack } from "@chakra-ui/react";
import ProfileInfoSection from "./ProfileInfoSection";
import ProfileAddressSection from "./ProfileAddressSection";
import ProfileNotice from "./ProfileNotice";
import ProfileDocuments from "./ProfileDocuments";
import ProfilePassword from "./ProfilePassword";
import ProfileAccounts from "./ProfileAccounts";
import { MessageType } from "app/models/message";
import { useStore } from "app/stores/store";
import { useEffect } from "react";
import LoadingComponent from "app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import ProfileTFAForm from "./ProfileTFAForm";
import ProfileWhitelistedAddresses from "./ProfileWhitelistedAddresses";
import { useParams } from "react-router-dom";
import { history } from "../..";
import { useTranslation } from "react-i18next";
import ProfileLoginInfos from "./ProfileLoginInfos";
import { PagingParams } from "app/models/pagination";
import { LoginInfoFilterParams } from "app/models/profile";

function SecondaryNotice(props: FlexProps) {
    const { t } = useTranslation();
    return (
        <Flex height="min-content" p="6" bg="gray.800" borderRadius="0.75rem" direction="column" align="flex-start" {...props}>
            <Text color="white" size="h4_bold" mb="3">
                {t("profile.notice.black1")}
            </Text>
            <Text size="paragraph_normal" color="white">
                {t("profile.notice.black2")}
            </Text>
        </Flex>
    );
}

function SecondaryFiatNotice(props: FlexProps) {
    const { t } = useTranslation();
    return (
        <Flex height="min-content" p="6" bg="gray.800" borderRadius="0.75rem" direction="column" pos="relative" overflow="hidden" {...props}>
            <Text color="white" size="h4_bold" mb="3">
                {t("bank.statement.info.p1")}
            </Text>
            <Text size="paragraph_normal" color="white">
                {t("bank.statement.info.p2")}
            </Text>
            <Text size="paragraph" color="white">
                {t("bank.statement.info.p3")}
            </Text>
            <Text size="paragraph" color="white">
                {t("bank.statement.info.p4")}
            </Text>
            <Text size="paragraph" color="white">
                {t("bank.statement.info.p5")}
            </Text>
        </Flex>
    );
}

export default observer(function ProfileSettings() {
    const { t } = useTranslation();
    const { tabIndex, profileName } = useParams<{ tabIndex: string; profileName: string }>();
    const {
        profileStore: {
            loadingProfile,
            loadProfile,
            profile,
            isCurrentUser,
            loginInfoFilterParams,
            loadLoginInfos,
            loginInfos,
            setLoginInfoFilterParams,
            setLoginInfoPagingParams,
        },
        userStore: { user, isAuthorized, loadTFAKey },
    } = useStore();
    const [authEnabled, setAuthEnabled] = React.useState(false);
    const [authFlowOpen, setAuthFlowOpen] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(true);

    useEffect(() => {
        //console.log("ProfileSettings - " + profileName);
        // setLoginInfoPagingParams(new PagingParams(1, 3));
        if (profileName) {
            //console.log("a");
            if (isAuthorized) {
                loadProfile(profileName).then(() => {
                    //console.log("then 1");
                    if (loginInfos.length < 1) {
                        loadLoginInfos(true);
                    }
                });
            } else {
                history.push("/not-found");
            }
        } else if (user) {
            loadProfile(user.username).then(() => {
                //console.log("then 2/" + loginInfos.length);
                if (loginInfos.length < 1) {
                    loadLoginInfos();
                }
            });
            if (!user.tfaGoogleEnabled) loadTFAKey();
        }

        return () => {
            //console.log("clean-up");
            setLoginInfoFilterParams(new LoginInfoFilterParams(), false);
        };
    }, [loadProfile, user, user!.tfaGoogleEnabled, profileName, loadLoginInfos, setLoginInfoFilterParams]);

    if (loadingProfile) return <LoadingComponent />;

    return (
        <>
            {/* {!authFlowOpen ? ( */}
            <Grid mt={{ base: "6", lg: "10" }}>
                {!isCurrentUser ? (
                    <Notification
                        gridColumn="1/-1"
                        type={MessageType.Info}
                        message={`Profile ${profileName} is ${!profile?.kycValidated ? " not " : ""} valid`}
                        my="6"
                        mt="-3"
                        messageStyle={{ fontWeight: "semibold" }}
                    />
                ) : (
                    <>
                        {profile?.kycValidated ? (
                            <>
                                {showNotification && (
                                    <Notification
                                        gridColumn="1/-1"
                                        type={MessageType.Success}
                                        message={t("valid.profile.mess")}
                                        my="6"
                                        mt="-3"
                                        messageStyle={{ fontWeight: "semibold" }}
                                        handleClose={() => setShowNotification(false)}
                                    />
                                )}
                            </>
                        ) : (
                            <Notification
                                gridColumn="1/-1"
                                type={MessageType.Info}
                                message="Your account is not validated yet. Make sure you complete your profile and upload the documents."
                                my="6"
                                mt="-3"
                                messageStyle={{ fontWeight: "semibold" }}
                            />
                        )}
                    </>
                )}
                <Heading color="white" gridColumn="1/-1" size="h2_bold" mb="6">
                    {t("profile.settings.page")}
                </Heading>
                <Tabs variant="enclosed" gridColumn="1/-1" defaultIndex={tabIndex ? parseInt(tabIndex) : 0}>
                    <TabList maxW={{ base: "100%", lg: "60%" }}>
                        <Tab>{t("profile.settings.tab")}</Tab>
                        <Tab>{t("profile.documents.tab")}</Tab>
                        <Tab>{t("profile.security.tab")}</Tab>
                        <Tab>{t("profile.fiat.acc.tab")}</Tab>
                        <Tab>{t("profile.Whitelist.tab")}</Tab>
                    </TabList>
                    <TabPanels mb="20">
                        <TabPanel>
                            <Grid w="100%" spacerStyles={{ d: "none" }} pos="relative" mb="8">
                                <ProfileInfoSection gridColumn={{ base: "1/-1", lg: "1 / span 7" }} />
                                {isCurrentUser && (
                                    <ProfileNotice
                                        d={{ base: "none", lg: "flex" }}
                                        gridColumn="span 4 / 13"
                                        height="min-content"
                                        position="sticky"
                                        top="4"
                                    />
                                )}
                            </Grid>
                            <Grid w="100%" spacerStyles={{ d: "none" }} pos="relative">
                                <ProfileAddressSection gridColumn={{ base: "1/-1", lg: "1 / span 7" }} />
                                {isCurrentUser && <SecondaryNotice gridColumn="span 4 / 13" top="4" d={{ base: "none", lg: "flex" }} />}
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Grid w="100%" spacerStyles={{ d: "none" }} pos="relative" mb="8">
                                <ProfileDocuments gridColumn={{ base: "1/-1", lg: "1 / span 7" }} />
                                {isCurrentUser && (
                                    <ProfileNotice
                                        d={{ base: "none", lg: "flex" }}
                                        gridColumn="span 4 / 13"
                                        height="min-content"
                                        position="sticky"
                                        top="4"
                                    />
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Grid w="100%" spacerStyles={{ d: "none" }} pos="relative" mb="8">
                                <Flex direction="column" gridColumn={{ base: "1/-1", lg: "1 / span 7" }}>
                                    {isCurrentUser && (
                                        <>
                                            <ProfilePassword mb="8" />
                                            <ProfileTFAForm mb="8" />
                                        </>
                                    )}
                                    <ProfileLoginInfos />
                                </Flex>
                                {isCurrentUser && (
                                    <ProfileNotice
                                        d={{ base: "none", lg: "flex" }}
                                        gridColumn="span 4 / 13"
                                        height="min-content"
                                        position="sticky"
                                        top="4"
                                    />
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Grid w="100%" spacerStyles={{ d: "none" }} pos="relative" mb="8">
                                <Flex direction="column" gridColumn={{ base: "1/-1", lg: "1 / span 7" }}>
                                    <ProfileAccounts newAccount={parseInt(tabIndex) === 3} />
                                </Flex>
                                {isCurrentUser && (
                                    <VStack
                                        gridColumn="span 4 / 13"
                                        position="sticky"
                                        align="flex-start"
                                        d={{ base: "none", lg: "flex" }}
                                        height="min-content"
                                        top="4"
                                    >
                                        <ProfileNotice  />
                                        <SecondaryFiatNotice top="20" />
                                    </VStack>
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Grid w="100%" spacerStyles={{ d: "none" }} pos="relative" mb="8">
                                <Flex direction="column" gridColumn={{ base: "1/-1", lg: "1 / span 7" }}>
                                    <ProfileWhitelistedAddresses newAddress={parseInt(tabIndex) === 4} />
                                </Flex>
                                {isCurrentUser && (
                                    <ProfileNotice
                                        d={{ base: "none", lg: "flex" }}
                                        gridColumn="span 4 / 13"
                                        height="min-content"
                                        position="sticky"
                                        top="4"
                                    />
                                )}
                            </Grid>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Grid>
        </>
    );
});
