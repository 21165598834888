import * as React from "react";
import Grid from "app/components/grid";
import {
    useBreakpointValue,
    Text,
    Heading,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionItemProps,
} from "@chakra-ui/react";
import { Star, EmptyMinus, EmptyPlus } from "assets/icons/landing";
import { useTranslation } from "react-i18next";

interface QuestionProps extends AccordionItemProps {
    question: string;
    answer: string;
}

function Question({ question, answer, ...rest }: QuestionProps) {
    return (
        <AccordionItem {...rest}>
            {({ isExpanded }) => (
                <>
                    <h2>
                        <AccordionButton>
                            <Text size="paragraph_semibold" flex="1" textAlign="left">
                                {question}
                            </Text>
                            {isExpanded ? (
                                <Flex align="center" justify="center" minW="40px" minH="40px" w="0" h="0" borderRadius="50%" bg="gray.700">
                                    <EmptyMinus w="20px" h="20px" />
                                </Flex>
                            ) : (
                                <Flex align="center" justify="center" minW="40px" minH="40px" w="0" h="0" borderRadius="50%" bg="gray.700">
                                    <EmptyPlus w="20px" h="20px" />
                                </Flex>
                            )}
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>{answer}</AccordionPanel>
                </>
            )}
        </AccordionItem>
    );
}

export default function Questions() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();
    return (
        <Grid bg="gray.900" pb={{ base: 10, md: 20, lg: 28 }}>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                color="white"
                gridColumn="1/-1"
                justify="center"
                mt={{ base: "10", md: "20", lg: "28" }}
                mb="10"
            >
                <Heading size={headerSize} textAlign={{ base: "center", md: "left" }}>
                    {t("landing.p9")}
                </Heading>
                <Star order={{ base: -1, md: 0 }} mb={{ base: 3, md: 0 }} w="40px" h="40px" ml={{ base: "0", md: "3", lg: "4" }} />
            </Flex>
            <Accordion color="white" gridColumn="1/-1" variant="black">
                <Question question={t("FAQ.p1")} answer={t("FAQ.p1.1")} mb="3" />
                <Question question={t("FAQ.p2")} answer={t("FAQ.p2.1")} mb="3" />
                <Question question={t("FAQ.p3")} answer={t("FAQ.p3.1")} mb="3" />
                <Question question={t("FAQ.p4")} answer={t("FAQ.p4.1")} mb="3" />
                <Question question={t("FAQ.p5")} answer={t("FAQ.p5.1")} mb="3" />
            </Accordion>
        </Grid>
    );
}
