import * as React from "react";
// import { Intro, Rates, Security, Action, Steps, Questions, Call } from "features/landing";
import { history } from "../..";
import UnauthenticatedNavigation from "app/components/unauthenticatedNavigation";
import { useStore } from "app/stores/store";
import Footer from "app/layout/Footer";

import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Heading, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { getAccountOperationCategoryLabel } from "app/models/transaction";

export default function PrivacyPolicy() {
    const headerSize = useBreakpointValue({
        base: "h3_bold",
        lg: "h2_bold",
    });
    const { t } = useTranslation();

    const toc = [];


    const headers = [
        "PrivacyP.p19",
        "PrivacyP.p30",
        "PrivacyP.p33",
        "PrivacyP.p39",
        "PrivacyP.p42",
        "PrivacyP.p46",
        "PrivacyP.p50",
        "PrivacyP.p54",
        "PrivacyP.p57",
        "PrivacyP.p64",
        "PrivacyP.p66",
        "PrivacyP.p69",
        "PrivacyP.p71",
    ];

    
    const getContent = () => {
        const rows = [];
        for (let i = 19; i <= 72; i++) {
            if (headers.includes(`PrivacyP.p${i}`)) {
                rows.push(
                    <Text id={`${i}`} size="paragraph_semibold" textAlign="left" maxW="90ch" mb="3" mt="10">
                        {t(`PrivacyP.p${i}`)}
                    </Text>
                );
            } else {
                rows.push(
                    <Text key={i} size="paragraph_normal" textAlign="left" mb="3">
                        {t(`PrivacyP.p${i}`)}
                    </Text>
                );
            }
        }
        return rows;
    };

    return (
        <Grid color="white" mt={"3em"} height="90%" px="1">
            <Flex direction="column" align="start" textAlign="left" maxW="container.xl" mx="auto" px="6">
                <Heading size={headerSize} mb="2">
                    {t("PrivacyP.p1")}
                </Heading>
                <Heading size={headerSize} mb="2">
                    {t("PrivacyP.p2")}
                </Heading>
                <Text size="overline" color="gray.500" mb="10">
                    {t("PrivacyP.p3")}
                </Text>

                <Flex
                    direction="column"
                    align="start"
                    textAlign="left"
                    mx="auto"
                    px="6"
                    py="10"
                    bg="gray.800"
                    borderRadius="0.75rem"
                    borderStyle="solid"
                    borderWidth="1px"
                    borderColor="gray.700"
                >
                    <Text size="paragraph_normal" textAlign="left" align="left" mb="10">
                        {t("PrivacyP.p4")}
                    </Text>
                    <Heading size="h4_bold" textAlign="left" mb="3">
                        {t("PrivacyP.p5")}
                    </Heading>
                    {headers.map((item) => (
                        <Link href={`#${item.substring(10)}`} key={item.substring(10)} size="paragraph_normal" textAlign="left">
                            {t(item)}
                        </Link>
                    ))}
                    {getContent()}
                </Flex>
            </Flex>
        </Grid>
    );
}
