import * as React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

interface Props extends ImageProps {
  src: string;
}

export default function ResponsiveImage({ src, ...rest }: Props) {
  return (
    <Image
      srcSet={`${src}@3x.png 3x,${src}@2x.png 2x,${src}.png 1x`}
      src={`${src}.png`}
      {...rest}
    />
  );
}
