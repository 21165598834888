import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { MessageType } from "app/models/message";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
    saveCallback: () => void;
    editMode?: boolean;
    submitButtonText?: string;
}

export default observer(function ProfileAddressForm({ saveCallback, editMode = false, submitButtonText = "Save" }: Props) {
    const {
        profileStore: { profile, updateProfile, loading },
        commonStore: { setNotification },
    } = useStore();
    const today = new Date();

    //really needed?
    const validationSchema = Yup.object({
        street: Yup.string().required("Required"),
    });
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{
                street: profile?.homeAddress ? profile?.homeAddress.street : "",
                zipCode: profile?.homeAddress ? profile?.homeAddress?.zipCode : "",
                town: profile?.homeAddress ? profile?.homeAddress?.town : "",
                county: profile?.homeAddress ? profile?.homeAddress?.county : "",
                country: profile?.homeAddress ? profile?.homeAddress?.country : "",
            }}
            onSubmit={(values) => {
                updateProfile({ homeAddress: { ...values, id: profile?.homeAddress?.id } }).then(() => {
                    // setNotification({
                    //     message: "Profile updated",
                    //     type: MessageType.Info,
                    // });
                    saveCallback();
                });
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <MyTextInput
                        type="text"
                        name="street"
                        placeholder={t("profile.address.plch")}
                        label={t("profile.address")}
                        isRequired
                        disabled={!editMode}
                    />
                    <MyTextInput type="text" name="zipCode" placeholder={t("zip.code")} label={t("zip.code")} disabled={!editMode} />
                    <MyTextInput name="town" type="text" placeholder={t("profile.city")}  label={t("profile.city")}  isRequired disabled={!editMode} />
                    <MyTextInput type="text" name="county" placeholder={t("profile.county")}  label={t("profile.county")}  isRequired disabled={!editMode} />
                    <MyTextInput type="text" name="country" placeholder={t("profile.country")}  label={t("profile.country")} mb="10" isRequired disabled={!editMode} />
                    {editMode && (
                        <Button
                            variant="primary"
                            type="submit"
                            isLoading={isSubmitting && loading}
                            isDisabled={!isValid || !dirty}
                            loadingText={submitButtonText}
                            isFullWidth
                        >
                            {submitButtonText}
                        </Button>
                    )}
                </Form>
            )}
        </Formik>
    );
});
