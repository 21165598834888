import { Currency } from "./rate";

export interface BalanceDto {
    currency: Currency;
    balance: number;
}

export class BalanceFilterParams {
    startDate?: Date;
    endDate?: Date;
}
// export class BalanceDto implements BalanceDto {}