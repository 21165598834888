import { Text, Button, Flex, Spacer } from "@chakra-ui/react";
import Notification from "../../../app/components/notification";
import ReadOnlyField from "../../../app/components/readOnlyField";
import Balance from "../../../app/components/balance";
import { MessageType } from "app/models/message";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { getCurrencyConfirmations, isFiat } from "app/models/rate";
import QRCode from "qrcode.react";
import { useState } from "react";
import { FundingRequestFormValues } from "app/models/fundingRequest";
import { FIATAccount } from "app/models/fiatAccount";
import { history } from "../../..";
import { useTranslation } from "react-i18next";

export default observer(function DepositDetails() {
    const { t } = useTranslation();
    const {
        userStore: { user },
        profileStore: { getAssets, createCryptoAccount, loadProfile, accounts },
        fundingRequestStore: { loading, setShowSummary, setFundingRequestFormValues, fundingType, asset, setAsset },
    } = useStore();
    // const [asset, setAsset] = useState(new Asset({ currency: Currency.EUR, balance: 0 }));
    const [error, setError] = useState(false);
    const [amount, setAmount] = useState(0);

    function getCode() {
        var acc = accounts?.find((x) => x.currency === asset.currency) as FIATAccount;
        return acc.code || "";
    }

    return (
        <>
            <Text size="paragraph_normal" color="gray.400" mb="2">
                {t("asset")}
            </Text>
            <Balance
                variant="none"
                mb="10"
                hasError={error}
                setError={setError}
                setAmount={setAmount}
                hasInput={isFiat(asset.currency)}
                assets={getAssets()}
                setAsset={setAsset}
                asset={asset}
            />
            {asset.accountId ? (
                <>
                    {!isFiat(asset.currency) ? (
                        <>
                            <Flex align="center" justify="space-between" mb="3">
                                <Spacer />
                                <QRCode value={`${asset.currency}:${asset.iban}`} includeMargin={true} size={200} />
                                <Spacer />
                            </Flex>

                            <ReadOnlyField label={`${asset.currency} Address`} value={asset.iban!} mb="3" />
                            <Notification
                                messageStyle={{ fontWeight: "normal" }}
                                message={`Only ${asset.currency} can be sent to this address. Payments with other cryptocurrencies will not be credited. Network confirmations required: ${getCurrencyConfirmations(asset.currency)}`}
                                type={MessageType.Info}
                                bg="gray.700"
                                mb="10"
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                variant="primary"
                                w="100%"
                                onClick={() => {
                                    setFundingRequestFormValues(
                                        new FundingRequestFormValues({
                                            accountId: asset.accountId || "",
                                            amount: amount,
                                            isDebit: false,
                                            currency: asset.currency,
                                            externalId: asset.iban,
                                            additionalInfo: getCode(),
                                        })
                                    );
                                    setShowSummary(true);
                                }}
                                isDisabled={error || amount === 0}
                            >
                                {t("continue.bttn")}
                            </Button>
                        </>
                    )}
                </>
            ) : (
                <>
                    {!isFiat(asset.currency) ? (
                        <>
                            <Notification
                                messageStyle={{ fontWeight: "normal" }}
                                message={t("invalid.acc.message.Withdraw")}
                                type={MessageType.Error}
                                bg="gray.700"
                                mb="10"
                            />
                            <Button
                                variant="primary"
                                w="100%"
                                onClick={() => createCryptoAccount(asset.currency).then(() => loadProfile(user?.username!))}
                                isLoading={loading}
                            >
                                {t("generate.address.btn")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Notification
                                messageStyle={{ fontWeight: "normal" }}
                                message={t("invalid.acc.message")}
                                type={MessageType.Error}
                                bg="gray.700"
                                mb="10"
                            />
                            <Button variant="primary" w="100%" onClick={() => history.push("/profiles/settings/3")}>
                                {t("add.bank.acc.btn")}
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    );
});
