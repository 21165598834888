import * as React from "react";
import { Flex, FlexProps, Heading, Button } from "@chakra-ui/react";
import ProfileAddressForm from "features/profiles/ProfileAddressForm";
import { useStore } from "app/stores/store";
import { useTranslation } from "react-i18next";

export default function ProfileAddressSection(props: FlexProps) {
    const { t } = useTranslation();
    const [disabled, setDisabled] = React.useState(true);
    const {
        profileStore: { isCurrentUser },
    } = useStore();

    return (
        <Flex direction="column" {...props}>
            <Flex mb="6" justify="space-between" color="white">
                <Heading size="h4_bold">{t("profile.address")}</Heading>
                {isCurrentUser && (
                    <Button variant="overlineLink" onClick={() => setDisabled((d) => !d)}>
                        {disabled ? "Edit" : "Cancel"}
                    </Button>
                )}
            </Flex>
            <Flex
                py={{ base: "6", sm: "8" }}
                px={{ base: "4", sm: "8" }}
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                direction="column"
            >
                <ProfileAddressForm saveCallback={() => setDisabled(true)} editMode={!disabled} />
            </Flex>
        </Flex>
    );
}
