import * as React from "react";
import { Flex, Heading, Button } from "@chakra-ui/react";
import { ArrowBack } from "assets/icons/onboarding";
import ProfileFiatAccountForm from "features/profiles/ProfileFiatAccountForm";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import { ProfileFiatAccountCard } from "./ProfileFiatAccountCard";
import { FIATAccount } from "app/models/fiatAccount";
import IconButton from "app/components/iconButton";
import ProfileFiatAccountOverview from "features/profiles/ProfileFiatAccountOverview";
import { useTranslation } from "react-i18next";

interface Props {
    newAccount?: boolean;
}

export default observer(function ProfileAccounts({ newAccount, ...rest }: Props) {
    const {
        profileStore: { profile, isCurrentUser },
    } = useStore();
    const [viewAccount, setViewAccount] = React.useState(newAccount);
    const [selectedAccount, setSelectedAccount] = React.useState<FIATAccount>();
    const { t } = useTranslation();
    return (
        <Flex color="white" direction="column">
            <Flex align="center" justify="space-between" mb="6">
                <Flex align="center">
                    {viewAccount && (
                        <IconButton aria-label="go back" mr="4" icon={<ArrowBack w="20px" h="20px" />} onClick={() => setViewAccount(false)} />
                    )}
                    <Heading size="h4_bold">
                        {viewAccount
                            ? selectedAccount
                                ? t("profile.fiat.acc.view.header")
                                : t("profile.fiat.acc.add.header")
                            : t("profile.fiat.acc.header")}
                    </Heading>
                </Flex>
                {!viewAccount && isCurrentUser && (
                    <Button
                        variant="overlineLink"
                        onClick={() => {
                            setViewAccount(true);
                            setSelectedAccount(undefined);
                        }}
                    >
                        {t("add.acc.bttn")}
                    </Button>
                )}
            </Flex>
            {viewAccount ? (
                <Flex
                    py={{ base: "6", sm: "8" }}
                    px={{ base: "4", sm: "8" }}
                    borderRadius="0.75rem"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="gray.700"
                    bg="gray.800"
                    direction="column"
                >
                    {selectedAccount ? (
                        <ProfileFiatAccountOverview account={selectedAccount} />
                    ) : (
                        <ProfileFiatAccountForm saveCallback={() => setViewAccount(false)} editMode={viewAccount} submitButtonText="Save" />
                    )}
                </Flex>
            ) : (
                <>
                    {profile?.fiatAccounts?.map((fiatAccount) => (
                        <ProfileFiatAccountCard
                            key={fiatAccount.currency}
                            account={fiatAccount}
                            viewCallback={() => {
                                setSelectedAccount(fiatAccount);
                                setViewAccount(true);
                            }}
                            mb="4"
                        />
                    ))}
                </>
            )}
        </Flex>
    );
});
