import * as React from "react";
import { Field, Form, useField } from "formik";
import {
    Input,
    InputGroup,
    InputRightElement,
    FormControl,
    FormErrorMessage,
    FormLabel,
    FormControlProps,
    InputProps,
    InputLeftAddon,
} from "@chakra-ui/react";
import { EyeClosed, EyeOpened, SolidX } from "assets/icons/unauthenticated";

interface Props extends FormControlProps {
    placeholder?: string;
    name: string;
    type?: "email" | "text" | "tel" | "password";
    label?: string;
    // required?: boolean;
    // disabled?: boolean;
    value?: any;
    autoFocus?: boolean;
    inputProps?: InputProps;
    leftAddon?: React.ReactNode;
    onChange?: (event: any) => void;
    disabled?: boolean;
}

export default function MyTextInput({
    type,
    name,
    label,
    placeholder,
    inputProps,
    leftAddon,
    value,
    disabled = false,
    ...rest
}: Props) {
    const [field, meta] = useField(name);
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <FormControl  mb="4" {...rest} isInvalid={meta.touched && !!meta.error} >
            {label && <FormLabel>{label}</FormLabel>}
            <InputGroup>
                {leftAddon && (
                    <InputLeftAddon
                        bg="black"
                        border="none"
                        color="white"
                        h="auto"
                    >
                        {leftAddon}
                    </InputLeftAddon>
                )}
                <Input
                    {...field}
                    {...inputProps}
                    maxLength={100}
                    variant="flushed"
                    pl={leftAddon ? 0 : 6}
                    type={showPassword ? "text" : type}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {type == "password" && (
                    <InputRightElement
                        bottom="0"
                        right="6"
                        my="auto"
                        justifyContent="flex-end"
                        onClick={() => setShowPassword((v) => !v)}
                        cursor="pointer"
                    >
                        {showPassword ? (
                            <EyeOpened color="gray.400" />
                        ) : (
                            <EyeClosed color="gray.400" />
                        )}
                    </InputRightElement>
                )}
            </InputGroup>
            <FormErrorMessage>
                {meta.touched && !!meta.error ? meta.error : ""}
            </FormErrorMessage>
        </FormControl>
    );
}
