import OnboardingSteps from "./OnboardingSteps";
import AuthenticationTemplate from "app/components/basicPageTemplate";
import { Text } from "@chakra-ui/react";
import { history } from "../..";
import { useEffect } from "react";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import ProfileInfoForm from "features/profiles/ProfileInfoForm";
import LoadingComponent from "app/layout/LoadingComponent";
import { useTranslation } from "react-i18next";

export default observer(function Two() {
   
    const {
        profileStore,
        userStore: { user },
    } = useStore();
    const { profile, loadProfile, loadingProfile } = profileStore;
    const { t } = useTranslation();
    
    
    useEffect(() => {
        // if (user?.onboardingDone) {
        //     history.push("/");
        // }
        if (user?.username && profile == null)
            loadProfile(user?.username);
        profileStore.setOnboardingVariant("start");
    }, [profileStore, user, loadProfile]);


    if (loadingProfile) return <LoadingComponent content='Loading profile...' />
    return (
        <>
            <OnboardingSteps steps={5} currentStep={2} />
            <AuthenticationTemplate
                headingText={t("profile.info")}
                pt={{ base: 20, md: 0 }}
                rightChild={
                    <>
                        <Text size="paragraph_normal" color="white" mb="6">
                            {t("profile.notice1")}
                            {t("profile.notice2")}
                        </Text>
                        <Text size="paragraph_normal" color="white">
                           {t("profile.notice3")}
                        </Text>
                    </>
                }
            >
                <ProfileInfoForm saveCallback={() => history.push("/onboarding/three")} editMode={true} submitButtonText={t("continue.bttn")}/>
            </AuthenticationTemplate>
        </>
    );
});
