import * as React from "react";
import { Heading, useBreakpointValue, Flex } from "@chakra-ui/react";
import Grid from "app/components/grid";
import Trade from "./Trade";
import IconButton from "app/components/iconButton";
import { ArrowBack } from "assets/icons/onboarding";
import ExchangeRates from "./ExchangeRates";
import { observer } from "mobx-react-lite";
import { useStore } from "app/stores/store";
import { useEffect, useState } from "react";
import Notification from "app/components/notification";
import { MessageType } from "app/models/message";
import LoadingComponent from "app/layout/LoadingComponent";
import { useTranslation } from "react-i18next";

export default observer(function Exchange() {
    
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    const [showSummary, setShowSummary] = useState(false);
    const {
        krakenStore: { createHubConnection, clearRates, rates, loadingInitial },
        profileStore: { isProfileValidated },
    } = useStore();
    const { t } = useTranslation();

    useEffect(() => {
        createHubConnection();

        return () => {
            clearRates();
        };
    }, [createHubConnection, clearRates]);

    if (loadingInitial) return <LoadingComponent />;
    

    return (
        <>
            <Grid mt={{ base: "6", lg: "10" }}>
                {!isProfileValidated && (
                    <Notification
                        gridColumn="1/-1"
                        type={MessageType.Error}
                        message={t("complete.acc.message")}
                        my="6"
                        messageStyle={{ fontWeight: "semibold" }}
                    />
                )}
                {showSummary ? (
                    <Flex gridColumn="1/-1" mb="10" align="center">
                        <IconButton aria-label="go back" icon={<ArrowBack w="20px" h="20px" />} onClick={() => setShowSummary(false)} />
                        <Heading ml="8" color="white" size={headingSize}>
                            {t("preview.trade")}
                        </Heading>
                    </Flex>
                ) : (
                    <Heading mb="10" color="white" gridColumn="1/-1" size={headingSize}>
                        {t("navigation.trade")}
                    </Heading>
                )}
                <Trade
                    showSummary={showSummary}
                    handleSell={() => setShowSummary(false)}
                    handleContinue={() => setShowSummary(true)}
                    gridColumn={{ base: "1/-1", lg: "1 / span 6", xl: "1 / span 7" }}
                    mb={{ base: "6", lg: "0" }}
                />
                <ExchangeRates gridColumn={{ base: "1/-1", lg: "7 / span 6", xl: "8 / span 5" }} mb={{ base: "20", lg: "0" }} height="min-content" />
            </Grid>
        </>
    );
});
