import * as React from "react";
import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { createStandaloneToast } from "@chakra-ui/react";
// import Toast from "../../components/toast/Toast";
import theme from "../../../theme";
import { getIcon, MessageType } from "app/models/message";
import { useTranslation } from "react-i18next";

interface Props extends FlexProps {
  icon: React.ReactNode;
  message: string;
}

export  function Toast({ icon, message, ...rest }: Props) {
  const { t } = useTranslation();
  return (
    <Flex
      {...rest}
      align="center"
      bg="gray.800"
      borderRadius="0.75rem"
      boxShadow="4px 4px 20px 20px rgba(0, 0, 0, 0.2)"
      p="4"
    >
      <Flex
        minW="44px"
        minH="44px"
        borderRadius="full"
        w="0"
        h="0"
        align="center"
        justify="center"
        bg="black"
        mr="4"
      >
        {icon}
      </Flex>
      <Text color="white" size="paragraph_semibold">
        {t(message)}
      </Text>
    </Flex>
  );
}

export function showToast(message: string, type: MessageType, icon?: React.ReactNode) {
  const toast = createStandaloneToast({ theme: theme });
  toast({
      position: "bottom",
      // duration: 2000,
      render: () => <Toast icon={icon ? icon : getIcon(type)} message={message} />,
  });
}
