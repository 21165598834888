import * as React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { Clock } from "assets/icons/home";

interface Props extends FlexProps {
  isPending?: boolean;
  size: "big" | "small";
  icon: React.ReactNode;
}

export default function ActivityIcon({
  isPending = false,
  icon,
  size,
  ...rest
}: Props) {
  return (
    <Flex
      pos="relative"
      bg="gray.700"
      align="center"
      justify="center"
      minW={size === "small" ? "40px" : "72px"}
      minH={size === "small" ? "40px" : "72px"}
      h="0"
      w="0"
      borderRadius="full"
      border="1px solid"
      borderColor="black"
      {...rest}
    >
      {icon}
      {isPending ? (
        <Flex
          align="center"
          justify="center"
          minW={size === "small" ? "16px" : "28px"}
          minH={size === "small" ? "16px" : "28px"}
          w="0"
          h="0"
          borderRadius="full"
          bg="yellow.800"
          pos="absolute"
          bottom="0"
          right="-2px"
          border="1px solid"
          borderColor="black"
        >
          <Clock
            color="black"
            minW={size === "small" ? "16px" : "28px"}
            minH={size === "small" ? "16px" : "28px"}
            w="0"
            h="0"
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
