import { format } from "date-fns";
import { Flex, Table, Tbody, Td, Th, Thead, Tooltip, Tr, IconButton } from "@chakra-ui/react";
import { Bin } from "assets/icons/activity";
import { Download } from "assets/icons/activity";
import { EmptyCheck } from "assets/icons/onboarding";
import { observer } from "mobx-react-lite";
import { createDownloadLink, Document, getDocumentTitle } from "../../app/models/document";
import { useStore } from "../../app/stores/store";
import { useState } from "react";
import { SyntheticEvent } from "react";
import agent from "../../app/api/agent";

interface Props {
    documents: Document[] | undefined;
    deleteDocument: (doc: Document) => Promise<void>;
    validateDocument: (doc: Document) => Promise<void>;
    loading: boolean;
}

export default observer(function DocumentsList({ documents, deleteDocument, loading, validateDocument }: Props) {
    const {
        profileStore: { isCurrentUser },
        userStore: { isAuthorized },
    } = useStore();
    const [target, setTarget] = useState("");

    function handleDeleteDocument(doc: Document, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        deleteDocument(doc);
    }

    function handleValidateDocument(doc: Document, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);
        if (isAuthorized)
            validateDocument(doc);
    }

    function handleDownloadDocument(doc: Document, e: SyntheticEvent<HTMLButtonElement>) {
        setTarget(e.currentTarget.name);

        if (isAuthorized)
            agent.Documents.adminDownload(doc.id).then((response) => {
                createDownloadLink(doc.name, response);
            });
        else if (isCurrentUser)
            agent.Documents.download(doc.id).then((response) => {
                createDownloadLink(doc.name, response);
            });
    }

    return (
        <>
            {documents && documents.length > 0 && (
                <Table variant="striped" colorScheme="blackAlpha">
                    <Thead>
                        <Tr>
                            <Th color="gray.500">Actions</Th>
                            <Th color="gray.500">Creation Date</Th>
                            {/* {isAuthorized && !isCurrentUser &&  */}
                            <Th color="gray.500">Owner Name</Th>
                            {/* } */}
                            <Th color="gray.500">Name</Th>

                            <Th color="gray.500">Type</Th>
                        </Tr>
                    </Thead>

                    <Tbody color="white" bg="gray.900">
                        {documents.map((document) => (
                            <Tr key={document.id} color={document.validated ? "green.800" : "white"}>
                                <Td>
                                    {(isCurrentUser || isAuthorized) && (
                                        <Flex direction="row">
                                            {isAuthorized && (
                                                <Tooltip label="Validate">
                                                    <IconButton
                                                        aria-label="Validate"
                                                        mr="2"
                                                        colorScheme="gray.800"
                                                        icon={<EmptyCheck w="20px" h="20px" color="green.800" />}
                                                        name={"validate" + document.id}
                                                        isDisabled={document.validated}
                                                        isLoading={target === "validate" + document.id && loading}
                                                        onClick={(e) => handleValidateDocument(document, e)}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Tooltip label="Download">
                                                <IconButton
                                                    aria-label="Download"
                                                    mr="2"
                                                    colorScheme="gray.800"
                                                    icon={<Download w="20px" h="20px" />}
                                                    name={"download" + document.id}
                                                    isLoading={target === "download" + document.id && loading}
                                                    onClick={(e) => handleDownloadDocument(document, e)}
                                                />
                                            </Tooltip>

                                            <Tooltip label="Delete">
                                                <IconButton
                                                    aria-label="Delete"
                                                    mr="2"
                                                    colorScheme="gray.800"
                                                    icon={<Bin w="20px" h="20px" />}
                                                    isDisabled={document.validated}
                                                    isLoading={target === document.id && loading}
                                                    onClick={(e) => handleDeleteDocument(document, e)}
                                                    name={document.id}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    )}
                                </Td>
                                <Td>{format(new Date(document.creationDate), "dd MMM yyyy h:mm aa")}</Td>
                                {/* {isAuthorized && !isCurrentUser && ( */}
                                <Td>{document.ownerName && <a href={`/admin/profiles/${document.ownerName}`}>{document.ownerName}</a>}</Td>
                                {/* )} */}
                                <Td>{document.name}</Td>

                                <Td>{getDocumentTitle(document.type)}</Td>
                                
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </>
    );
});
