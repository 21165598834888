import { showToast } from "app/components/toast";
import { MessageType } from "app/models/message";
import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { Role, TFAFormValues, User, UserFormValues } from "../models/user";
import { store } from "./store";

interface RedirectState {
    from: string;
}

export default class UserStore {
    user: User | null = null;
    TFAKey: string | null = null;
    refreshTokenTimeout: any;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get isAuthorized() {
        // //console.log(this.user?.roles);
        return (!!this.user && this.user.roles && this.user.roles.includes(Role.Admin));
    }

    decodeJwt = (token: string) => {
        return JSON.parse(atob(token.split(".")[1]));
    };

    login = async (creds: UserFormValues) => {
        this.loading = true;
        try {
            const user = await agent.Account.login(creds);
            //console.log(user);
            user.roles = this.decodeJwt(user.token).role;
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
            //user.onboardingDone = true;
            runInAction(() => {this.user = user; this.loading = false;});
            //console.log(this.user);
            
            // const { location } = history;
            const state: any  = history.location.state;
            if (state && state.from) {
                history.push(state.from);
            } else {
                user.onboardingDone ?
                history.push("/home")
                :
                history.push("/onboarding/one");
            }
            store.modalStore.closeModal();
            
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
            throw error;
        }
    };

    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem("jwt");
        runInAction(() => (this.user = null));
        history.push("/");
    };

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            user.roles = this.decodeJwt(user.token).role;
            store.commonStore.setToken(user.token);
            runInAction(() => (this.user = user));
            this.startRefreshTokenTimer(user);
        } catch (error) {
            //console.log(error);
        }
    };

    register = async (creds: UserFormValues) => {
        try {
            // set username as email
            creds.displayName = creds.username = creds.email;
            const user = await agent.Account.register(creds);
            // store.commonStore.setToken(user.token);
            // runInAction(() => (this.user = user));
            history.push(`/security/registerSuccess?email=${creds.email}`);
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    };

    setImage = (image: string) => {
        if (this.user) this.user.image = image;
    };

    setDisplayName = (name: string) => {
        if (this.user) this.user.displayName = name;
    };

    loadTFAKey = async () => {
        this.loading = true;
        try {
            const TFAKey = await agent.Account.getTFAKey();
            runInAction(() => (this.TFAKey = TFAKey));
            //console.log(TFAKey);
            return TFAKey;
        } catch (error) {
            //console.log(error);
        }
        finally{
            runInAction(() => (this.loading = false));
        }
    };

    TFAConfirmSetup = async (values: TFAFormValues) => {
        this.loading = true;
        try {
            await agent.Account.TFAConfirmSetup(values);
            runInAction(() => (this.user!.tfaGoogleEnabled = true));
            showToast("2FA.enabled", MessageType.Success);
        } catch (error) {
            //console.log(error);
            showToast("2FA.enabled.error", MessageType.Error);
            // throw error;
        }
        finally{
            runInAction(() => (this.loading = false));
        }
    };

    disableTFA = async () => {
        this.loading = true;
        try {
            await agent.Account.disableTFA();
            runInAction(() => (this.user!.tfaGoogleEnabled = false));
            showToast("2FA.disabled", MessageType.Success);
        } catch (error) {
            //console.log(error);
            showToast("2FA.disabled.error", MessageType.Error);
        } finally{
            runInAction(() => (this.loading = false));
        }
    };

    verifyTFA = async (code: string) => {
        try {
            const verified = await agent.Account.verifyTFA(code);
            if (verified)
                store.modalStore.closeModal();
            return verified;
        } catch (error) {
            throw error;
        }
    };

    isTFAEnabled = async (email: string) => {
        this.loading = true;
        try {
            const enabled = await agent.Account.isTFAEnabled(email);
            runInAction(() => {this.loading = false;});
            return enabled;
        } catch (error) {
            //console.log(error);
            runInAction(() => (this.loading = false));
            throw error;
        }
    };


    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await agent.Account.refreshToken();
            user.roles = this.decodeJwt(user.token).role;
            runInAction(() => (this.user = user));
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            //console.log(error);
        }
    };

    private startRefreshTokenTimer(user: User) {
        const jwtToken = this.decodeJwt(user.token);
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
