import * as React from "react";
import { Flex, Text, Input, Grid, FlexProps } from "@chakra-ui/react";
import { ArrowDown } from "assets/icons/navigation";
import {
    previousMonday,
    nextMonday,
    nextSunday,
    lastDayOfMonth,
    setDate,
    isMonday,
    isSunday,
    eachDayOfInterval,
    format,
    subMonths,
    addMonths,
    isSameMonth,
    compareAsc,
    isSameDay,
    isWithinInterval,
} from "date-fns";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Returns the "padded" days for that month.
function getDatesInterval(selectedMonth: Date): Date[] {
    const firstDay = setDate(selectedMonth, 1);
    const lastDay = lastDayOfMonth(selectedMonth);

    let start = firstDay;
    if (!isMonday(firstDay)) {
        start = previousMonday(firstDay);
    }
    let end = lastDay;
    if (!isSunday(lastDay)) {
        end = nextSunday(end);
    }
    return eachDayOfInterval({ start, end });
}

function isDateOnIntervalEnd(dateLeft: Date, dateRight: Date | undefined) {
    if (!dateRight) return false;
    return isSameDay(dateLeft, dateRight);
}

function isDateInInterval(date: Date, start: Date | undefined, end: Date | undefined) {
    if (!start || !end) return false;
    return isWithinInterval(date, { start, end });
}

function getDateProperties(date: Date, selectedMonth: Date, startDate: Date | undefined, endDate: Date | undefined, today?: Date): FlexProps {
    if (isDateOnIntervalEnd(date, startDate)) {
        var props = {
            borderTopLeftRadius: "0.25rem",
            borderBottomLeftRadius: "0.25rem",
            bg: "yellow.800",
            color: "black",
        };
        if (isDateOnIntervalEnd(date, endDate)) return { ...props, borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem" };
        return props;
    }
    if (isDateOnIntervalEnd(date, endDate)) {
        return {
            borderTopRightRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
            bg: "yellow.800",
            color: "black",
        };
    }
    if (isDateInInterval(date, startDate, endDate)) {
        return {
            bg: "yellow.500",
            color: "black",
        };
    }
    if (isSameMonth(date, selectedMonth)) {
        return {
            bg: "transparent",
            color: today && isSameDay(date, today) ? "yellow.800" : "white",
        };
    }
    return {
        bg: "transparent",
        color: "gray.500",
    };
}

interface Props extends FlexProps {
    startDate?: Date;
    endDate?: Date;
    setStartDate?: (startDate: Date) => void;
    setEndDate?: (startDate?: Date, endDate?: Date) => void;
}

export default observer(function Calendar({ startDate, endDate, setStartDate, setEndDate, ...rest }: Props) {

    const { t } = useTranslation();
    const [selectedMonth, setSelectedMonth] = React.useState(new Date());
    const [focusedInput, setFocusedInput] = React.useState<"start" | "end">("start");
    const today = new Date();

    const interval = React.useMemo(() => getDatesInterval(selectedMonth), [selectedMonth]);

    const goPreviousMonth = React.useCallback(() => {
        setSelectedMonth((date) => subMonths(date, 1));
    }, []);

    const goNextMonth = React.useCallback(() => {
        setSelectedMonth((date) => addMonths(date, 1));
    }, []);

    const handleDateClick = React.useCallback(
        (date: Date) => {
            if (focusedInput === "start") {
                setStartDate && setStartDate(date);
                setFocusedInput("end");
                // If new start date is later than end date, it should set both to the same selected value.
                if (endDate && compareAsc(date, endDate) > 0) {
                    setEndDate && setEndDate(date);
                }
            } else {
                setEndDate && setEndDate(date);
                setFocusedInput("start");
                if (startDate && compareAsc(date, startDate) < 0) {
                    setStartDate && setStartDate(date);
                }
            }
        },
        [focusedInput, startDate, endDate]
    );
    

    return (
        <Flex direction="column" maxW="350px" {...rest}>
            <Flex align="center" justify="space-between" sx={{ gap: "8px" }} mb="4">
                <Flex direction="column">
                    <Text size="smallText_normal" color="gray.400" mb="1">
                        {t("calentar.start.date")}
                    </Text>
                    <Input
                        variant="calendar"
                        placeholder="dd/mm/yyyy"
                        borderColor={focusedInput === "start" ? "yellow.800" : undefined}
                        onFocus={() => setFocusedInput("start")}
                        value={startDate ? format(startDate, "dd/MM/yyyy") : "dd/mm/yyyy"}
                        readOnly
                    />
                </Flex>
                <Flex direction="column">
                    <Text size="smallText_normal" color="gray.400" mb="1">
                        {t("calendar.end.date")}
                    </Text>
                    <Input
                        variant="calendar"
                        placeholder="dd/mm/yyyy"
                        borderColor={focusedInput === "end" ? "yellow.800" : undefined}
                        onFocus={() => setFocusedInput("end")}
                        value={endDate ? format(endDate, "dd/MM/yyyy") : "dd/mm/yyyy"}
                        readOnly
                    />
                </Flex>
            </Flex>
            <Flex align="center" justify="space-between" mb="4">
                <ArrowDown
                    w="20px"
                    h="20px"
                    boxSizing="content-box"
                    transform="rotate(90deg)"
                    m="-3"
                    p="3"
                    cursor="pointer"
                    onClick={goPreviousMonth}
                />
                <Text size="smallText_semibold" color="white">
                    {format(selectedMonth, "LLLL yyyy")}
                </Text>
                <ArrowDown w="20px" h="20px" boxSizing="content-box" transform="rotate(-90deg)" m="-3" p="3" cursor="pointer" onClick={goNextMonth} />
            </Flex>
            <Grid templateColumns="repeat(7, 1fr)" autoRows="1fr">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                    <Text key={day} as="span" textAlign="center" size="caption_semibold" mb="4">
                        {day}
                    </Text>
                ))}
                {interval.map((date) => (
                    <Flex
                        key={date.toISOString()}
                        justify="center"
                        align="center"
                        height="100%"
                        _hover={{ bg: "yellow.800", color: "black" }}
                        userSelect="none"
                        cursor="pointer"
                        {...getDateProperties(date, selectedMonth, startDate, endDate, today)}
                        onClick={() => handleDateClick(date)}
                    >
                        <Text size="smallText_semibold" as="span">
                            {format(date, "dd")}
                        </Text>
                    </Flex>
                ))}
            </Grid>
        </Flex>
    );
});
