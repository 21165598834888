export interface Document {
    id: string;
    ownerName: string;
    name: string;
    comment: string;
    type: number;
    creationDate: Date;
    validated: boolean;
}
export enum DocumentType  {
    ID = 0,
    Selfie = 1,
    POR = 2,
    Bank = 3,
};

export function getDocumentTitle(documentType: DocumentType) {
    switch (documentType) {
        case DocumentType.ID:
            return "Identity";
        case DocumentType.Bank:
            return "Bank";
        case DocumentType.POR:
            return "Proof of residence";
        case DocumentType.Selfie:
            return "Selfie";
        default:
            return documentType;
    }
}

export function createDownloadLink(name: string, blob: Blob) {
    // console.log(name);
    const link = document.createElement("a");
    link.href = "data:image/*;base64," + blob;

    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export class DocumentFilterParams {
    name?: string;
    validated?: boolean;
}
