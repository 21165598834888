import { Flex, FlexProps, Text, Button } from "@chakra-ui/react";
import { IdCard, Selfie, Residence } from "assets/icons/settings";
import { Loader } from "assets/icons/home";
import { EmptyCheck } from "assets/icons/onboarding";
import { DocumentType } from "app/models/document";
import { useStore } from "app/stores/store";
import { observer } from "mobx-react-lite";
import agent from "app/api/agent";
import { createDownloadLink, Document } from "app/models/document";
import { useTranslation } from "react-i18next";

type Variants = "initial" | "pending" | "verified";

interface Props extends FlexProps {
    uploadCallback: () => void;
    documentType: DocumentType;
}

function getIcon(documentType: DocumentType) {
    switch (documentType) {
        case DocumentType.ID:
            return <IdCard w="20px" h="20px" />;
        case DocumentType.Selfie:
            return <Selfie w="20px" h="20px" />;
        case DocumentType.POR:
            return <Residence w="20px" h="20px" />;
    }
}

function getText(documentType: DocumentType) {
    switch (documentType) {
        case DocumentType.ID:
            return "ID";
        case DocumentType.Selfie:
            return "Selfie";
        case DocumentType.POR:
            return "Residence";
    }
}

export default observer(function ProfileDocumentCard({ uploadCallback, documentType, ...rest }: Props) {
    const {
        profileStore: { getDocumentByType, deleteDocument, isCurrentUser, validateDocument },
        userStore: { isAuthorized },
    } = useStore();
    const { t } = useTranslation();

    function documentExits() {
        return getDocumentByType(documentType) != undefined;
    }

    function documentIsValidated() {
        var doc = getDocumentByType(documentType);
        return doc && doc.validated;
    }

    function handleDownloadDocument(doc: Document) {
        if (isAuthorized)
            agent.Documents.adminDownload(doc.id).then((response) => {
                createDownloadLink(doc.name, response);
            });
        else if (isCurrentUser)
            agent.Documents.download(doc.id).then((response) => {
                createDownloadLink(doc.name, response);
            });
    }
    return (
        <>
            <Flex
                py={{ base: "4", sm: "6" }}
                px={{ base: "4", sm: "6" }}
                borderRadius="0.75rem"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.700"
                bg="gray.800"
                justify="space-between"
                align="center"
                {...rest}
            >
                <Flex
                    basis="40%"
                    justify="space-between"
                    align={{ base: "flex-start", sm: "center" }}
                    direction={{ base: "column", sm: "row" }}
                    sx={{ gap: "12px" }}
                >
                    <Flex color="white" align="center" mb={{ base: !documentExits() ? "0" : "4", sm: "0" }}>
                        <Flex
                            borderRadius="50%"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="gray.900"
                            minW="40px"
                            minH="40px"
                            align="center"
                            justify="center"
                            bg="gray.700"
                        >
                            {getIcon(documentType)}
                        </Flex>
                        <Text size="paragraph_semibold" ml="4">
                            {getText(documentType)}
                        </Text>
                    </Flex>
                    {documentExits() && (
                        <Flex align="center">
                            {!documentIsValidated() ? (
                                <>
                                    <Loader w="20px" h="20px" color="yellow.800" />
                                    <Text ml="3" size="caption_bold">
                                        {t("verifying")}
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <EmptyCheck w="20px" h="20px" color="green.500" />
                                    <Text ml="3" size="caption_bold">
                                        {t("validated")}
                                    </Text>
                                </>
                            )}
                        </Flex>
                    )}
                </Flex>
                
                <Flex height="min-content">
                    {documentExits() ? (
                        <>
                            {!documentIsValidated() && isAuthorized && !isCurrentUser && (
                                <Button variant="primary" mr="3" onClick={() => validateDocument(getDocumentByType(documentType!)!)}>
                                    {t("validate")}
                                </Button>
                            )}
                            {!documentIsValidated() && isCurrentUser && (
                                <Button variant="secondary" mr="3" onClick={() => deleteDocument(getDocumentByType(documentType!)!)}>
                                    {t("remove")}
                                </Button>
                            )}
                            {/* <Button variant="primary" onClick={onOpen}> */}
                            <Button variant="primary" onClick={() => handleDownloadDocument(getDocumentByType(documentType!)!)}>
                                {t("view")}
                            </Button>
                        </>
                    ) : (
                        <>
                            {isCurrentUser && (
                                <Button variant="primary" onClick={uploadCallback}>
                                    {t("upload")}
                                </Button>
                            )}
                        </>
                    )}
                </Flex>
            </Flex>
        </>
    );
});
