import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const Home = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <g>
      <path
        d="M20.5 7.917a.833.833 0 0 0-.324-.66L11.009.175a.833.833 0 0 0-1.02 0L.822 7.258a.833.833 0 0 0-.322.659v11.25a.833.833 0 0 0 .833.833H8a.417.417 0 0 0 .417-.416v-3.75a2.083 2.083 0 1 1 4.166 0v3.75A.417.417 0 0 0 13 20h6.667a.833.833 0 0 0 .833-.833V7.917z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export const Trade = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <g fill="currentColor">
      <path d="M9.1 4.113a6.032 6.032 0 015.916 1.877 1.042 1.042 0 101.56-1.38 8.115 8.115 0 00-7.957-2.527 8.557 8.557 0 00-6.137 5.925.208.208 0 01-.226.149l-.861-.109A.41.41 0 001 8.231a.417.417 0 00-.016.438l2.064 3.619a.416.416 0 00.31.206.343.343 0 00.052 0 .416.416 0 00.3-.126l2.897-2.995a.417.417 0 00-.25-.703l-1.565-.193a.208.208 0 01-.167-.278 6.417 6.417 0 014.474-4.086zm10.905 7.637a.417.417 0 00.012-.438l-2.083-3.608a.417.417 0 00-.663-.078l-2.88 3.012a.416.416 0 00.25.701l1.54.184a.208.208 0 01.171.278 6.406 6.406 0 01-4.474 4.083 6.036 6.036 0 01-5.916-1.877 1.042 1.042 0 10-1.56 1.38 8.116 8.116 0 007.957 2.525 8.55 8.55 0 006.141-5.934.21.21 0 01.225-.149l.882.106a.38.38 0 00.05 0 .417.417 0 00.348-.185z"></path>
    </g>
  </Icon>
);

export const DepositWithdraw = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.9 4.27a1.25 1.25 0 00-1.25 1.25v8.424a.208.208 0 01-.208.208H3.401a.833.833 0 00-.714 1.265l2.5 4.166a.833.833 0 001.429 0l2.5-4.166a.833.833 0 00-.715-1.262H7.359a.208.208 0 01-.208-.208V5.52A1.25 1.25 0 005.9 4.27zm9.2 11.43a1.25 1.25 0 001.25-1.25V6.027a.208.208 0 01.207-.208H17.6a.833.833 0 00.714-1.262L15.813.39a.867.867 0 00-1.429 0l-2.5 4.167a.832.832 0 00.715 1.262h1.042a.208.208 0 01.208.208v8.423a1.25 1.25 0 001.25 1.25z"
    ></path>
  </Icon>
);

export const Activity = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M19.458 9.376h-4.065l-1.339-3.5a1.042 1.042 0 00-1.993.166l-1.215 5.93L9.333 1.928a1.042 1.042 0 00-2.031-.132l-2.179 7.58H1.542a1.042 1.042 0 000 2.083h4.365a1.04 1.04 0 001-.755l1.075-3.741 1.678 11.11a1.042 1.042 0 001 .885h.027a1.04 1.04 0 001.02-.833l1.675-8.167.318.833a1.041 1.041 0 00.973.667h4.785a1.041 1.041 0 100-2.083z"
      ></path>
    </g>
  </Icon>
);

export const ArrowDown = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.988 11a.993.993 0 01-.395-.081.957.957 0 01-.325-.23L3.18 6.237a.717.717 0 01.081-1.028.79.79 0 011.074.054L7.91 9.156a.104.104 0 00.078.034.108.108 0 00.079-.034l3.574-3.893a.765.765 0 01.531-.26.8.8 0 01.567.18.75.75 0 01.184.233.712.712 0 01-.127.821l-4.086 4.45a.96.96 0 01-.326.23.997.997 0 01-.396.083z"
    ></path>
  </Icon>
);

export const Account = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#E5E7EA"
      d="M10 9.792a4.583 4.583 0 100-9.167 4.583 4.583 0 000 9.167zm0 1.25a7.925 7.925 0 00-7.917 7.916.417.417 0 00.417.417h15a.417.417 0 00.416-.417A7.925 7.925 0 0010 11.042z"
    ></path>
  </Icon>
);

export const Hamburger = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      d="M18.75 2.083H1.25a.833.833 0 0 0-.833.833v.834c0 .46.373.833.833.833h17.5c.46 0 .834-.373.834-.833v-.834a.833.833 0 0 0-.834-.833zM18.75 8.75H1.25a.833.833 0 0 0-.833.833v.833c0 .46.373.834.833.834h17.5c.46 0 .834-.373.834-.834v-.833a.833.833 0 0 0-.834-.833zM18.75 15.416H1.25a.833.833 0 0 0-.833.834v.833c0 .46.373.833.833.833h17.5c.46 0 .834-.373.834-.833v-.833a.833.833 0 0 0-.834-.834z"
      fill="#fff"
    />
  </Icon>
);

export const Settings = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M15 6.333h-1.241a5.972 5.972 0 00-.509-1.226l.878-.878a1 1 0 000-1.414l-.943-.942a1 1 0 00-1.414 0l-.879.878a5.94 5.94 0 00-1.225-.509V1a1 1 0 00-1-1H7.333a1 1 0 00-1 1v1.242a5.98 5.98 0 00-1.226.509l-.878-.878a1 1 0 00-1.414 0l-.944.942a1 1 0 000 1.415l.879.878a5.92 5.92 0 00-.509 1.225H1a1 1 0 00-1 1v1.334a1 1 0 001 1h1.241c.123.426.294.837.509 1.226l-.88.879a1 1 0 000 1.414l.943.943a1.03 1.03 0 001.415 0l.879-.878c.388.214.8.385 1.226.509V15a1 1 0 001 1h1.334a1 1 0 001-1v-1.24a5.972 5.972 0 001.226-.509l.878.878a1 1 0 001.414 0l.943-.942a1 1 0 000-1.414l-.878-.878c.215-.389.386-.8.509-1.227H15a1 1 0 001-1.001V7.333a1 1 0 00-1-1zm-7 5a3.334 3.334 0 110-6.667 3.334 3.334 0 010 6.667z"
      ></path>
    </g>
  </Icon>
);

export const Logout = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="#fff">
      <path d="M14.667.333h-7a1.333 1.333 0 00-1.334 1.333v2a.667.667 0 101.334 0V2A.333.333 0 018 1.666h6.334a.333.333 0 01.333.334v12a.333.333 0 01-.333.333H8A.333.333 0 017.667 14v-1.667a.667.667 0 10-1.334 0v2a1.333 1.333 0 001.334 1.333h7A1.333 1.333 0 0016 14.333V1.666A1.333 1.333 0 0014.667.333z"></path>
      <path d="M11.334 9.333a1 1 0 100-2h-6V5.666a.667.667 0 00-1.037-.554l-4 2.666a.667.667 0 000 1.11l4 2.666a.675.675 0 00.684.034.666.666 0 00.352-.588V9.333h6z"></path>
    </g>
  </Icon>
);

export const Logo = (props: IconProps) => (
  <Icon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1020.75 170.25"
    {...props}
  >
    <defs>
      <path
        id="glyph-0-1"
        d="M86.719-78.766l-5.594 28.907h24.75v12.093h-27.11L71.36 0H58.531l7.407-37.766H40.108L33.062 0h-12.64l6.86-37.766H4.515v-12.093h25.109l5.781-28.907H11.203v-11.937h26.375L44.812-129h13l-7.218 38.297h26.015L83.83-129h12.468l-7.219 38.297h22.938v11.937zM42.453-49.859h25.844l5.594-28.907H48.062zm0 0"
      ></path>
      <path
        id="glyph-1-2"
        d="M16.063-130.031h21.171V0H16.062zm37.39 0c5.531 0 11 .886 16.406 2.656 5.414 1.762 10.27 4.406 14.563 7.938 4.289 3.523 7.758 7.78 10.406 12.78 2.656 5 3.984 10.618 3.984 16.844 0 6.594-1.12 12.57-3.359 17.922-2.242 5.344-5.36 9.84-9.36 13.485-3.991 3.648-8.808 6.468-14.452 8.468-5.649 2-11.883 3-18.704 3H44.47v-15.515H56.64c2.582 0 5.14-.442 7.672-1.328 2.53-.883 4.851-2.328 6.968-4.328 2.114-2 3.817-4.79 5.11-8.375 1.3-3.582 1.953-8.024 1.953-13.329 0-2.113-.297-4.55-.89-7.312-.587-2.77-1.79-5.414-3.61-7.938-1.824-2.53-4.387-4.648-7.688-6.359-3.293-1.707-7.64-2.562-13.047-2.562h-8.64v-16.047zm0 0"
      ></path>
      <path
        id="glyph-1-3"
        d="M15.875-130.031h21.172V0H15.875zM104.094 0H81.688L57.172-47.11a7.79 7.79 0 00-1.156.094c-.407.055-1.024.078-1.844.078H44.28v-15.515h12c2.594 0 5.18-.442 7.766-1.328 2.582-.883 4.93-2.328 7.047-4.328 2.125-2 3.832-4.79 5.125-8.375 1.289-3.582 1.937-8.024 1.937-13.329 0-2.113-.297-4.55-.89-7.312-.586-2.77-1.79-5.414-3.61-7.938-1.824-2.53-4.383-4.648-7.672-6.359-3.293-1.707-7.64-2.562-13.046-2.562H44.28v-16.047h9c5.532 0 11 .886 16.407 2.656 5.414 1.762 10.269 4.406 14.562 7.938 4.29 3.523 7.758 7.78 10.406 12.78 2.645 5 3.969 10.618 3.969 16.844 0 9.418-2.18 17.418-6.531 24-4.344 6.586-10.227 11.465-17.64 14.641zm0 0"
      ></path>
      <path
        id="glyph-1-4"
        d="M63.344-114.688c-4.356 0-8.297.825-11.828 2.47-3.524 1.648-6.524 4.445-9 8.39-2.47 3.937-4.383 9.027-5.735 15.266-1.343 6.23-2.078 13.87-2.203 22.921.125 7.18.598 13.532 1.422 19.063.82 5.531 2.055 10.234 3.703 14.11L30-11.829c-5.418-5.52-9.594-12.719-12.531-21.594-2.938-8.883-4.407-19.625-4.407-32.219 0-10.937 1.145-20.55 3.438-28.843 2.29-8.29 5.582-15.196 9.875-20.72 4.29-5.53 9.523-9.737 15.703-12.624 6.176-2.883 13.086-4.328 20.734-4.328 8.114 0 15.227 1.531 21.344 4.594l-7.765 16.578c-3.875-2.47-8.227-3.704-13.047-3.704zm31.75-5.296c5.77 5.418 10.156 12.656 13.156 21.718 3 9.055 4.5 19.93 4.5 32.625 0 10.95-1.152 20.602-3.453 28.954-2.293 8.343-5.617 15.28-9.969 20.812-4.344 5.523-9.578 9.695-15.703 12.516C77.508-.535 70.57.875 62.812.875c-8.355 0-15.765-1.645-22.234-4.938l7.938-16.75c3.644 2.938 8.234 4.407 13.765 4.407 4.352 0 8.32-.852 11.907-2.563 3.593-1.707 6.624-4.5 9.093-8.375 2.469-3.883 4.38-8.972 5.735-15.265 1.351-6.29 2.086-13.97 2.203-23.032-.117-7.406-.649-13.867-1.594-19.39-.938-5.531-2.29-10.239-4.047-14.125-.125-.114-.187-.235-.187-.36zm0 0"
      ></path>
      <path
        id="glyph-1-5"
        d="M115.563-130.031V0H94.39v-58.578H45.344v-14.469H94.39v-56.984zm-98.985 0H38.11V0H16.58zm0 0"
      ></path>
      <path
        id="glyph-1-6"
        d="M122.797 0h-21.875L67.219-97.39 33.172 0H11.109l45.532-129.86h20.984zM78.875-41.281L83.985-27H50.28l4.938-14.281zm0 0"
      ></path>
      <path
        id="glyph-1-7"
        d="M53.813-80.813c6.113 0 11.609 1.09 16.484 3.266 4.883 2.18 9.031 5.09 12.437 8.734 3.414 3.649 6.004 7.766 7.766 12.36a39.328 39.328 0 012.656 14.281c0 9.649-1.59 17.324-4.765 23.031-3.18 5.7-7.09 10.047-11.735 13.047-4.648 3-9.5 4.914-14.562 5.735C57.039.46 52.69.875 49.047.875c-4 0-7.824-.324-11.469-.969-3.648-.644-6.969-1.468-9.969-2.469-3-1-5.62-2-7.859-3-2.23-1-3.934-1.789-5.11-2.375v-17.64c1.645 1.055 3.735 2.2 6.266 3.437a119.626 119.626 0 008.032 3.532 60.453 60.453 0 008.906 2.734c3.113.7 6.023 1.047 8.734 1.047 8.707 0 15.531-2.235 20.469-6.703 4.937-4.469 7.406-11.348 7.406-20.64 0-6.696-2.148-11.99-6.437-15.876-4.293-3.883-9.907-5.828-16.844-5.828-2.242 0-4.48.152-6.719.453-2.23.293-4.344.73-6.344 1.313l2.125-17.47c2.344-.468 4.836-.788 7.485-.968 2.656-.176 4.687-.266 6.093-.266zm-23.47 23.297H13.767c0-.945.113-2.71.343-5.297.239-2.582.536-5.664.891-9.25.352-3.593.766-7.503 1.234-11.734.47-4.238.938-8.535 1.407-12.89a3396.6 3396.6 0 014.062-33.344h64.39v17.281H37.579c-1.18 8.71-2.121 15.477-2.828 20.297-.7 4.824-1.2 8.414-1.5 10.766a876.414 876.414 0 00-.531 4.328c-.055.523-.078.843-.078.968l-1.407 11.813zm0 0"
      ></path>
      <clipPath id="clip-0">
        <path d="M2.113 0h1016.274v169.504H2.113zm0 0"></path>
      </clipPath>
    </defs>
    <g fill="transparent" clipPath="url(#clip-0)">
      <path d="M2.113 0h1016.524v177.016H2.113zm0 0"></path>
      <path d="M2.113 0h1016.524v169.543H2.113zm0 0"></path>
      <path d="M2.113 0h1016.524v169.543H2.113zm0 0"></path>
    </g>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="4.731"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-0-1"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="121.445"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-1"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="169.789"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-2"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="276.534"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-3"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="392.983"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-4"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="518.607"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-5"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="650.759"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-6"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="784.676"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-7"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <use
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="891.421"
      y="149.622"
      fill="#F2A900"
      xlinkActuate="onLoad"
      xlinkHref="#glyph-1-5"
      xlinkShow="embed"
      xlinkType="simple"
    ></use>
    <path
      fill="#F2A900"
      d="M458.328 68.98l-23.008 51.415 6.57 2.94 35.051-78.32-6.574-2.94zm0 0"
    ></path>
  </Icon>
);
