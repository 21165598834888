import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Flex, Heading, Input, InputGroup, InputRightElement, Spacer, Switch, Text, useBreakpointValue } from "@chakra-ui/react";
import Grid from "app/components/grid";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import DocumentsList from "../../documents/DocumentsList";
import { DocumentFilterParams } from "app/models/document";
import { getRecordNo, PagingParams } from "app/models/pagination";
import { Close } from "assets/icons/home";

export default observer(function KYCDocuments() {
    const {
        adminStore: {
            documents,
            loadDocuments,
            setDocFilterParams,
            setDocPagingParams,
            docFilterParams,
            docPagination,
            deleteDocument,
            validateDocument,
            initialLoading,
            loading,
        },
        userStore: { isAuthorized },
    } = useStore();
    const headingSize = useBreakpointValue({ base: "h3_bold", sm: "h2_bold" });
    const [loadingNext, setLoadingNext] = useState(false);
    const [kycValidated, setKycValidated] = useState<boolean>(false);
    const [name, setName] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isAuthorized)
            if (documents.length < 1) {
                loadDocuments();
            }
        return () => {
            setDocFilterParams(new DocumentFilterParams());
        };
    }, [loadDocuments]);

    function handleGetNext() {
        setLoadingNext(true);
        setDocPagingParams(new PagingParams(docPagination!.currentPage + 1));
        loadDocuments().then(() => setLoadingNext(false));
    }

    function applyFilterHandler() {
        var newFP = docFilterParams;
        newFP.name = name;
        newFP.validated = kycValidated;
        setDocFilterParams(newFP);
    }

    if (initialLoading) return <LoadingComponent />;

    return (
        <Grid mt={{ base: "6", lg: "10" }} pos="relative">
            <Heading mb="6" color="white" gridColumn="1/-1" size={headingSize}>
                KYC Documents
            </Heading>
            <Flex gridColumn={{ base: "1/-1" }} mb="3">
                <Flex align="center" justify="flex-start">
                    <InputGroup mr="3">
                        <Input variant="filled" placeholder="Search..." value={name} onChange={(e) => setName(e.target.value)} />
                        <InputRightElement my="auto" bottom="0" cursor="pointer" onClick={() => setName("")}>
                            {name ? <Close w="16px" h="16px" color="white" /> : null}
                        </InputRightElement>
                    </InputGroup>
                    <Switch colorScheme="yellow" isChecked={kycValidated} onChange={() => setKycValidated(!kycValidated)} />
                    <Text as="span" ml="3" color="white" size="paragraph_semibold" whiteSpace="nowrap" mr="3">
                        KYC Validated
                    </Text>
                </Flex>
                <Spacer />
                <Button
                    variant="primary"
                    onClick={() => {
                        applyFilterHandler();
                    }}
                >
                    Filter
                </Button>
            </Flex>
            <Flex
                gridColumn={{ base: "1/-1" }}
                mb={{ base: "6", lg: "0" }}
                direction="column"
                justify="center"
                py="3"
                bg="gray.800"
                borderRadius="0.75rem"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.700"
                overflowX="auto"
            >
                {documents && documents.length > 0 && (
                    <DocumentsList documents={documents!} deleteDocument={deleteDocument} validateDocument={validateDocument} loading={loading} />
                )}
                {docPagination && docPagination!.totalPages > 1 && (
                    <Flex align="center" direction="row" justify="end" mt="4">
                        <Text size="smallText_semibold" color="gray.300" mr="3">
                            Displayed {getRecordNo(docPagination)} users
                        </Text>
                        <Button
                            variant="primary"
                            isLoading={loadingNext}
                            isDisabled={!(!loadingNext && !!docPagination && docPagination.currentPage < docPagination.totalPages)}
                            onClick={handleGetNext}
                            mr="3"
                        >
                            Display more
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Grid>
    );
});
